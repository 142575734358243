import moment from "moment";
import { Venue } from "../../typings/common";
import { VenueOptions, VenueData, HistoricSalesItem } from "../../typings/groupwideDashboard";
import { getEarliestDate, fromStandardDateFormat, toStandardDateFormat } from "../date";
import { safeSum } from "../globals";

const computeConsolidated = (venues: (Venue & VenueOptions)[]): VenueData => {
  const filteredVenues = venues.filter((venue) => venue.included);

  const lastUpdated = getEarliestDate(
    filteredVenues.map((venue) =>
      venue.data?.lastUpdated
        ? typeof venue.data.lastUpdated === "string"
          ? fromStandardDateFormat(venue.data.lastUpdated)
          : venue.data.lastUpdated
        : moment()
    )
  );

  const dayNetSalesWithServiceCharge = safeSum(
    filteredVenues.map((venue) => venue.data?.dayNetSalesWithServiceCharge ?? 0)
  );

  const mtdSales = safeSum(filteredVenues.map((venue) => venue.data?.mtdSales ?? 0));

  const monthBudget = safeSum(filteredVenues.map((venue) => venue.data?.monthBudget ?? 0));

  const ytdSales = safeSum(filteredVenues.map((venue) => venue.data?.ytdSales ?? 0));

  const yearBudget = safeSum(filteredVenues.map((venue) => venue.data?.yearBudget ?? 0));

  const historicSalesArrays = filteredVenues.map((venue) => venue.data?.historicSales);

  let historicSalesObject: {
    [key: string]: number;
  } = {};
  historicSalesArrays.forEach((venueHistoricSalesArray) => {
    venueHistoricSalesArray?.forEach((item) => {
      const dateString = toStandardDateFormat(item.date) ?? "";
      if (dateString in historicSalesObject) {
        historicSalesObject[dateString] += item.sales;
      } else {
        historicSalesObject[dateString] = item.sales;
      }
    });
  });

  const historicSales: HistoricSalesItem[] = Object.keys(historicSalesObject)
    .sort()
    .map((key) => ({
      date: fromStandardDateFormat(key),
      sales: historicSalesObject[key],
    }));

  console.log(historicSales);

  return {
    lastUpdated,
    dayNetSalesWithServiceCharge,
    mtdSales,
    monthBudget,
    ytdSales,
    yearBudget,
    historicSales,
  };
};

export default computeConsolidated;
