export const recipientMessage = (
	recipientName: string,
	senderName: string,
	message: string
) =>
	`Hello ${recipientName}, you have been recognised by ${senderName} for your Open Arms.
Keep up the awesome!
____

${message}`;

export const managerMessage = (
	employeeNames: string[],
	senderName: string,
	message: string
) =>
	`Hello, ${senderName} has recognised ${employeeNames.join(
		" and "
	)} for their Open Arms.
Looks like your team member(s) are doing a great job!
____

${message}`;