import React from "react"
import { SalesSummaryRowType, ProductSales } from "../../typings/common";
import { formatCurrency, formatPercentage } from "../../utils/currency";
import SalesSummaryRow from "../SalesSummaryRow"

type SalesByProductProps = {
  salesByProductData?: any
}

const SalesByProduct: React.FC<SalesByProductProps> = ({
  salesByProductData
}) => {
  return (
    <>
      {
        salesByProductData.map((productSales: ProductSales) => {
          return (
            <SalesSummaryRow 
              key={productSales.name}
              itemNameText={productSales.name}
              itemValueText={`${
                formatCurrency(productSales.value)
              } (${
                formatPercentage(productSales.percentageOfTotalSales)
              } of sales)`}
              type={SalesSummaryRowType.grayText}
            />
          )
        })
      }
    </>
  )
}

export default SalesByProduct; 