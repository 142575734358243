// This is for the consolidation of The Coconut Club (Ann Siang)'s, The Coconut Club (Beach Road)'s,
// and The Coconut Club (Siglap)'s figures in IncentivesDashboard

import { DailyIncentiveLog, Dashboard } from "../typings/common";
import {
  aggregateMtdSalesForIncentiveCalc,
  computeMtdEndState,
  monthDailyRowsToMtdSalesForIncentiveCalc,
} from "./incentive";

import { Moment } from "moment";
import { airtableRequiredFields } from "./globals";
import { getDailyRowPeriod } from "../services/DataSourceRailroadSwitch";
import { getIncentiveAdminMonthlyParams } from "../components/IncentivesDashboard/IncentivesController";

export const isTccIncentiveConsolidationLogicActive = true;

export const cca = "The Coconut Club (Ann Siang)";
export const ccb = "The Coconut Club (Beach Road)";
export const ccs = "The Coconut Club (Siglap)";
export const ccr = "The Coconut Club (River Valley)";

export const getIncentivesDashboardValsForTcc = (date: Moment): Promise<DailyIncentiveLog> => {
  const monthStart = date.clone().startOf("month");

  // Get sales data for CCS from Airtable
  const ccsSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccs,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get sales data for CCB from Airtable
  const ccbSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccb,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get sales data for CCR from Airtable
  const ccrSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccr,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get incentive params
  const incentiveStructureParamsPromise = () =>
    getIncentiveAdminMonthlyParams(ccb, date.year(), date.month() + 1, false);

  return Promise.all([
    ccsSalesPromise(),
    ccbSalesPromise(),
    ccrSalesPromise(),
    incentiveStructureParamsPromise(),
  ]).then(
    ([ccsSalesResponse, ccbSalesResponse, ccrSalesResponse, incentiveStructureParamsResponse]) => {
      console.log(ccsSalesResponse, ccbSalesResponse, ccrSalesResponse);
      const tccCombinedSales = aggregateMtdSalesForIncentiveCalc(
        [
          monthDailyRowsToMtdSalesForIncentiveCalc(ccsSalesResponse, date),
          monthDailyRowsToMtdSalesForIncentiveCalc(ccbSalesResponse, date),
          monthDailyRowsToMtdSalesForIncentiveCalc(ccrSalesResponse, date),
        ],
        date
      );

      const mtdDailyIncentiveLog = computeMtdEndState(
        tccCombinedSales,
        incentiveStructureParamsResponse,
        date
      );

      const preTargetPortion =
        Math.min(
          incentiveStructureParamsResponse.monthlyTarget ?? 0,
          mtdDailyIncentiveLog.monthlySales ?? 0
        ) * (incentiveStructureParamsResponse.monthlyTargetCut ?? 0);

      const postTargetPortion = mtdDailyIncentiveLog.isPotUnlocked
        ? (incentiveStructureParamsResponse.monthlyTargetBonusCut ?? 0) *
          ((mtdDailyIncentiveLog.monthlySales ?? 0) -
            (incentiveStructureParamsResponse.monthlyTarget ?? 0))
        : 0;

      const potReward =
        (preTargetPortion + postTargetPortion) /
        (incentiveStructureParamsResponse.numberToSplit ?? 1);

      const output = {
        ...incentiveStructureParamsResponse,
        ...mtdDailyIncentiveLog,
        preTargetPortion,
        postTargetPortion,
        potReward,
      };

      console.log(output);
      return output;
    }
  );
};
