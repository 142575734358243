import "./App.css";

import AlertPage, { AlertPageType } from "./AlertPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ChangeLog from "./ChangeLog";
import DataAssuranceDashboard from "./DataAssuranceDashboard";
import DefaultRedirect from "./DefaultRedirect";
import GroupwideSalesDashboard from "./GroupwideDashboard";
import IncentivesAdmin from "./IncentivesAdmin";
import IncentivesDashboard from "./IncentivesDashboard/IncentivesDashboard";
import LightspeedApiReauthPage from "./LightspeedApiReauthPage";
import LightspeedConnection from "./LightspeedConnection";
import Login from "./Login";
import Logout from "./Logout";
import ManualAdjustmentRequestPage from "./ManualAdjustmentRequest";
import ManualApiRefreshPage from "./ManualApiRefresh";
import MarketingDashboard from "./MarketingDashboard";
import OpenArmsRecognitionDashboard from "./OpenArmsRecognitionDashboard";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import RecognitionForm from "./RecognitionForm";
import RecognitionFormSubmitted from "./RecognitionForm/RecognitionFormSubmitted";
import SalesDashboard from "./SalesDashboard/SalesDashboard";
import SalesInputDashboard from "./SalesInputDashboard/SalesInputDashboard";
import SetPasswordPage from "./SetPasswordPage";
import { path } from "../utils/globals";

const App: React.FC<{}> = (props) => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route path={path.root} exact>
            <DefaultRedirect />
          </Route>

          <Route path={path.login}>
            <Login />
          </Route>

          <ProtectedRoute
            path={path.salesDashboard}
            component={SalesDashboard}
            permissionsDashboard="Sales"
          />

          <ProtectedRoute
            path={path.marketingDashboard}
            component={MarketingDashboard}
            permissionsDashboard="Marketing"
          />

          <ProtectedRoute
            path={path.assuranceDashboard}
            component={DataAssuranceDashboard}
            permissionsDashboard="DataAssurance"
          />

          <ProtectedRoute
            path={path.recognitionDashboard}
            component={OpenArmsRecognitionDashboard}
            permissionsDashboard="OpenArmsRecognition"
          />

          <ProtectedRoute
            path={path.manualAdjustmentRequest}
            component={ManualAdjustmentRequestPage}
            permissionsDashboard="ManualAdjustmentRequest"
          />

          <ProtectedRoute
            path={path.posDataRefresh}
            component={ManualApiRefreshPage}
            permissionsDashboard="ManualApiRefresh"
          />

          <ProtectedRoute
            path={path.lightspeedApiReauth}
            component={LightspeedApiReauthPage}
            permissionsDashboard="Administrator"
          />

          <ProtectedRoute path={path.setPassword} component={SetPasswordPage} />

          <ProtectedRoute path={path.changelog} component={ChangeLog} />

          <ProtectedRoute
            path={path.incentivesDashboard}
            component={IncentivesDashboard}
            permissionsDashboard="Incentives"
          />

          <ProtectedRoute
            path={path.incentivesDashboardAdmin}
            component={IncentivesAdmin}
            permissionsDashboard="IncentivesAdmin"
          />

          <ProtectedRoute
            path={path.groupwideDashboard}
            component={GroupwideSalesDashboard}
            permissionsDashboard="Groupwide"
          />

          <ProtectedRoute
            path={path.salesInput}
            component={SalesInputDashboard}
            permissionsDashboard="SalesDataInput"
          />

          <Route path={path.recognitionForm} component={RecognitionForm} />

          <Route path={path.recognitionFormSubmitted} component={RecognitionFormSubmitted} />

          <Route path={path.noUserFound}>
            <div>No user found</div>
          </Route>

          <Route path={path.noUserFound}>
            <div>User error</div>
          </Route>

          <Route path={path.logout}>
            <Logout />
          </Route>

          <Route path={path.lightspeedConnection}>
            <LightspeedConnection />
          </Route>

          <Route path={path.permissionDenied}>
            <AlertPage alertPageType={AlertPageType.permissionDenied} />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
