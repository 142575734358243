import React from 'react';
import { 
  getAvailableVenues, 
  getMarketingHistoryLy, 
  getMarketingHistoryRecent
} from '../services/AirtablePlus';
import MainHeader from "./MainHeader"
import { 
  fromStandardDateFormat,
  fromStandardDateTimeFormat,
  toHumanDateTimeFormat
 } from '../utils/date'; 
import { 
  DailyRow, 
  Venue,
  ChannelIncDel,
  Dashboard
} from '../typings/common'; 
import MarketingDashboardMainBody from "./MarketingDashboardMainBody";
import { toggleChannelSelection } from '../utils/salesHistory';
import { defaultMarketingChannels, loggedInUser } from '../utils/globals';
import LoadingOverlay from "react-loading-overlay"; 
import SelectField from './InputFields/SelectField';
import { 
  Text, 
  Section, 
  SectionSubHeader, 
  MobileLayout, 
  Button,
  Loading
} from "./StandardComponents"; 
import TextField from './InputFields/TextField';
// import FacebookLoginAuthComponent from './FacebookLoginAuthComponent';
// import { getFacebookAdsCampaignsMetrics } from "../services/FacebookGraphApi"; 
// import FacebookAdsCampaignsTable from "./FacebookAdsCampaignsTable";
// import { getFacebookAdsCampaigns } from '../services/FacebookBusinessSdk';
import DashboardHeader from './DashboardHeader';
import SideBar from './SideBarNew';

type SelectionProps = {
  venue: string | undefined, 
  fromDate: string | undefined, 
  toDate: string | undefined
}; 

const MarketingDashboard: React.FC = () => {

  const [ selection, setSelection ] = React.useState<SelectionProps>(); 
  const [ availableVenues, setAvailableVenues ] = React.useState< Venue[] >([]);
  
  const [ marketingHistoryRecent, setMarketingHistoryRecent ] = React.useState< DailyRow[] >([]);  
  const [ marketingHistoryLy, setMarketingHistoryLy ] = React.useState< DailyRow[] >([]); 
  const [ channelSelection, setChannelSelection ] = React.useState< ChannelIncDel[] >(defaultMarketingChannels); 
  const [ loading, setLoading ] = React.useState<boolean>(false); 
  // const [ facebookAdsCampaignsMetrics, 
    // setFacebookAdsCampaignsMetrics ] = React.useState<FacebookAdsCampaignMetrics[]>(); 

  // const [ facebookAccessToken, setFacebookAccessToken ] = React.useState<string>(); 
  
  async function fetchAvailableVenues(): Promise<void> {
    const availableVenuesResponse = await getAvailableVenues(
      Dashboard.marketing, 
      loggedInUser()
    ); 

    setAvailableVenues(availableVenuesResponse); 
    setLoading(false); 
  }

  async function getMarketingHistoryRecentDataset(
    venue: string, 
    fromDate: string, 
    toDate: string
  ): Promise<void> {
    const marketingHistoryRecentDatasetResponse = await getMarketingHistoryRecent({
      venue: venue, 
      fromDate: fromDate, 
      toDate: toDate
    }); 

    if (Array.isArray(marketingHistoryRecentDatasetResponse) && marketingHistoryRecentDatasetResponse.length >= 1) {
      marketingHistoryRecentDatasetResponse.forEach((dailyRow) => {
        if (dailyRow.business_date !== undefined) {
          const businessDateMoment = fromStandardDateFormat(dailyRow.business_date); 
          dailyRow.includedInMarketingHistory = {
            selectedPeriod: businessDateMoment.isBetween(
              fromDate, 
              toDate, 
              "day", 
              "[]"
            )
          };  
        }
      })
      setMarketingHistoryRecent(marketingHistoryRecentDatasetResponse); 
    }
    else {
      setMarketingHistoryRecent([]); 
      console.log("Cannot find any entry", marketingHistoryRecentDatasetResponse); 
    }
  }

  async function getMarketingHistoryLyDataset(
    venue: string, 
    fromDate: string, 
    toDate: string
  ): Promise<void> {
    const marketingHistoryLyDatasetResponse = await getMarketingHistoryLy({
      venue: venue, 
      fromDate: fromDate, 
      toDate: toDate
    }); 

    if (Array.isArray(marketingHistoryLyDatasetResponse) && marketingHistoryLyDatasetResponse.length >= 1) {
      setMarketingHistoryLy(marketingHistoryLyDatasetResponse); 
      console.log(marketingHistoryLyDatasetResponse); 
    }
    else {
      setMarketingHistoryLy([]); 
      console.log("Cannot find any entry", marketingHistoryLyDatasetResponse); 
    }
  }

  React.useEffect(() => {
    setLoading(true); 
    fetchAvailableVenues(); 
  }, [])

  const loadData = (currentSelection?: SelectionProps) => {
    if (currentSelection &&
      currentSelection?.venue !== undefined && 
      currentSelection?.fromDate !== undefined && 
      currentSelection?.toDate) {
      
      console.time("aggregateQueryTime")
      setLoading(true); 
      Promise.all([
        getMarketingHistoryRecentDataset(
          currentSelection?.venue, 
          currentSelection?.fromDate, 
          currentSelection?.toDate
        ), 
        getMarketingHistoryLyDataset(
          currentSelection?.venue, 
          currentSelection?.fromDate, 
          currentSelection?.toDate
        )
      ])
      .then(() => {
        setLoading(false); 
        console.timeEnd("aggregateQueryTime"); 
      })
       
      // updateFacebookAdsCampaignsMetrics(); 
    }
  }

  React.useEffect(() => {
    loadData(selection); 
  }, [selection]); 

  const handleVenueChange = (selectedValue: string) => {
    setSelection({
      venue: selectedValue, 
      fromDate: selection?.fromDate, 
      toDate: selection?.toDate, 
    })
    // console.log(selectedValue); 
  };

  const handleDateRangeChange = (
    selectedFromDate: string, 
    selectedToDate: string
    ) => {

    setSelection({
      venue: selection?.venue, 
      fromDate: selectedFromDate, 
      toDate: selectedToDate
    }); 
  };

  const handleChannelSelectionChange = (code: string) => {
    const updated = toggleChannelSelection(channelSelection, code); 
    console.table(updated); 
    setChannelSelection(updated as ChannelIncDel[]); 
  }

  React.useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'; 
    }
    else {
      document.body.style.overflow = 'auto'; 
    }
  })

  const marketingHistoryRecentSelectedPeriod = marketingHistoryRecent
    .filter((dailyRow) => dailyRow.includedInMarketingHistory?.selectedPeriod); 
  
  const lastUpdated = (
    marketingHistoryRecentSelectedPeriod.length !== 0 && 
    marketingHistoryRecentSelectedPeriod !== undefined) ? 

      marketingHistoryRecentSelectedPeriod
      [marketingHistoryRecentSelectedPeriod.length-1].end_time : 
      
      ""; 

  const lastUpdatedString = lastUpdated ? 
    toHumanDateTimeFormat(
      fromStandardDateTimeFormat(
        lastUpdated
      )
    ) : 
    ""; 

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(100, 108, 119, 0.2)', 
          zIndex: 2000, 
          height: '100vh', 
          position: 'fixed'
        })
      }}>
      <MobileLayout>
        <MainHeader />
        <SideBar
          pageWrapId="outer-container" 
          outerContainerId="outer-container"
          selectedItem="Marketing"/>

          <Button onClick={() => loadData(selection)}>Refresh</Button>

          <DashboardHeader>Marketing report</DashboardHeader>

          <SectionSubHeader>
            {`Last updated: ${lastUpdatedString}`}
          </SectionSubHeader>
          <Section>
            <SelectField label="Venue"
              placeholderLabel="Select venue..."
              changeHandler={handleVenueChange}
              options={
                availableVenues
                  .filter((venue) => venue.name !== undefined)
                  .map((venue) => ({ 
                    label: venue.name as string, 
                    value: venue.name as string
                    }))} />
          
            <TextField 
              label="Date" inputType="dateRange"
              dateRangeChangeHandler={handleDateRangeChange}/>
          </Section>

          {/* <FacebookLoginAuthComponent 
            loggedInAsText="Logged into Facebook Ads Manager as:"
            connectButtonClick={componentClicked}
            postLoginCallback={responseFacebook}
            postLogoutCallback={handleFacebookLogout}
          />

          { facebookAdsCampaignsMetrics !== null &&
            facebookAdsCampaignsMetrics !== undefined &&
            <FacebookAdsCampaignsTable 
              facebookAdsCampaigns={facebookAdsCampaignsMetrics}/>} */}
          
          { selection?.fromDate && selection?.toDate && selection?.venue ? 
              (marketingHistoryRecent !== [] ? 
                <MarketingDashboardMainBody 
                  fromDate={fromStandardDateFormat(selection.fromDate)}
                  toDate={fromStandardDateFormat(selection.toDate)}
                  marketingHistoryRecent={marketingHistoryRecent}
                  marketingHistoryLy={marketingHistoryLy}
                  channelSelection={channelSelection}
                  channelSelectionChangeHandler={handleChannelSelectionChange} /> : 
                <Text>No data found for selected venue and date.</Text>
              ) : 
              null }
        
        </MobileLayout>
        
    </LoadingOverlay>
  );
}

export default MarketingDashboard;
