import React from "react"
import { DailyRow } from "../../typings/common"; 
import { Text as ogText } from "../StandardComponents"; 
import styled from "styled-components"; 
import ComparativeDataPoint from "./ComparativeDataPoint";
import { ExpandableComponent } from "../ExpandableComponent";

type ComparativeDataProps = {
  comparativeData?: (DailyRow | null) [], 
  comparativeDataChangeHandler: (arg0: any) => void
}

const Text = styled(ogText)`
  margin-top: 0px; 
  margin-left: 0px; 
`

const ComparativeData: React.FC<ComparativeDataProps> = ({
  comparativeData, 
  comparativeDataChangeHandler
}) => {

  const comparativeDataContainer = (
    <>
      <Text>Select dates to be included in comparative data</Text>
      { 
        comparativeData?.map((comparativeDataPoint) => {
          const irrelevant = comparativeDataPoint?.sumChannelSales === 0; 

          return <ComparativeDataPoint
            key={comparativeDataPoint?.business_date} 
            comparativeDataPoint={comparativeDataPoint} 
            isIrrelevant={irrelevant}
            changeHandler={() => {
              comparativeDataChangeHandler(comparativeDataPoint?.business_date)
            }} />
        })
      }
    </>
  )

  return (
    <ExpandableComponent
      sectionHeaderText="Select comparative data"
      defaultExpanded={false}
    >
      { comparativeDataContainer } 
    </ExpandableComponent>
  )
}

export default ComparativeData; 