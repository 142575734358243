import React, { FormEvent } from "react"; 
import styled from "styled-components"; 
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import { toHumanDateFormatWithDow, toStandardDateFormat } from "../../utils/date";
import moment from 'moment'; 

type TextFieldProps = {
  id?: string, 
  label?: string, 
  defaultText?: string, 
  defaultDate?: Date, 
  defaultStartDate?: Date, 
  defaultEndDate?: Date, 
  inputType: "text" | "password" | "date" | "dateRange", 
  changeHandler?: ( (arg0: string) => void ), 
  dateRangeChangeHandler?: (arg0: string, arg1: string) => void
}

const Wrapper = styled.div`
  display: flex; 
  flex-direction: column; 
  text-align: left; 
  margin: 0px 4px; 
`

const Text = styled.p`
  display: block; 
  font-size: 13px; 
  font-weight: 500; 
  color: #646C77; 
  text-align: left; 
  margin: 4px auto 2px auto; 
`

const Input = styled.input.attrs((props) => ({
  ...props
}))`
  border: 0.5px solid #646C77;
  border-radius: 4px;
  margin: 2px auto 16px auto; 
  font-family: Eina01; 
  width: 100%; 
  box-sizing: border-box; 
  font-size: 16px; 
  padding: 12px 12px; 
  color: #273561; 
  input:focus {
    box-shadow: 0 0 0 3px #646C77; 
  }
  -webkit-appearance: none; 
`

const TextField = ({
  label, 
  defaultText, 
  inputType, 
  defaultDate, 
  defaultStartDate, 
  defaultEndDate, 
  changeHandler, 
  dateRangeChangeHandler
}: TextFieldProps) => {
  const [ date, setDate ] = React.useState<Date>(); 
  const [ startDate, setStartDate ] = React.useState<Date>(); 
  const [ endDate, setEndDate ] = React.useState<Date>(); 
  const [ tempDates, setTempDates ] = React.useState<{
    startDate: Date | undefined, 
    endDate: Date | undefined
  }>(); 
  
  React.useEffect(() => {
    if (defaultDate) {
      setDate(defaultDate); 
    }
  }, [defaultDate]); 

  const handleChange = (event: FormEvent) => {
    if (changeHandler !== undefined) {
      changeHandler((event.target as HTMLInputElement).value); 
    }
  }

  const handleDateChange = (date: Date) => {
    const standardDate = toStandardDateFormat(date); 
    if (changeHandler !== undefined && 
      standardDate !== undefined) {

      changeHandler(standardDate)
    }
    setDate(date); 
  }

  const handleDateRangeChange = ([ fromDate, toDate ]: [ Date, Date ]) => {
    const standardFromDate = toStandardDateFormat(fromDate); 
    const standardToDate = toStandardDateFormat(toDate); 
    if (dateRangeChangeHandler !== undefined && 
      standardFromDate !== undefined && 
      standardToDate !== undefined) {
      
      dateRangeChangeHandler(standardFromDate, standardToDate); 
    }
    setStartDate(fromDate); 
    setEndDate(toDate); 
  }

  const CustomDateInput = ({ value, onClick }: any) => {
    const displayValue = toHumanDateFormatWithDow(moment(value));
    return <Input 
      readOnly
      value={displayValue !== "" ? displayValue : "Select date..."} 
      onClick={onClick}
      />
  }

  const CustomDateRangeInput = ({ value, onClick }: any) => {
    return <Input
      readOnly
      value={
        ( startDate === undefined || 
          startDate === null || 
          endDate === undefined ||
          endDate === null ) ? 
        "Select date range..." : 
        `${
          toHumanDateFormatWithDow(moment(startDate))
        } to ${
          toHumanDateFormatWithDow(moment(endDate))
        }`
      }
      onClick={onClick}
      />
  }

  return (
    <Wrapper>
      { 
        inputType === "date" ? 
        (
          <label>
            <Text>{label}</Text>
            <DatePicker 
              selected={date}
              onChange={handleDateChange}
              customInput={<CustomDateInput />}/>
          </label>
        ) : 
        null 
      }
      {
        inputType === "dateRange" ? 
          (
            <label>
              <Text>{label}</Text>
              <DatePicker 
                onChange={handleDateRangeChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<CustomDateRangeInput />}
                onInputClick={() => {
                  setTempDates({
                    startDate: startDate, 
                    endDate: endDate
                  }); 
                  setStartDate(undefined); 
                  setEndDate(undefined); 
                }}
                onClickOutside={() => {
                  setStartDate(tempDates?.startDate); 
                  setEndDate(tempDates?.endDate); 
                }}
                shouldCloseOnSelect={startDate !== undefined}
                />
            </label>
          ) : 
          null
      }
      { 
        inputType !== "date" && inputType !== "dateRange" ? 
          (
            <label>
              <Text>{label}</Text>
              <Input 
                value={defaultText} 
                type={inputType}
                onChange={handleChange}/>
            </label>
          ) : 
          null
      }
    </Wrapper>
  )
}



export default TextField; 