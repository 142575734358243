import React from 'react'; 
import { 
  HeadlineText as ogHeadlineText, 
  Text, 
  CancelIcon, 
  BuildIcon
} from './StandardComponents';
import styled from 'styled-components'; 

export enum AlertPageType {
  permissionDenied = "permissionDenied", 
  underMaintenance = "underMaintenance"
}; 

const Wrapper = styled.div`
  text-align: center; 
  margin-top: 36px; 
`

const IconWrapper = styled.div`
  font-size: 48px; 
`

const HeadlineText = styled(ogHeadlineText)`
  text-align: center; 
`

const alertPageVars: {
  [key in AlertPageType]: {
    pageTitle: string, 
    message: string, 
    iconComponent: () => JSX.Element
  }
} = {
  permissionDenied: {
    pageTitle: "Permission Denied", 
    message: `User "${window.localStorage.getItem("loggedInUser")}" does not have the necessary permission for this page.`, 
    iconComponent: CancelIcon
  }, 
  underMaintenance: {
    pageTitle: "Under Maintenance", 
    message: `This page is currently under maintenance and inaccessible.`, 
    iconComponent: BuildIcon
  }
}

const AlertPage: React.FC<{ alertPageType: AlertPageType }> = ({
  alertPageType
}) => (
  <Wrapper>
    <IconWrapper>
      {alertPageVars[alertPageType].iconComponent()}
    </IconWrapper>
    <HeadlineText>
      {alertPageVars[alertPageType].pageTitle}
    </HeadlineText>
    <Text>
      {alertPageVars[alertPageType].message}
    </Text>
  </Wrapper>
); 

export default AlertPage; 