import { AirtableUpdateParams, AuthResponse, PermissionsDashboard } from "../typings/common";
import { 
  getUserAuthDetails,
  getUserPasswordHash,
  getUserPasswordRaw, 
  getUserPermissionsDashboard, 
} from "./AirtablePlus";
import { updateAirtableRowsUser } from '../services/AirtableService'; 
import bcrypt from 'bcryptjs'; 
import { loggedInUser } from "../utils/globals";

const SALT_ROUNDS = 8; 

const AuthService = {
  isAuthenticated: async (
    accessPermission?: PermissionsDashboard, 
    ) => {
    if (loggedInUser === null) {
      return AuthResponse.forbidden; 
    }
    else {
      
      if (accessPermission === undefined) {
        // If no accessPermission is given, user just needs to be logged in
        return AuthResponse.success
      }
      else {
        return getUserPermissionsDashboard(loggedInUser())
          .then((permissionFromDb) => {
            if (Array.isArray(permissionFromDb) && permissionFromDb.includes(accessPermission)) {
              // If accessPermission is given, user needs to have the provided accessPermission
              return AuthResponse.success; 
            }
            return AuthResponse.unauthorised; 
          }); 
      }
    }
  }, 
  authenticate: async (username: string, password: string) => {
    
    const {
      passwordRaw, 
      passwordHash, 
      redirectPath, 
      defaultVenue
    } = await getUserAuthDetails(username); 

    const authResult = passwordRaw === password ||
      await bcrypt.compare(password, passwordHash); 

    const isVirginLogin = passwordRaw !== "" &&
      passwordHash === ""; 
      
    if (authResult) {
      AuthService.login(
        username,
        redirectPath, 
        defaultVenue, 
        isVirginLogin
      ); 
      
    } 
    return authResult; 
  }, 
  login: (
    username: string, 
    redirectPath: string, 
    defaultVenue?: string, 
    isVirginLogin?: boolean
  ) => {
    window.localStorage.setItem("loggedInUser", username);
    window.localStorage.setItem("redirectPath", redirectPath); 
    window.localStorage.setItem("defaultVenue", defaultVenue ?? ""); 
    
    if (isVirginLogin) {
      window.localStorage.setItem("isVirginLogin", ""); 
    }
  }, 
  getUserAuthDetails: () => {
    return {
      loggedInUser: window.localStorage.getItem("loggedInUser") ?? "", 
      redirectPath: window.localStorage.getItem("redirectPath") ?? "", 
      defaultVenue: window.localStorage.getItem("defaultVenue") ?? "", 
      isVirginLogin: window.localStorage.getItem("isVirginLogin") !== null
    }
  }, 
  logout: () => {
    if (window.localStorage.getItem("loggedInUser") !== null) {
      window.localStorage.removeItem("loggedInUser"); 
    }
    if (window.localStorage.getItem("redirectPath") !== null) {
      window.localStorage.removeItem("redirectPath"); 
    }
    if (window.localStorage.getItem("defaultVenue") !== null) {
      window.localStorage.removeItem("defaultVenue"); 
    }
    if (window.localStorage.getItem("isVirginLogin") !== null) {
      window.localStorage.removeItem("isVirginLogin"); 
    }
  }, 
  generateHashAndStore: async (
    username: string, 
    password: string, 
    toRemovePasswordRaw?: boolean
  ) => {

    const hash = bcrypt.hashSync(password, SALT_ROUNDS); 
    let payload: AirtableUpdateParams = {
      where: `username = "${username}"`,
      data: {
        password_hash: hash
      }
    }; 
    if (toRemovePasswordRaw) {
      payload.data.password_raw = ""; 
    }
    const res = await updateAirtableRowsUser(payload);
    if (window.localStorage.getItem("isVirginLogin") !== null) {
      window.localStorage.removeItem("isVirginLogin"); 
    }
    return res.length === 1; 
  }  
}

export default AuthService; 