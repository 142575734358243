import React from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { path } from "../../utils/globals";
import { Description, OpenArmsPageLayout, SubmitButton, Title } from "./components";

const RecognitionFormSubmitted: FC = () => {
  const history = useHistory();

  return (
		<Wrapper>
			<OpenArmsPageLayout>
				<Title>
					🎉
					<br />
					<br />
					Sent!
				</Title>
				<Description>
					Recognition can make a day or change a life—thank you for taking the
					time to recognise.
					<br />
					<br />
				</Description>
				<SubmitButton onClick={() => history.push(path.recognitionForm)}>
					Submit Another
				</SubmitButton>
			</OpenArmsPageLayout>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	text-align: center; 
	justify-content: center;
	background: #f8f6f4;
`;

const Container = styled.div`
	display: flex; 
	flex-direction: column; 
	align-items: stretch;
	gap: 12px; 
`


export default RecognitionFormSubmitted;