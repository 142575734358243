import React, { FormEvent } from "react";
import { FC, useEffect, useState } from "react";
import getEmployees from "../services/FacebookWorkplace/getEmployees";
import LoadingOverlay from "react-loading-overlay";
import { Loading, MobileLayout } from "./StandardComponents";
import MainHeader from "./MainHeader";
import SideBar from "./SideBarNew";
import styled from "styled-components";
import { Table, TableBody, TableCell as OgTableCell, TableHead, TableRow } from "@material-ui/core";
import { RecognitionData } from "../typings/common";
import { getOpenArmsRecognition } from "../services/AirtablePlus";
import { toHumanDateTimeFormat } from "../utils/date";

export type FormVal = {
  employee?: FacebookOrgMember;
  message?: string;
  senderName?: string;
  sharePublicly: boolean;
};

const OpenArmsRecognitionDashboard: FC = (props) => {
  const [employeeMap, setEmployeeMap] = useState<{
    [key: string]: FacebookOrgMember;
  }>({});
  const [recognitionEntries, setRecgonitionEntries] = useState<RecognitionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getEmployees(), getOpenArmsRecognition()]).then(
      ([employeesResponse, recognitionsResponse]) => {
        console.table(employeesResponse);
        const tempMap: {
          [key: string]: FacebookOrgMember;
        } = {};
        employeesResponse &&
          employeesResponse.forEach((employee) => {
            tempMap[employee.email ?? ""] = employee;
          });
        setEmployeeMap(tempMap);
        console.log(recognitionsResponse);
        setRecgonitionEntries(recognitionsResponse);
        setLoading(false);
      }
    );
  }, []);

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          position: "fixed",
          height: "100vh",
        }),
      }}
    >
      <MobileLayout>
        <MainHeader />
        <SideBar selectedItem="OpenArmsRecognition" />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Share publicly?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recognitionEntries.map((entry) => (
              <TableRow>
                <TableCell>{toHumanDateTimeFormat(entry.timestamp ?? "")}</TableCell>
                <TableCell>{employeeMap[entry.employeeEmail ?? ""]?.name ?? ""}</TableCell>
                <TableCell>{entry.message}</TableCell>
                <TableCell>{entry.senderName}</TableCell>
                <TableCell>{entry.sharePublicly ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MobileLayout>
    </LoadingOverlay>
  );
};

export default OpenArmsRecognitionDashboard;

const TableCell = styled(OgTableCell)`
  font-size: 12px !important;
`;
