import React from 'react'; 
import { Redirect } from 'react-router-dom'; 
import AuthService from '../services/AuthService';
import { loggedInUser } from '../utils/globals';

const DefaultRedirect = () => {
  const userAuthDetails = AuthService.getUserAuthDetails(); 

  if (userAuthDetails.isVirginLogin) {
    console.log(loggedInUser); 
    return <Redirect to="set_password" />
  }
  else if (userAuthDetails.redirectPath) {
    return <Redirect to={{
      pathname: userAuthDetails.redirectPath, 
      state: {
        defaultVenue: userAuthDetails.defaultVenue
      }
    }} />
  }
  else {
    return <Redirect to="/login" />
  }
}

export default DefaultRedirect; 