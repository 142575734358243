import { FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from "@material-ui/core";
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { LightspeedCredentials, SnackbarProps } from "../typings/common";
import MainHeader from "./MainHeader";
import SideBar from "./SideBarNew";
import {
  Button,
  Loading,
  MobileLayout,
  SectionHeaderNoLine,
  SectionSubHeader,
  SuccessAlert,
  Text,
} from "./StandardComponents";
import styled from "styled-components";
import generatePkcePair from "../utils/generatePkcePair";
import { updateLightspeedProductionCredentials } from "../services/AirtableService";

const LIGHTSPEED_AUTH_URL = (clientId: string, codeChallenge: string) =>
  `https://lightspeedapis.com/resto/oauth2/v1/authorize?response_type=code&client_id=${clientId}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

const LIGHTSPEED_TOKEN_REQUEST_URL = "https://lightspeedapis.com/resto/oauth2/v1/token";

const LightspeedApiReauthPage: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [codeVerifier, setCodeVerifier] = useState<string>("");
  const [codeChallenge, setCodeChallenge] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [lightspeedCredentials, setLightspeedCredentials] = useState<LightspeedCredentials>({});
  const [selectedVenue, setSelectedVenue] = useState<string>("");
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    open: false,
    message: "None",
    severity: "warning",
  });

  const onGeneratePkce = () => {
    const { verifier, challenge } = generatePkcePair();
    setCodeVerifier(verifier);
    setCodeChallenge(challenge);
  };

  const onOpenLightspeedAuthorization = () => {
    if (!codeChallenge) {
      return;
    }
    const url = LIGHTSPEED_AUTH_URL(
      process.env.REACT_APP_LIGHTSPEED_API_CLIENT_ID ?? "",
      codeChallenge
    );
    window.open(url, "_blank");
  };

  const onGetToken = () => {
    const authorizationHeaderValue =
      "Basic " + process.env.REACT_APP_LIGHTSPEED_AUTHORIZATION_HEADER;
    const corsProxyUrl = process.env.REACT_APP_CORS_BYPASS_SERVER_URL;

    if (!codeVerifier || !code || !authorizationHeaderValue || !corsProxyUrl) {
      throw `Missing value: ${{ codeVerifier, code, authorizationHeaderValue, corsProxyUrl }}`;
    }
    setLoading(true);
    fetch(`${corsProxyUrl}${LIGHTSPEED_TOKEN_REQUEST_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authorizationHeaderValue,
      },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code: code,
        code_verifier: codeVerifier,
        redirect_uri: "https://tlbg-dashboard.vercel.app/lightspeed_connection",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLightspeedCredentials({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        });
        setLoading(false);
      });
  };

  const onSendToAirtable = () => {
    if (
      !selectedVenue ||
      !lightspeedCredentials.accessToken ||
      !lightspeedCredentials.refreshToken
    ) {
      return;
    }
    setLoading(true);
    updateLightspeedProductionCredentials(
      selectedVenue,
      lightspeedCredentials.accessToken,
      lightspeedCredentials.refreshToken
    ).then((isSuccessful) => {
      setLoading(false);
      if (isSuccessful) {
        setSnackbarProps({
          open: true,
          message: "Successfully updated credentials in Airtable",
          severity: "success",
        });
      } else {
        setSnackbarProps({
          open: true,
          message: "Failed to update credentials in Airtable",
          severity: "warning",
        });
      }
    });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          position: "fixed",
          height: "100vh",
        }),
      }}
    >
      <MobileLayout>
        <MainHeader />

        <SideBar selectedItem="ManualApiRefresh" />
        <Snackbar
          {...snackbarProps}
          autoHideDuration={6000}
          onClose={() => setSnackbarProps({ ...snackbarProps, open: false })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SuccessAlert>{snackbarProps.message}</SuccessAlert>
        </Snackbar>
        <SectionHeaderNoLine>Lightspeed API Reauthorization</SectionHeaderNoLine>
        <SectionSubHeader>Refresh for access token and refresh token</SectionSubHeader>
        <Section>
          <Button onClick={onGeneratePkce}>Generate PKCE</Button>
          <TextField label="Code Verifier" variant="outlined" value={codeVerifier} />
          <TextField label="Code Challenge" variant="outlined" value={codeChallenge} />
          <Button
            onClick={onOpenLightspeedAuthorization}
            disabled={codeVerifier.length === 0 || codeChallenge.length === 0}
          >
            Open Lightspeed Authorization
          </Button>
        </Section>
        <Section>
          <Text>Get text string from after "code=" in the post-authorization URL</Text>
          <TextField
            label="Code"
            variant="outlined"
            onChange={(e) => setCode(e.currentTarget.value)}
          />
          <Button onClick={onGetToken} disabled={code === ""}>
            Get Token
          </Button>
        </Section>
        <Section>
          <TextField
            label="Access token"
            variant="outlined"
            value={lightspeedCredentials.accessToken}
          />
          <TextField
            label="Refresh token"
            variant="outlined"
            value={lightspeedCredentials.refreshToken}
          />
          <FormControl fullWidth>
            <InputLabel id="venue-select-label">Venue</InputLabel>
            <Select
              value={selectedVenue}
              labelId="venue-select-label"
              label="Venue"
              onChange={(e) => setSelectedVenue(e.target.value as string)}
            >
              {VENUE_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={onSendToAirtable}
            disabled={
              !lightspeedCredentials.accessToken ||
              !lightspeedCredentials.refreshToken ||
              !selectedVenue
            }
          >
            Send to Airtable
          </Button>
        </Section>
      </MobileLayout>
    </LoadingOverlay>
  );
};

export default LightspeedApiReauthPage;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 8px;
  margin: 16px 0px;
`;

const VENUE_OPTIONS: string[] = ["LBF", "FHV"];
