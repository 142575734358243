import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh'; 
import IconButton from '@material-ui/core/IconButton';

type RefreshButtonProps = {
  onClick: () => void
}

const RefreshButton: React.FC<RefreshButtonProps> = ({
  onClick
}) => {

  return (
    <IconButton 
      className="refresh-button"
      onClick={() => onClick()}>
      <RefreshIcon 
        fontSize="large" 
        htmlColor="#273561"/>
    </IconButton>
  )
}

export default RefreshButton; 