import React, { KeyboardEventHandler } from 'react'; 
import styled from 'styled-components'; 
import { OccasionType } from '../../typings/common';
import NumericField from '../NumericField';
import { FieldInputNumeric } from '../StandardComponents';

type CalendarCellProps = {
	rowIndex: number, 
  colIndex: number, 
  fieldId: number, 
  fieldName?: string, 
  fieldValue?: number, 
  occasionType?: OccasionType, 
  changeHandler?: (id: number, input: number) => void, 
  keyDownHandler?: KeyboardEventHandler<HTMLInputElement>, 
  disabled?: boolean
}

const Wrapper = styled.div<{
  rowIndex: number, 
  colIndex: number
}>`
  display: flex; 
  flex-direction: column; 
  grid-row-start: ${({rowIndex}) => rowIndex}; 
  grid-column-start: ${({colIndex}) => colIndex}; 
`

const LabelWrapper = styled.div`
  display: flex; 
  flex-direction: row; 
  line-height: 18px; 
  justify-content: space-between; 
`

const DayIndexText = styled.span`
  text-align: left; 
  font-size: 11px; 
  color: #646C77; 
  width: 16px; 
`

const IndicatorCircle = styled.span`
  color: #273561; 
  font-size: 12px;
  display: inline; 
`

const HintText = styled.span`
  text-align: right; 
  font-size: 10px; 
  color: #646C77; 
  width: 72px; 
`

const CalendarCell: React.FC<CalendarCellProps> = ({
  rowIndex, 
  colIndex, 
  fieldId, 
  fieldName, 
  fieldValue, 
  occasionType, 
  changeHandler, 
  keyDownHandler, 
  disabled = false
}) => {
  return (
    <Wrapper
      rowIndex={rowIndex}
      colIndex={colIndex}>

      <LabelWrapper>
        <DayIndexText>{fieldName}</DayIndexText>
        <HintText>
          <IndicatorCircle>{ occasionType ? "• " : ""}</IndicatorCircle>
          { occasionType }
        </HintText>
      </LabelWrapper>

      <NumericField 
        value={fieldValue}
        onChange={(event) => {
          if (changeHandler) {
            changeHandler(
              fieldId, 
              parseFloat((event.target as HTMLInputElement).value)
            ); 
          }
        }} 
        onKeyDown={keyDownHandler}
        disabled={disabled}
        />

    </Wrapper>
  )
}

export default CalendarCell; 