import {
  HistoricSalesTrendViewOption,
  SalesProgressBarViewOption,
} from "../typings/groupwideDashboard";

export const defaultSalesProgressBarViewOptions = [
  {
    value: "mtd",
    label: "MTD",
  },
  {
    value: "ytd",
    label: "YTD",
  },
];

export const defaultHistoricSalesTrendViewOptions = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
];

export const CONSOLIDATED = "{CONSOLIDATED}";

export const defaultVenueOptions = {
  included: true,
  salesProgressBarView: "mtd" as SalesProgressBarViewOption,
  historicSalesTrendView: "daily" as HistoricSalesTrendViewOption,
};
