import moment from "moment";
import { Moment } from "moment";
import { Channel, ChannelIncDel, DailyRow, SalesHistoryLastYear, SalesHistoryRecent, SalesHistoryType } from "../typings/common";
import { fromStandardDateFormat, getL6w, getMtd, getTmly, getTtlmMtd, thisTimeLastYear, toStandardDateFormat } from "./date";

export const toggleSalesHistoryRecent = (
  salesHistoryRecent: SalesHistoryRecent, 
  salesHistoryRecentType: "l6wDowSales" | "ttlmMtdSales" | "salesTrendL6w" | "mtdSales", 
  businessDate: string
  ) => {
    const updated = salesHistoryRecent[salesHistoryRecentType]?.map((dailyRow: DailyRow) => {
      if (dailyRow.business_date === businessDate) {
        return {
          ...dailyRow, 
          includedInSalesHistory: {
            ...dailyRow.includedInSalesHistory, 
            toggle: !dailyRow.includedInSalesHistory?.toggle
          }
        }
      }
      else {
        return dailyRow
      }
    });

    return {
      ...salesHistoryRecent, 
      [salesHistoryRecentType]: updated
    }
}


export const toggleSalesHistoryLastYear = (
  salesHistoryLastYear: SalesHistoryLastYear, 
  salesHistoryLastYearType: "tmlyDowSales" | "tmlyMtdSales" | "salesTrendL6wLy", 
  businessDate: string
  ) => {
    const updated = salesHistoryLastYear[salesHistoryLastYearType]?.map((dailyRow: DailyRow) => {
      if (dailyRow.business_date === businessDate) {
        return {
          ...dailyRow, 
          includedInSalesHistory: {
            ...dailyRow.includedInSalesHistory, 
            toggle: !dailyRow.includedInSalesHistory?.toggle
          }
        }
      }
      else {
        return dailyRow
      }
    });

    return {
      ...salesHistoryLastYear, 
      [salesHistoryLastYearType]: updated
    }
}

export const toggleChannelSelection = (channelSelection: (Channel | ChannelIncDel)[], code: string) => {
  const index = channelSelection.findIndex((channel) => channel.code === code); 
  let newChannelSelection = [...channelSelection]; 
  if (index !== -1) {
    newChannelSelection[index].active = ! channelSelection[index].active 
  }
  return newChannelSelection; 
}

export const getSpecificSalesHistory = (
  salesHistory: DailyRow[], 
  salesHistoryType: SalesHistoryType, 
  toggleFalseIfSumChannelSalesZero: boolean = false
) => {
  let filteredSalesHistory = salesHistory
    .filter((dailyRow) => dailyRow !== undefined)
    .filter((dailyRow) => dailyRow.includedInSalesHistory?.[salesHistoryType])

  if (toggleFalseIfSumChannelSalesZero && filteredSalesHistory) {
    filteredSalesHistory.forEach((dailyRow, i) => {
      if (filteredSalesHistory[i] && filteredSalesHistory[i].includedInSalesHistory) {
        filteredSalesHistory[i].includedInSalesHistory = {
          ...(filteredSalesHistory[i].includedInSalesHistory), 
          toggle: (dailyRow.sumChannelSales !== 0)
        }; 
        // filteredSalesHistory[i].includedInSalesHistory.toggle = (dailyRow.sumChannelSales !== 0);
      }
    });
  } 

  return filteredSalesHistory as DailyRow[]; 
}

export const salesHistoryRecentDateCriteria = (
  date: Moment | string
) => {
  date = (typeof date === 'string') ? fromStandardDateFormat(date) : date;
  const selectedDateDow = date.day(); 
  const l6w = getL6w(date); 
  const ttlmMtd = getTtlmMtd(date); 
  const mtd = getMtd(date); 

  const l6wDowSalesCriterion = (date: Moment) => 
    date.day() === selectedDateDow && 
    date.isBetween(
      l6w.from, 
      l6w.to, 
      'day', 
      '[)'
    ); 

  const ttlmMtdSalesCriterion = (date: Moment) => 
    date.isBetween(
      ttlmMtd.from, 
      ttlmMtd.to, 
      'day', 
      '[]'
    ); 

  const salesTrendL6wCriterion = (date: Moment) => 
    date.isBetween(
      l6w.from, 
      l6w.to, 
      'day', 
      '[]'
    );  

  const mtdSalesCriterion = (date: Moment) => 
    date.isBetween(
      mtd.from, 
      mtd.to, 
      'day', 
      '[]'
    ); 

  return {
    l6wDowSalesCriterion, 
    ttlmMtdSalesCriterion, 
    salesTrendL6wCriterion, 
    mtdSalesCriterion, 
    dateRange: {
      from: moment.min([l6w.from, ttlmMtd.from, mtd.from]), 
      to: date
    }
  }
}

export const salesHistoryLyDateCriteria = (
  date: Moment | string
) => {
  date = (typeof date === 'string') ? fromStandardDateFormat(date) : date;
  const selectedDateDow = date.day(); 
  const ttly = thisTimeLastYear(date); 
  const tmly = getTmly(date); 
  const l6wLy = getL6w(ttly); 

  const tmlyDowSalesCriterion = (date: Moment) => {
    const fulfillsCriteria = 
      date.day() === selectedDateDow && 
      date.isSameOrAfter(tmly.from) &&
      date.isSameOrBefore(tmly.to); 
    
    return fulfillsCriteria; 
  }

  const tmlyMtdSalesCriterion = (date: Moment) => {
    const fulfillsCriteria =
      date.isSameOrBefore(ttly) &&
      date.isSameOrAfter(tmly.from) && 
      date.isSameOrBefore(tmly.to); 

    return fulfillsCriteria; 
  }

  const salesTrendL6wLyCriterion = (date: Moment) => {
    const fulfillsCriteria = 
      date.isSameOrAfter(l6wLy.from) && 
      date.isSameOrBefore(l6wLy.to);  

    return fulfillsCriteria; 
  }

  return {
    tmlyDowSalesCriterion, 
    tmlyMtdSalesCriterion, 
    salesTrendL6wLyCriterion, 
    dateRange: {
      from: l6wLy.from, 
      to: ttly
    }
  }
}