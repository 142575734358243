import React from 'react'; 
import LoadingOverlay from 'react-loading-overlay'; 
import MainHeader from './MainHeader'; 
import SideBar from './SideBarNew'; 
import { airtableRequiredFields, loggedInUser } from '../utils/globals';
import { ExpandableSection } from './ExpandableSection';
import VenueDataAssuranceTable from './VenueDataAssuranceTable';
import {
  CheckCircleIcon, 
  ErrorIcon, 
  MobileLayout, 
  Panel, 
  PanelContainer, 
  Section, 
  SectionHeader,
  SectionSubHeader,
  SelectionContainer, 
  Text, 
  Button,
  Loading
} from "./StandardComponents"; 
import { VenueAssuranceData, Dashboard, DataAssuranceCol, Venue, AssuranceData } from '../typings/common'; 
import { generateVenueAssuranceData, getDataAssuranceColFromDailyRow } from '../utils/dataAssurance';
import { getVenueAssuranceDataDaily, getAvailableVenues, getVenueAssuranceDataYearly } from '../services/AirtablePlus';
import Checkbox from './InputFields/Checkbox';
import moment from 'moment';
import { toHumanDateFormat } from '../utils/date';

const DataAssuranceDashboard: React.FC = () => {

  type VenueSelection = Venue & { active: boolean }; 
  type VenuesSelection = { [key: string]: VenueSelection }; 

  const [ loading, setLoading ] = React.useState<boolean>(false); 
  const [ venues, setVenues ] = React.useState<VenuesSelection>(); 
  const [ venuesToLoadAssurance, setVenuesToLoadAssurance ] = React.useState<VenuesSelection>(); 
  const [ assuranceData, setAssuranceData ] = React.useState<AssuranceData>({})

  async function fetchAvailableVenues(): Promise<void> {
    let availableVenueResponse = await getAvailableVenues(
      Dashboard.assurance, 
      loggedInUser()
    ); 

    const venueTemp: {
      [key: string]: VenueSelection
    } = {}; 

    availableVenueResponse.forEach((venue) => {
      venueTemp[venue.code] = {
        ...venue, 
        active: true
      }
    })

    setVenues(venueTemp); 
    setLoading(false); 
  }

  React.useEffect(() => {
    setLoading(true); 
    fetchAvailableVenues(); 
  }, []); 

  React.useEffect(() => {

    if (venuesToLoadAssurance === undefined) {
      return; 
    }

    let venueAssuranceDataPromisesMap: {
      [key: string]: Promise<VenueAssuranceData>
    } = {}; 
    Object.values(venuesToLoadAssurance)
      .filter((venue) => venue.active)
      .forEach((activeVenue) => {
        // 1. Get data from database for [ dailySum, systemSum ] x [ LY, YTD ]
        // (Currently simulated)
        const dailySumRowsLy = getVenueAssuranceDataDaily(
            activeVenue.name, 
            moment().year()-1
          ); 
          
        const systemSumRowLy = getVenueAssuranceDataYearly(
          activeVenue.name, 
          moment().year()-1
        ); 

        const dailySumRowsYtd = getVenueAssuranceDataDaily(
            activeVenue.name, 
            moment().year()
          ); 
        const systemSumRowYtd = getVenueAssuranceDataYearly(
          activeVenue.name, 
          moment().year()
        ); 

        const venueAssuranceDataPromises = Promise.all([
          dailySumRowsLy, 
          systemSumRowLy, 
          dailySumRowsYtd,
          systemSumRowYtd
        ])
        .then(([
          dailySumRowsLyResponse, 
          systemSumRowLyResponse, 
          dailySumRowsYtdResponse,
          systemSumRowYtdResponse
        ]) => {
          
          const dataAssuranceColLy: DataAssuranceCol = 
            getDataAssuranceColFromDailyRow(
              dailySumRowsLyResponse, 
              systemSumRowLyResponse, 
              2020, 
              airtableRequiredFields.RawData.dataAssurance
            ); 

          const dataAssuranceColYtd: DataAssuranceCol = 
            getDataAssuranceColFromDailyRow(
              dailySumRowsYtdResponse, 
              systemSumRowYtdResponse, 
              2021, 
              airtableRequiredFields.RawData.dataAssurance
            ); 

          const venueAssuranceDataTemp: VenueAssuranceData = 
            generateVenueAssuranceData(
              dataAssuranceColLy, 
              dataAssuranceColYtd, 
              airtableRequiredFields.RawData.dataAssurance
            ); 

          return venueAssuranceDataTemp; 
        }); 

        venueAssuranceDataPromisesMap[activeVenue.code] = venueAssuranceDataPromises; 
      }); 

    Promise
      .all(Object.values(venueAssuranceDataPromisesMap))
      .then((promisesResponseArray) => {
        let assuranceDataTemp: AssuranceData = {}
        Object.keys(venueAssuranceDataPromisesMap).forEach((key, index) => {
          assuranceDataTemp[key] = {
            venueName: venuesToLoadAssurance[key].name, 
            venueAssuranceData: promisesResponseArray[index]
          }
        })

        return assuranceDataTemp; 
      })
      .then((res) => {
        setAssuranceData(res)
        setLoading(false); 
      }); 


  }, [venuesToLoadAssurance]); 

  const handleVenueSelectionChange = (toggledVenue: string) => {
    if (venues !== undefined) {
      let venuesTemp = {
        ...venues, 
        [toggledVenue]: {
          ...venues[toggledVenue], 
          active: ! venues[toggledVenue].active
        }
      }; 
      setVenues(venuesTemp); 
    }
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(100, 108, 119, 0.2)', 
          zIndex: 2000, 
          height: '100vh', 
          position: 'fixed'
        })
      }}>
      <MobileLayout>
        <MainHeader />
        <SideBar
          selectedItem="DataAssurance"
          />

        <SectionHeader>Select venue</SectionHeader>
        <Section>
          <SelectionContainer>
            <PanelContainer>
              <Panel>
                {
                  venues && Object.values(venues)
                    .filter((channel, index, arr) => index < arr.length / 2)
                    .map((venue) => (
                      <Checkbox
                        key={venue.code} 
                        disabled={ venue.active === undefined }
                        active={ venue.active }
                        changeHandler={() => handleVenueSelectionChange(venue.code)}>
                        <Text>{venue.name}</Text>
                      </Checkbox>
                    ))
                }
              </Panel>
              <Panel>
                {
                  venues && Object.values(venues)
                    .filter((item, index, arr) => index >= arr.length / 2)
                    .map((venue) => (
                      <Checkbox
                        key={venue.code} 
                        disabled={ venue.active === undefined }
                        active={ venue.active }
                        changeHandler={() => handleVenueSelectionChange(venue.code)}>
                        <Text>{venue.name}</Text>
                      </Checkbox>
                    ))
                }
              </Panel>
            </PanelContainer>
            <Button onClick={() => {
              setVenuesToLoadAssurance(venues); 
              setLoading(true); 
              }}>
              Run assurance
            </Button>
          </SelectionContainer>
        </Section>

        {
          assuranceData && 
          Object.entries(assuranceData).map(([venueCode, element]) => (
            <ExpandableSection
              key={venueCode}
              sectionHeaderText={element.venueName ?? ""}
              defaultExpanded={false}
              headerComponent={
                element.venueAssuranceData?.assuranceStatus ? 
                <CheckCircleIcon /> : 
                <ErrorIcon />
              }>

              <SectionSubHeader>{`Data assured till: 
                ${ element.venueAssuranceData?.yearToDateData.assuredToDate ? 
                  toHumanDateFormat(element.venueAssuranceData?.yearToDateData.assuredToDate) : 
                  "-" }
                `}
              </SectionSubHeader>
              {
                element.venueAssuranceData && 
                  <VenueDataAssuranceTable 
                    {...element.venueAssuranceData}/>
              }

            </ExpandableSection>
          ))
        }
      </MobileLayout>
    </LoadingOverlay>
  )
}

export default DataAssuranceDashboard; 