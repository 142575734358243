import React from 'react'; 
import Highcharts, { PointLabelObject } from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official'; 
import VisPalette from '../../utils/visPalette';
import { formatCurrency } from '../../utils/currency';

type PieChartProps = {
  seriesName: string
  pieChartData: PieChartDataPoint[]
  valueIsCurrency: boolean
}

type PieChartDataPoint = {
  name: string, 
  value: number
}

const pieChartDataOptions = ({ seriesName, pieChartData, valueIsCurrency }: PieChartProps) => {

  function tooltipFormatterCurrency(this: PointLabelObject) {
    return `<b>${this.point.name}</b>`
      + `<br/>$${formatCurrency(this.y)} `
      + `<b>(${this.percentage?.toFixed(1)}%)</b>`
  }

  function tooltipFormatterQuantity(this: PointLabelObject) {
    return `<b>${this.point.name}</b>`
      + `<br/>$${this.y} `
      + `<b>(${this.percentage?.toFixed(1)}%)</b>`
  }

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: null
    },
    tooltip: {
      // pointFormat: '{series.name}: ${point.y:.0f} <b>({point.percentage:.1f}%)</b>'
      formatter: valueIsCurrency ? tooltipFormatterCurrency : tooltipFormatterQuantity
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    colors: VisPalette, 
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [{
      name: seriesName,
      colorByPoint: true,
      data: pieChartData.map((pieChartDataPoint: PieChartDataPoint, index: number) => {
        // For the first element
        if (index === 0) {
          return {
            name: pieChartDataPoint.name, 
            y: pieChartDataPoint.value, 
            sliced: true, 
            selected: true
          }
        }
        else {
          return {
            name: pieChartDataPoint.name, 
            y: pieChartDataPoint.value
          }
        }
      })
    }], 
    credits: {
      enabled: false
    }
  }; 
}

const HcPieChart: React.FC<PieChartProps> = (pieChartProps) => {
  return <HighchartsReact highcharts={Highcharts} options={pieChartDataOptions(pieChartProps)} />; 
}

export default HcPieChart; 