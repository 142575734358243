import { HmacSHA256 } from "crypto-js";
import CryptoJS from "crypto-js";

const generateAppSecret = () => {
	const accessToken =
		process.env.REACT_APP_FACEBOOK_WORKPLACE_ACCESS_TOKEN ?? "";
	const appSecretRaw =
		process.env.REACT_APP_FACEBOOK_WORKPLACE_APP_SECRET ?? "";
	const time = (new Date().valueOf() / 1000).toFixed(0);

	const proof = HmacSHA256(
		CryptoJS.enc.Utf8.parse(`${accessToken}|${time}`), 
		CryptoJS.enc.Utf8.parse(appSecretRaw)
	).toString();
	console.log(proof); 

	return {
		accessToken, // Debug
		appSecretRaw, // Debug
		proof,
		time,
	};
};

export default generateAppSecret; 