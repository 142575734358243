import React from "react"
import { ChannelIncDel, DailyRow, SalesSummaryRowType } from "../typings/common"
import { formatCurrency } from "../utils/currency"
import { 
  getSalesByProduct, 
  getSumChannelSales, 
  getMomComparison, 
  aggregateDailyRows, 
  toDailyRow, 
  getSalesByChannelMarketing, 
  getOrderByChannelMarketing, 
  getAovByChannelMarketing, 
  getYoyComparison 
} from "../utils/dailyRow"
import HcBarChart from "./HighCharts/HcBarChart"
import HcPieChart from "./HighCharts/HcPieChart"
import SalesSummaryRow from "./SalesSummaryRow"
import {
  Section, 
  SectionHeader, 
  SectionSubHeader
} from "./StandardComponents"; 
import ChannelSelection from "./ChannelSelection"; 
import HcLineChart from "./HighCharts/HcLineChartDailyRow"; 
import { marketingDashboardAggregationFunctions, marketingDashboardFieldsToAggregate } from "../utils/globals"
import { Moment } from "moment"

type MarketingDashboardMainBodyProps = {
  fromDate: Moment, 
  toDate: Moment, 
  marketingHistoryRecent: DailyRow[],
  marketingHistoryLy: DailyRow[],
  channelSelection: ChannelIncDel[], 
  channelSelectionChangeHandler: (arg0: string) => void
}

const MarketingDashboardMainBody: React.FC<MarketingDashboardMainBodyProps> = ({
  fromDate, 
  toDate, 
  channelSelection, 
  channelSelectionChangeHandler, 
  marketingHistoryRecent, 
  marketingHistoryLy
}) => {

  if (marketingHistoryRecent.length === 0) {
    return <div><p>No data</p></div>
  }

  // Have to implement marketingDashboardFieldsToAggregate
  const marketingHistoryRecentSelectedPeriod = marketingHistoryRecent
    .filter((dailyRow) => dailyRow.includedInMarketingHistory?.selectedPeriod); 
  
  const aggregatedDailyRow = toDailyRow(
    aggregateDailyRows(
      marketingHistoryRecentSelectedPeriod, 
      marketingDashboardFieldsToAggregate, 
      marketingDashboardAggregationFunctions), 
    true
  ); 

  const momComparison = getMomComparison(marketingHistoryRecent, "net_sales_with_service_charge"); 
  const yoyComparison = getYoyComparison(
    [ ...marketingHistoryLy, 
      ...marketingHistoryRecentSelectedPeriod
    ], 
    "net_sales_with_service_charge"
  ); 
  
  if (marketingHistoryRecent.length !== 0) {
    marketingHistoryRecent.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, channelSelection); 
    })
  }

  const lineChartData = [
    {
      lineSeriesName: "Sales", 
      rawLineSeriesDailyRowData: marketingHistoryRecent, 
      lastDate: toDate
    }
  ]; 
  
  return (
    <div>
      <SectionHeader>Sales summary</SectionHeader>
      <Section>
        <SalesSummaryRow 
          itemNameText="Net sales with service charge" 
          itemValueText={formatCurrency(aggregatedDailyRow.net_sales_with_service_charge)} 
          type={SalesSummaryRowType.headline} />
      </Section>

      <SectionHeader>Month on month comparison</SectionHeader>
      <Section>
        { momComparison && momComparison.map((monthRow) => {
          return (
            <SalesSummaryRow
              key={monthRow.monthName} 
              itemNameText={monthRow.monthName}
              itemValueText={formatCurrency(monthRow.metricFigure)}
              delta={monthRow.momDelta}
              type={SalesSummaryRowType.grayText}
              />
          )
        }) }
      </Section>
      
      <SectionHeader>Year on year comparison</SectionHeader>
      <Section>
        { yoyComparison && yoyComparison.map((yearRow) => {
          return (
            <SalesSummaryRow
              key={yearRow.yearName} 
              itemNameText={`Selected period in ${yearRow.yearName}`}
              itemValueText={formatCurrency(yearRow.metricFigure)}
              delta={yearRow.yoyDelta}
              type={SalesSummaryRowType.grayText}
              />
          )
        }) }
      </Section>

      <SectionHeader>Sales trend</SectionHeader>
      <Section>
        <ChannelSelection 
          channels={channelSelection}
          channelSelectionChangeHandler={channelSelectionChangeHandler}/>
      </Section>
      <Section>
        <HcLineChart 
          seriesName={"Period sales trend by channel"}
          lineChartData={lineChartData}
          valueIsCurrency={true}
          multipleTimePeriod={false}
          metricToPlot="sumChannelSales"
          daysToTrack={toDate.diff(fromDate, 'day') + 1}/>
      </Section>
      
      <SectionHeader>Sales by product</SectionHeader>
      <SectionSubHeader>
        Note: Amount reflected is inclusive of service charge
      </SectionSubHeader>
      <Section>
        <HcPieChart 
          seriesName={"Sales"} 
          pieChartData={getSalesByProduct(aggregatedDailyRow)}
          valueIsCurrency={true}/>
      </Section>

      <SectionHeader>Sales by channel</SectionHeader>
      <SectionSubHeader>
        Note: Service charge included as part of dine-in sales
      </SectionSubHeader>
      <Section>
        <HcPieChart 
          seriesName={"Sales"} 
          pieChartData={getSalesByChannelMarketing(aggregatedDailyRow)}
          valueIsCurrency={true}/>
      </Section>
      
      <SectionHeader>Order count by channel</SectionHeader>
      <Section>
        <HcBarChart 
          seriesName={"Order count"} 
          barChartData={getOrderByChannelMarketing(aggregatedDailyRow)}
          valueIsCurrency={false} />
      </Section>
    
      <SectionHeader>AOV by channel</SectionHeader>
      {/* TODO */}
      <Section>
        <HcBarChart 
          seriesName={"AOV"} 
          barChartData={getAovByChannelMarketing(aggregatedDailyRow)}
          valueIsCurrency={true}/>
      </Section>

      <SectionHeader>Dine-in analysis</SectionHeader>
      <SectionSubHeader>Note: Service charge included as part of dine-in sales</SectionSubHeader>
      <Section>
        <SalesSummaryRow 
          itemNameText="Number of guests" 
          itemValueText={aggregatedDailyRow?.["channel|dine_in|guests"]}
          type={SalesSummaryRowType.grayText}/>

        <SalesSummaryRow 
          itemNameText="Average spend per guest" 
          itemValueText={formatCurrency(aggregatedDailyRow?.average_spend_per_guest)}
          type={SalesSummaryRowType.grayText}/>
      </Section>
    </div>
  );
}
  
export default MarketingDashboardMainBody; 