import React, { ReactElement } from "react";
import styled from "styled-components";
import { CheckCircle, Error, Cancel, Build } from "@material-ui/icons";
import icon_question from "../assets/icon_question.svg";
import PullToRefresh from "react-simple-pull-to-refresh";
import { PullToRefreshProps } from "react-js-pull-to-refresh/dist/src/components/PullToRefresh";

export const MobileLayout = styled.div.attrs({
  id: "outer-container",
})`
  text-align: center;
  padding: 72px max(5vw, 36px);
  overflow-y: visible;
  max-width: 520px;
  flex: 1;
  height: calc(100% - 144px);
  margin: auto;
`;

export const DesktopLayout = styled.div.attrs({
  id: "outer-container",
})<{ scrollable?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 48px;
  ${({ scrollable }) => (scrollable || scrollable === undefined ? "" : `overflow-y: hidden; `)}
`;

export const HeadlineText = styled.p`
  // display: inline-block;
  color: #273561;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0px;
`;

export const Text = styled.p`
  color: #646c77;
  font-size: 13px;
  text-align: left;
  margin: 8px;
  display: inline-block;
`;

export const BoldText = styled(Text)`
  font-weight: 500;
`;

export const LinkedText = styled.a`
  color: #273561;
  font-size: 14px;
  text-align: left;
  display: inline-block;
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ThinTopHeader = styled.div`
  background-color: #f6f7fb;
  height: 28px;
  display: flex;
  padding: 0px 48px;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled.button`
  display: block;
  height: 40px;
  width: 100%;
  background-color: #273561;
  font-size: 13px;
  border-radius: 3px;
  color: #ffffff;
  margin: 8px auto;
  border: none;
  :disabled {
    background-color: rgba(39, 53, 97, 0.3);
  }
`;

export const LightButton = styled.button`
  display: block;
  background-color: #ffffff;
  font-size: 13px;
  border-radius: 3px;
  color: #273561;
  margin: 8px 0px;
  text-align: center;
  border: 0.5px solid #273561;
  padding: 8px 16px;
  :disabled {
    background-color: rgba(39, 53, 97, 0.3);
  }
`;

export const PopupMenu = styled.div`
  position: absolute;
  top: 32px;
  right: 0px;
  border-radius: 3px;
  background-color: #ffffff;
  animation: fadeIn ease 0.4s;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  z-index: 2000;
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.05);
`;

export const MobileMenuItem = styled.a<{ selected?: boolean }>`
  display: block;
  color: ${({ selected }) => (selected ? "#273561" : "#646C77")};
  font-weight: ${({ selected }) => (selected ? "600" : "400")};
  font-size: 16px;
  text-align: left;
  margin: 8px;
  line-height: 32px;
  outline: none;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: #273561;
  }
`;

export const DesktopMenuItem = styled.a<{ selected?: boolean }>`
  display: inline;
  color: ${({ selected }) => (selected ? "#273561" : "#646C77")};
  font-weight: ${({ selected }) => (selected ? "600" : "400")};
  font-size: 12px;
  text-align: left;
  padding: 8px;
  width: max-content;
  outline: none;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: #273561;
  }
`;

export const Separator = styled.div`
  border-bottom: 0.5px solid #646c7780;
  margin: 4px 0px;
`;

export const LinkedHeadlineText = styled(HeadlineText)`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ArrowFont = (props: any) => (
  <Text
    style={{
      fontSize: 8,
      verticalAlign: "middle",
      margin: "1px 1px 2px 2px",
      color: "inherit",
    }}
  >
    {props.children}
  </Text>
);

export const ExpandableChildContainer = styled.div`
  border-left: 1px solid #646c77;
  padding: 0px 0px 4px 12px;
  margin: 8px 0px 16px 0px;
  text-align: left;
`;

export const ChartWrapper = styled.div`
  margin: 16px auto 24px auto;
`;

export const Section = styled.div`
  margin: 16px auto;
  padding: 0px 0px;
`;

export const SectionHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #646c7780;
  margin: 32px auto 0px auto;
  padding: 0px 0px 8px 0px;
  color: #273561;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SectionHeaderNoLine = styled(SectionHeader)`
  border-bottom: none;
  padding-bottom: 4px;
`;

export const SectionHeaderNew = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  border-bottom: 0.5px solid #646c77;
  padding-bottom: 8px;
  color: #273561;
`;

export const LinkedSectionHeader = styled(SectionHeader)`
  :hover {
    cursor: pointer;
  }
`;

export const SectionSubHeader = styled.p`
  text-align: left;
  font-size: 13px;
  padding: 4px 0px;
  margin: 0px;
  color: #646c77;
`;

export const SectionWip = styled.div`
  margin: 18px auto;
  border: solid 5px #ffc0cb;
`;

export const SelectionContainer = styled.div`
  text-align: left; 
  background: #F6F7FB; 
  border-radius: 4px; 
  display: block; 
  flex: 1
  flex-direction: column;
  padding: 16px 24px; 
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

export const Panel = styled.div`
  display: block;
  width: 50%;
`;

export const FailedAlert = styled.div`
  background-color: #ffc3c3;
  color: #ff0033;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 16px;
`;

export const SuccessAlert = styled.div`
  background-color: #14cd72;
  color: #646c77;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 16px;
`;

export const FlexRow = styled.div<{
  marginBottom?: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px` : ``)}
`;

export const Left = styled.div<{
  zIndex?: number;
  flex?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: ${({ flex }) => flex ?? 1};
  z-index: ${({ zIndex }) => zIndex ?? 0};
  justify-content: center;
`;

export const Center = styled.div<{
  zIndex?: number;
  flex?: number;
}>`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  flex: ${({ flex }) => flex ?? 1};
  z-index: ${({ zIndex }) => zIndex ?? 0};
  justify-content: center;
`;

export const Right = styled.div<{
  zIndex?: number;
  flex?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: ${({ flex }) => flex ?? 1};
  z-index: ${({ zIndex }) => zIndex ?? 0};
  justify-content: center;
`;

export const GrayPanel = styled.div<{
  flex?: number;
  backgroundColor?: string;
}>`
  display: flex;
  flex: ${({ flex }) => flex ?? 1};
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#F6F7FB"};
  border-radius: 3px;
  padding: 18px;
  margin: 16px;
  :first-child {
    margin-left: 0px;
  }
  :last-child {
    margin-right: 0px;
  }
`;

export const BottomSection = styled(FlexRow)`
  align-items: stretch;
  align-content: space-between;
  display: inline-flex;
`;

export const RightAlignDatePickerCalendarContainer = styled.div`
  background-color: #000000;
  padding: 20px;
`;

export const FieldInput = styled.input`
  border-radius: 3px;
  color: #646c77;
  padding: 8px;
  font-family: "Eina01";
  text-align: right;
  border: 0.5px solid #646c77;
  height: 40px;
  margin: 0px;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
`;

export const FieldInputNumeric = styled.input.attrs({
  type: "number",
})`
  border-radius: 3px;
  color: #646c77;
  padding: 8px;
  text-align: right;
  border: 0.5px solid #646c77;
  height: 40px;
  margin: 0px;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
`;

export const IconWrapper = styled.div`
  vertical-align: bottom;
  display: inline;
`;

export const DropDown = styled.select`
  width: 150px;
  flex: 1;
  align-self: center;
  border: 0.5px solid #646c77;
  border-radius: 3px;
  margin: 1px;
  font-size: 14px;
  padding: 10px 10px;
  line-height: 22px;
  color: #273561;
  input:focus {
    box-shadow: 0 0 0 3px #646c77;
  }
  -webkit-appearance: none;
`;

export const DropDownDiv = styled.div`
  width: 120px;
  border: 0.5px solid #646c77;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  input:focus {
    box-shadow: 0 0 0 3px #646c77;
  }
  -webkit-appearance: none;
`;

export const TextInDropDown = styled.span`
  font-size: 14px;
  color: #273561;
  :hover {
    cursor: default;
  }
`;

export const SeparatorText: React.FC<{ children: any }> = ({ children }) => {
  const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px -32px;
  `;

  const Line = styled(Separator)`
    margin: 0px 4px;
    flex: 1;
    height: 0px;
    align-self: center;
    :nth-child(1) {
      margin-left: 0px;
    }
    :last-child {
      margin-right: 0px;
    }
  `;

  return (
    <Wrapper>
      <Line />
      <Text>{children}</Text>
      <Line />
    </Wrapper>
  );
};

export const CheckCircleIcon = () => (
  <CheckCircle style={{ color: "#7FC6A4", verticalAlign: "bottom" }} />
);
export const ErrorIcon = () => <Error style={{ color: "#E8774A", verticalAlign: "bottom" }} />;
export const CancelIcon = () => (
  <Cancel style={{ color: "#E8774A", verticalAlign: "bottom", fontSize: 64 }} />
);
export const BuildIcon = () => (
  <Build style={{ color: "#E8774A", verticalAlign: "bottom", fontSize: 64 }} />
);
export const HintIcon = () => <img src={icon_question} alt="" />;

export const VerticalSpacing = styled.div<{ height?: number }>`
  height: ${({ height }) => height ?? 4}px;
`;

export const LoadingDot = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #f5f6fa;
  animation-name: preloaderColor;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  position: absolute;
  :nth-child(1) {
    top: 0;
    left: 0;
    background-color: #273561;
  }
  :nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.25s;
  }
  :nth-child(3) {
    bottom: 0;
    right: 0;
    animation-delay: 0.5s;
  }
  :nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 0.75s;
  }

  @keyframes preloaderColor {
    0% {
      background-color: #f5f6fa;
    }
    25% {
      background-color: #2f417c;
    }
    40% {
      background-color: #f5f6fa;
    }
    100% {
      background-color: #f5f6fa;
    }
  }
`;

const LoadingContainer = styled.div`
  // background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  transition: opacity 0.25s ease-in-out;
`;

const LoadingDotContainer = styled.div`
  height: 32px;
  width: 32px;
  positive: relative;
  transform: rotate(45deg);
`;

export const Loading = (
  <LoadingContainer>
    <LoadingDotContainer>
      <LoadingDot />
      <LoadingDot />
      <LoadingDot />
      <LoadingDot />
    </LoadingDotContainer>
  </LoadingContainer>
);

const StandardPullToRefreshPullingContent = styled.div`
  flex: 1;
  flexdirection: col;
  backgroundcolor: #f6f7fb;
  height: 96px;
`;

const pullingContent = (text: string) => (
  <StandardPullToRefreshPullingContent>
    <div
      style={{
        flexDirection: "row",
        flex: 1,
        display: "inline",
      }}
    >
      {Loading}
      <p
        style={{
          padding: 0,
          margin: 0,
          textAlign: "center",
          fontSize: 12,
          color: "#646C77",
        }}
      >
        {text}
      </p>
    </div>
  </StandardPullToRefreshPullingContent>
);

export const StandardPullToRefresh: React.FC<{
  children: ReactElement;
  onRefresh: () => Promise<void>;
}> = ({ children, onRefresh }) => (
  <PullToRefresh
    isPullable={true}
    onRefresh={onRefresh}
    pullingContent={pullingContent("Pull to refresh")}
    refreshingContent={pullingContent("Refresh")}
    pullDownThreshold={96}
  >
    {children}
  </PullToRefresh>
);

const ChangesSavedStatusIndicatorWrapper = styled.div<{ status: boolean }>`
  align-self: center;
  border-radius: 3px;
  margin: 16px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding: 4px 12px 6px 10px;
  background-color: ${({ status }) => (status ? "#7FC6A433" : "#FFDEC4")};
  color: ${({ status }) => (status ? "#646C77" : "#FF7100")};
`;

export const ChangesSavedStatusIndicator: React.FC<{ status: boolean }> = ({ status }) => {
  return (
    <ChangesSavedStatusIndicatorWrapper status={status}>
      • Changes {status ? "" : "un"}saved
    </ChangesSavedStatusIndicatorWrapper>
  );
};

export const SaveButton = styled.button`
  display: block;
  background-color: #273561;
  font-size: 13px;
  border-radius: 3px;
  color: #ffffff;
  margin: 8px 0px;
  text-align: center;
  border: none;
  padding: 10px 24px;
  font-weight: 600;
  :disabled {
    background-color: rgba(39, 53, 97, 0.3);
  }
`;
