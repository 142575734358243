import React from "react";
import {
  Channel,
  DailyRow,
  DeltaProps,
  SalesHistoryLastYear,
  SalesHistoryRecent,
  SalesSummaryRowType,
} from "../../typings/common";
import { formatCurrency, getDelta } from "../../utils/currency";
import {
  getSalesByChannel,
  getSalesByProduct,
  getSumChannelSales,
  updateDtaRows,
  updateDeaRows,
} from "../../utils/dailyRow";
import ProgressBar from "../ProgressBar";
import SalesSummaryRow from "../SalesSummaryRow";
import ChannelSelection from "../ChannelSelection";
import {
  fromStandardDateFormat,
  getTmlyString,
  thisTimeLastYear,
  toHumanDateFormatWithDow,
} from "../../utils/date";
// import HcLineChart from "./HighCharts/HcLineChartDailyRow"
import { getCeilingWoAnomaly, safeAverage, safeSum } from "../../utils/globals";
import moment from "moment";
import { Section, SectionHeader, SectionSubHeader, HeadlineText } from "../StandardComponents";
import SalesByChannel from "./SalesByChannel";
import HcDistTimeChart from "../HighCharts/HcDistTimeChart";
import SalesByProduct from "./SalesByProduct";
import DtaRows from "./DtaRows";
import DeaRows from "./DeaRows";
import HcLineChart from "../HighCharts/HcLineChartTimeSeries";
import { salesHistoryToSeriesData } from "./salesHistoryToSeriesData";

type SalesDashboardMainBodyProps = {
  venueDailySalesDataset?: DailyRow;
  salesTrendChannelSelection: Channel[];
  salesTrendChannelSelectionChangeHandler: (arg0: string) => void;
  dtaChannelSelection: Channel[];
  dtaChannelSelectionChangeHandler: (arg0: string) => void;
  deaChannelSelection: Channel[];
  deaChannelSelectionChangeHandler: (arg0: string) => void;
  salesHistoryRecent?: SalesHistoryRecent;
  salesHistoryLastYear?: SalesHistoryLastYear;
  monthlyBudget?: number;
  l6wDowSalesComparativeDataChangeHandler: (arg0: any) => void;
  tmlyDowSalesComparativeDataChangeHandler: (arg0: any) => void;
};

const SalesDashboardMainBody: React.FC<SalesDashboardMainBodyProps> = ({
  venueDailySalesDataset,
  salesTrendChannelSelection,
  salesTrendChannelSelectionChangeHandler,
  dtaChannelSelection,
  dtaChannelSelectionChangeHandler,
  deaChannelSelection,
  deaChannelSelectionChangeHandler,
  salesHistoryRecent,
  salesHistoryLastYear,
  monthlyBudget,
  l6wDowSalesComparativeDataChangeHandler,
  tmlyDowSalesComparativeDataChangeHandler,
}) => {
  if (venueDailySalesDataset === undefined) {
    return (
      <div>
        <p>No data</p>
      </div>
    );
  }

  let mtdSalesTotal = 0;

  const mtdSalesFigures =
    salesHistoryRecent?.mtdSales !== undefined
      ? (salesHistoryRecent?.mtdSales
          .map((dailyRow) => dailyRow.net_sales_with_service_charge)
          .filter((sales) => typeof sales === "number") as number[])
      : undefined;

  if (mtdSalesFigures !== undefined) {
    mtdSalesTotal =
      mtdSalesFigures.length > 0 ? mtdSalesFigures.reduce((prev, curr) => prev + curr) : 0;
  }

  venueDailySalesDataset.sumChannelSales = getSumChannelSales(
    venueDailySalesDataset,
    salesTrendChannelSelection
  );

  // Populates data in ComparativeData based on ChannelSelection and
  // selected comparative data for tmlyDowSales
  let selectedTmlyDowSales = [0];
  let tmlyDowSalesAverage = 0;
  let tmlyDowSalesDelta: DeltaProps = {
    deltaDirection: "0",
    deltaText: "",
  };

  // Populates data in ComparativeData based on ChannelSelection and
  // selected comparative data for mtdSales, ttlmMtdSales, tmlyMtdSales and
  // their respective deltas
  let mtdSales = 0,
    ttlmMtdSales = 0,
    tmlyMtdSales = 0,
    ttlmMtdSalesDelta,
    tmlyMtdSalesDelta;
  if (salesHistoryRecent !== undefined && salesHistoryRecent.mtdSales !== undefined) {
    salesHistoryRecent.mtdSales.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });
    const mtdSalesArray = salesHistoryRecent.mtdSales.map(
      (dailyRow) => dailyRow.sumChannelSales
    ) as number[];
    mtdSales = safeSum(mtdSalesArray);
  }
  if (salesHistoryRecent !== undefined && salesHistoryRecent.ttlmMtdSales !== undefined) {
    salesHistoryRecent.ttlmMtdSales.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });
    const ttlmMtdSalesArray = salesHistoryRecent.ttlmMtdSales.map(
      (dailyRow) => dailyRow.sumChannelSales
    ) as number[];
    ttlmMtdSales = safeSum(ttlmMtdSalesArray);

    ttlmMtdSalesDelta = getDelta(ttlmMtdSales, mtdSales);
  }
  if (salesHistoryLastYear !== undefined && salesHistoryLastYear.tmlyMtdSales !== undefined) {
    salesHistoryLastYear.tmlyMtdSales.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });
    const tmlyMtdSalesArray = salesHistoryLastYear.tmlyMtdSales.map(
      (dailyRow) => dailyRow.sumChannelSales
    ) as number[];
    tmlyMtdSales = safeSum(tmlyMtdSalesArray);

    tmlyMtdSalesDelta = getDelta(tmlyMtdSales, mtdSales);
  }

  const businessDateMoment =
    venueDailySalesDataset.business_date !== undefined
      ? fromStandardDateFormat(venueDailySalesDataset.business_date)
      : moment();
  const humanDateWithDow = toHumanDateFormatWithDow(businessDateMoment);
  const ttly = thisTimeLastYear(businessDateMoment);
  const tmly = getTmlyString(businessDateMoment);
  const businessDateStraddles =
    businessDateMoment.year() !== businessDateMoment.weekYear() || ttly.year() !== ttly.weekYear();

  const [yearString, lastYearString] = businessDateStraddles
    ? businessDateMoment.year() < businessDateMoment.weekYear() || ttly.year() < ttly.weekYear()
      ? [
          `${businessDateMoment.year()}/${businessDateMoment.year() + 1}`,
          `${businessDateMoment.year() - 1}/${businessDateMoment.year()}`,
        ]
      : [
          `${businessDateMoment.weekYear()}/${businessDateMoment.weekYear() + 1}`,
          `${businessDateMoment.weekYear() - 1}/${businessDateMoment.weekYear()}`,
        ]
    : [`${businessDateMoment.year()}`, `${businessDateMoment.year() - 1}`];

  // Populates data in salesTrendL6w and salesTrendL6wLy graphs
  if (salesHistoryRecent !== undefined && salesHistoryRecent?.salesTrendL6w !== undefined) {
    // Updates all sumChannelSales
    salesHistoryRecent?.salesTrendL6w?.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });
  }
  if (salesHistoryLastYear !== undefined && salesHistoryLastYear?.salesTrendL6wLy !== undefined) {
    // Updates all sumChannelSales
    salesHistoryLastYear?.salesTrendL6wLy?.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });
  }

  const salesByChannel = getSalesByChannel(venueDailySalesDataset);
  const salesByProduct = getSalesByProduct(venueDailySalesDataset);

  const channelMetricsToPlot = {
    "dine_in_w_svc|sales": "Dine-in",
    "channel|take_out|sales": "Take out",
    "channel|delivery|sales": "Delivery",
    "channel|events|sales": "Events",
    "channel|others|sales": "Others",
  };

  const productMetricsToPlot = {
    "product|food|sales": "Food",
    "product|beverage|sales": "Beverage",
    "product|condiment|sales": "Condiment",
    "product|miscellaneous|sales": "Miscellaneous",
  };

  venueDailySalesDataset = updateDtaRows(venueDailySalesDataset, dtaChannelSelection);
  if (salesHistoryRecent !== undefined && salesHistoryRecent.salesTrendL6w !== undefined) {
    salesHistoryRecent.salesTrendL6w.forEach((dailyRow, index, arr) => {
      arr[index] = updateDtaRows(dailyRow, dtaChannelSelection);
    });
  }
  if (salesHistoryLastYear !== undefined && salesHistoryLastYear.salesTrendL6wLy !== undefined) {
    salesHistoryLastYear.salesTrendL6wLy.forEach((dailyRow, index, arr) => {
      arr[index] = updateDtaRows(dailyRow, dtaChannelSelection);
    });
  }

  venueDailySalesDataset = updateDeaRows(venueDailySalesDataset, deaChannelSelection);
  if (salesHistoryRecent !== undefined && salesHistoryRecent.salesTrendL6w !== undefined) {
    salesHistoryRecent.salesTrendL6w.forEach((dailyRow, index, arr) => {
      arr[index] = updateDeaRows(dailyRow, deaChannelSelection);
    });
  }
  if (salesHistoryLastYear !== undefined && salesHistoryLastYear.salesTrendL6wLy !== undefined) {
    salesHistoryLastYear.salesTrendL6wLy.forEach((dailyRow, index, arr) => {
      arr[index] = updateDeaRows(dailyRow, deaChannelSelection);
    });
  }

  // Populates data in ComparativeData based on ChannelSelection and
  // selected comparative data for l6wDowSales
  let selectedL6wDowSales = [0];
  let l6wDowSalesAverage = 0;
  let l6wDowSalesDelta: DeltaProps = {
    deltaDirection: "0",
    deltaText: "",
  };

  if (salesHistoryRecent && salesHistoryRecent.l6wDowSales) {
    // Updates all sumChannelSales
    salesHistoryRecent?.l6wDowSales?.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
      if (dailyRow.sumChannelSales === 0) {
        dailyRow.includedInSalesHistory = {
          ...dailyRow.includedInSalesHistory,
          toggle: false,
        };
      }
    });

    // Gets only those that toggle = true
    selectedL6wDowSales = salesHistoryRecent?.l6wDowSales
      .filter((dailyRow) => dailyRow.includedInSalesHistory?.toggle)
      .map((dailyRow) => dailyRow.sumChannelSales) as number[];

    if (selectedL6wDowSales !== undefined && selectedL6wDowSales.length !== 0) {
      l6wDowSalesAverage = safeAverage(selectedL6wDowSales);

      l6wDowSalesDelta = getDelta(l6wDowSalesAverage, venueDailySalesDataset?.sumChannelSales);
    }
  }

  if (salesHistoryLastYear && salesHistoryLastYear.tmlyDowSales) {
    // Updates all sumChannelSales
    salesHistoryLastYear?.tmlyDowSales?.forEach((dailyRow) => {
      dailyRow.sumChannelSales = getSumChannelSales(dailyRow, salesTrendChannelSelection);
    });

    // Gets only those that toggle = true
    selectedTmlyDowSales = salesHistoryLastYear?.tmlyDowSales
      .filter((dailyRow) => dailyRow.includedInSalesHistory?.toggle)
      .map((dailyRow) => dailyRow.sumChannelSales) as number[];

    if (selectedTmlyDowSales !== undefined && selectedTmlyDowSales.length !== 0) {
      tmlyDowSalesAverage = safeAverage(selectedTmlyDowSales);

      tmlyDowSalesDelta = getDelta(tmlyDowSalesAverage, venueDailySalesDataset?.sumChannelSales);
    }
  }

  return (
    <div>
      <SectionHeader>Sales summary - {humanDateWithDow}</SectionHeader>
      <Section>
        <SalesSummaryRow
          itemNameText="Net sales with service charge"
          itemValueText={formatCurrency(venueDailySalesDataset?.net_sales_with_service_charge)}
          type={SalesSummaryRowType.headline}
        />

        <SalesSummaryRow
          itemNameText="Gross sales"
          itemValueText={formatCurrency(venueDailySalesDataset?.gross_sales)}
          type={SalesSummaryRowType.grayText}
        />

        <SalesSummaryRow
          itemNameText="- Discounts"
          itemValueText={formatCurrency(venueDailySalesDataset?.discounts)}
          type={SalesSummaryRowType.grayText}
        />

        <SalesSummaryRow
          itemNameText="Net sales"
          itemValueText={formatCurrency(venueDailySalesDataset?.net_sales)}
          type={SalesSummaryRowType.grayBoldText}
        />

        <SalesSummaryRow
          // Frontend's "Service charge" = Backend's revenue_service_charge
          itemNameText="+ Service charge"
          itemValueText={formatCurrency(venueDailySalesDataset?.service_charge)}
          type={SalesSummaryRowType.grayText}
        />

        <SalesSummaryRow
          itemNameText="Net sales with service charge"
          itemValueText={formatCurrency(venueDailySalesDataset?.net_sales_with_service_charge)}
          type={SalesSummaryRowType.grayBoldText}
        />

        <SalesSummaryRow
          itemNameText="+ Tips"
          itemValueText={formatCurrency(venueDailySalesDataset?.["tips|amount"] ?? 0)}
          type={SalesSummaryRowType.grayText}
        />

        <SalesSummaryRow
          itemNameText="+ GST"
          itemValueText={formatCurrency(venueDailySalesDataset?.gst)}
          type={SalesSummaryRowType.grayText}
        />

        <SalesSummaryRow
          itemNameText="Total collection"
          itemValueText={formatCurrency(venueDailySalesDataset?.total_collection)}
          type={SalesSummaryRowType.grayBoldText}
        />
      </Section>

      <SectionHeader>MTD sales (actuals vs budget)</SectionHeader>
      <Section>
        <ProgressBar progress={mtdSalesTotal} goal={monthlyBudget} />
      </Section>

      <SectionHeader>Sales trend</SectionHeader>
      <Section>
        <ChannelSelection
          channels={salesTrendChannelSelection}
          channelSelectionChangeHandler={salesTrendChannelSelectionChangeHandler}
        />
      </Section>
      <Section>
        <SalesSummaryRow
          itemNameText={`Net sales with service charge for ${humanDateWithDow}`}
          itemValueText={formatCurrency(venueDailySalesDataset.sumChannelSales)}
          type={SalesSummaryRowType.headline}
        />

        <SalesSummaryRow
          itemNameText={`Average ${venueDailySalesDataset?.day_of_week} sales in last 6 weeks`}
          itemValueText={formatCurrency(l6wDowSalesAverage)}
          type={SalesSummaryRowType.grayText}
          delta={l6wDowSalesDelta}
          comparativeData={salesHistoryRecent?.l6wDowSales}
          comparativeDataChangeHandler={l6wDowSalesComparativeDataChangeHandler}
        />

        <SalesSummaryRow
          itemNameText={`Average ${venueDailySalesDataset?.day_of_week} sales in ${tmly}`}
          itemValueText={formatCurrency(tmlyDowSalesAverage)}
          type={SalesSummaryRowType.grayText}
          delta={tmlyDowSalesDelta}
          comparativeData={salesHistoryLastYear?.tmlyDowSales}
          comparativeDataChangeHandler={tmlyDowSalesComparativeDataChangeHandler}
        />
      </Section>
      {/* {mtdSales = 0, ttlmMtdSales = 0, tmlyMtdSales = 0} */}
      <Section>
        <SalesSummaryRow
          itemNameText="MTD net sales with service charge"
          itemValueText={formatCurrency(mtdSales)}
          type={SalesSummaryRowType.headline}
        />

        <SalesSummaryRow
          itemNameText="MTD sales this time last month"
          itemValueText={formatCurrency(ttlmMtdSales)}
          type={SalesSummaryRowType.grayText}
          delta={ttlmMtdSalesDelta}
        />

        <SalesSummaryRow
          itemNameText={`MTD sales this time in ${tmly}`}
          itemValueText={formatCurrency(tmlyMtdSales)}
          type={SalesSummaryRowType.grayText}
          delta={tmlyMtdSalesDelta}
        />
      </Section>

      <Section>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "net_sales_with_service_charge",
            42,
            true
          )}
          multipleTimePeriod
          height={320}
        />
      </Section>

      <SectionHeader>Sales by channel</SectionHeader>
      <SectionSubHeader>Note: Service charge included as part of dine-in sales</SectionSubHeader>
      <Section>
        <HeadlineText>{toHumanDateFormatWithDow(businessDateMoment)}</HeadlineText>

        <SalesByChannel salesByChannelData={salesByChannel} />
      </Section>
      <Section>
        <HeadlineText>Last 6 weeks</HeadlineText>

        <HcDistTimeChart
          seriesName="Last 6 weeks sales distribution"
          distTimeChartData={
            salesHistoryRecent?.salesTrendL6w !== undefined ? salesHistoryRecent?.salesTrendL6w : []
          }
          valueIsCurrency={true}
          daysToTrack={42}
          metricsToPlot={Object.keys(channelMetricsToPlot) as (keyof DailyRow)[]}
          metricsName={channelMetricsToPlot}
          lastDate={businessDateMoment}
        />
      </Section>
      <Section>
        <HeadlineText>Corresponding 6 weeks in {lastYearString}</HeadlineText>
        <HcDistTimeChart
          seriesName="This time last year 6 weeks sales distribution"
          distTimeChartData={
            salesHistoryLastYear?.salesTrendL6wLy !== undefined
              ? salesHistoryLastYear?.salesTrendL6wLy
              : []
          }
          valueIsCurrency={true}
          daysToTrack={42}
          metricsToPlot={Object.keys(channelMetricsToPlot) as (keyof DailyRow)[]}
          metricsName={channelMetricsToPlot}
          lastDate={ttly}
        />
      </Section>

      <SectionHeader>Sales by product</SectionHeader>
      <Section>
        <HeadlineText>{toHumanDateFormatWithDow(businessDateMoment)}</HeadlineText>

        <SalesByProduct salesByProductData={salesByProduct} />
      </Section>
      <Section>
        <HeadlineText>Last 6 weeks</HeadlineText>

        <HcDistTimeChart
          seriesName="Last 6 weeks sales distribution"
          distTimeChartData={
            salesHistoryRecent?.salesTrendL6w !== undefined ? salesHistoryRecent?.salesTrendL6w : []
          }
          valueIsCurrency={true}
          daysToTrack={42}
          metricsToPlot={Object.keys(productMetricsToPlot) as (keyof DailyRow)[]}
          metricsName={productMetricsToPlot}
          lastDate={businessDateMoment}
        />
      </Section>
      <Section>
        <HeadlineText>Corresponding 6 weeks in {ttly.weekYear()}</HeadlineText>
        <HcDistTimeChart
          seriesName="This time last year 6 weeks sales distribution"
          distTimeChartData={
            salesHistoryLastYear?.salesTrendL6wLy !== undefined
              ? salesHistoryLastYear?.salesTrendL6wLy
              : []
          }
          valueIsCurrency={true}
          daysToTrack={42}
          metricsToPlot={Object.keys(productMetricsToPlot) as (keyof DailyRow)[]}
          metricsName={productMetricsToPlot}
          lastDate={ttly}
        />
      </Section>

      <SectionHeader>Dine-in and events analysis</SectionHeader>
      <SectionSubHeader>
        Note: Service charge included as part of dine-in sales. Event sales before Jul 2020 are
        included as part of Dine-in. Data compared against last year same week same day.
      </SectionSubHeader>
      <Section>
        <ChannelSelection
          channels={deaChannelSelection}
          channelSelectionChangeHandler={deaChannelSelectionChangeHandler}
        />
      </Section>
      <Section>
        <HeadlineText>{toHumanDateFormatWithDow(businessDateMoment)}</HeadlineText>
        <DeaRows
          current={{
            totalSales: venueDailySalesDataset?.deaSales,
            guestCount: venueDailySalesDataset?.deaGuestCount,
            spendPerGuest: venueDailySalesDataset?.deaSpendPerGuest,
          }}
          previous={{
            totalSales: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.deaSales,
            guestCount: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.deaGuestCount,
            spendPerGuest: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.deaSpendPerGuest,
          }}
        />
      </Section>
      <Section>
        <HeadlineText>Average spend per guest</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "deaSpendPerGuest",
            42,
            true
          )}
          upperBoundToDisplayLogic={getCeilingWoAnomaly}
          multipleTimePeriod
          height={320}
        />
      </Section>
      <Section>
        <HeadlineText>Sales</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "deaSales",
            42,
            true
          )}
          multipleTimePeriod
          height={320}
        />
      </Section>
      <Section>
        <HeadlineText>Number of guest</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "deaGuestCount",
            42,
            false
          )}
          multipleTimePeriod
          height={320}
        />
      </Section>

      <SectionHeader>Delivery and takeaway analysis</SectionHeader>
      <SectionSubHeader>Note: Data compared against last year same week same day</SectionSubHeader>
      <Section>
        <ChannelSelection
          channels={dtaChannelSelection}
          channelSelectionChangeHandler={dtaChannelSelectionChangeHandler}
        />
      </Section>
      <Section>
        <HeadlineText>{toHumanDateFormatWithDow(businessDateMoment)}</HeadlineText>
        <DtaRows
          current={{
            totalSales: venueDailySalesDataset?.dtaSales,
            orderCount: venueDailySalesDataset?.dtaOrderCount,
            aov: venueDailySalesDataset?.dtaAov,
          }}
          previous={{
            totalSales: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.dtaSales,
            orderCount: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.dtaOrderCount,
            aov: salesHistoryLastYear?.salesTrendL6wLy?.slice(-1)[0]?.dtaAov,
          }}
        />
      </Section>
      <Section>
        <HeadlineText>Average order value (AOV)</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "dtaAov",
            42,
            true
          )}
          multipleTimePeriod
          height={320}
        />
      </Section>
      <Section>
        <HeadlineText>Sales</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "dtaSales",
            42,
            true
          )}
          upperBoundToDisplayLogic={getCeilingWoAnomaly}
          multipleTimePeriod
          height={320}
        />
      </Section>
      <Section>
        <HeadlineText>Order count</HeadlineText>
        <HcLineChart
          seriesData={salesHistoryToSeriesData(
            salesHistoryRecent?.salesTrendL6w ?? [],
            businessDateMoment,
            salesHistoryLastYear?.salesTrendL6wLy ?? [],
            ttly,
            "dtaOrderCount",
            42,
            false
          )}
          multipleTimePeriod
          height={320}
        />
      </Section>
    </div>
  );
};

export default SalesDashboardMainBody;
