import { computeMtdEndState, monthDailyRowsToMtdSalesForIncentiveCalc } from "./incentive";

import { DailyIncentiveLog } from "../typings/common";
import { Moment } from "moment";
import { airtableRequiredFields } from "./globals";
import { getDailyRowPeriod } from "../services/DataSourceRailroadSwitch";
import { getIncentiveAdminMonthlyParams } from "../components/IncentivesDashboard/IncentivesController";

// Assumes monthlyPotWithDailyAddition
const getIncentiveDashboardValsNoLock = (
  venueName: string,
  date: Moment
): Promise<DailyIncentiveLog> => {
  const monthStart = date.clone().startOf("month");

  // Get sales data for venue from Airtable
  const dailySalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      venueName,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  const incentiveStructureParamsPromise = () =>
    getIncentiveAdminMonthlyParams(venueName, date.year(), date.month() + 1, false);

  return Promise.all([dailySalesPromise(), incentiveStructureParamsPromise()]).then(
    ([dailySalesResponse, incentiveStructureParamsResponse]) => {
      const mtdDailyIncentiveLog = computeMtdEndState(
        monthDailyRowsToMtdSalesForIncentiveCalc(dailySalesResponse, date),
        incentiveStructureParamsResponse,
        date
      );

      const dailyTarget = incentiveStructureParamsResponse.dailyTargets
        ? incentiveStructureParamsResponse.dailyTargets[date.date()]
        : undefined;

      const output = {
        ...incentiveStructureParamsResponse,
        ...mtdDailyIncentiveLog,
        dailyTarget,
        potReward: mtdDailyIncentiveLog.potReward,
      };

      return output;
    }
  );
};

export default getIncentiveDashboardValsNoLock;
