import { HistoricSalesItem, VenueData } from "../../typings/groupwideDashboard";
import { airtableRequiredFields, safeSum } from "../globals";
import {
  fromStandardDateFormat,
  fromStandardDateTimeFormat,
  thisTimeLastYear,
  toStandardYearMonthFormat,
} from "../date";
import moment, { Moment } from "moment";

import { Venue } from "../../typings/common";
import { getDailyRowPeriod } from "../../services/DataSourceRailroadSwitch";
import { getVenueMonthlyBudget } from "../../services/AirtablePlus";
import { netSalesWithServiceChargeFromDailyRow } from "../dailyRow";

const getVenueGroupwideSalesDashboardData = (venue: Venue, date: Moment): Promise<VenueData> => {
  const ttly = thisTimeLastYear(date);

  const time12MonthsBeforeTtly = ttly.clone().subtract(1, "year").startOf("month");

  const time52WeeksBeforeTtly = ttly.clone().subtract(52, "week").startOf("week");

  const twoYearsAgo = moment.min([time12MonthsBeforeTtly, time52WeeksBeforeTtly]);

  const salesFiguresPromise = getDailyRowPeriod(
    twoYearsAgo,
    date.subtract(1, "day"),
    venue.name ?? "",
    airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
  ).then((dailyRows) => {
    const monthStart = date.clone().startOf("month");
    const yearStart = date.clone().startOf("year");
    let mtdSales = 0;
    let ytdSales = 0;
    let lastUpdated = moment();
    let dayNetSalesWithServiceCharge = 0;

    const historicSales: HistoricSalesItem[] = dailyRows.map((dailyRow) => {
      const netSalesWithServiceCharge = netSalesWithServiceChargeFromDailyRow(dailyRow);

      const businessDate = fromStandardDateFormat(dailyRow.business_date ?? "");

      const updated = fromStandardDateTimeFormat(dailyRow.end_time ?? "");
      if (updated.isAfter(lastUpdated)) {
        lastUpdated = updated;
      }

      if (businessDate.isSameOrAfter(monthStart, "day")) {
        mtdSales += netSalesWithServiceCharge;
      }

      if (businessDate.isSameOrAfter(yearStart, "day")) {
        ytdSales += netSalesWithServiceCharge;
      }

      if (date.isSame(businessDate, "day")) {
        dayNetSalesWithServiceCharge = netSalesWithServiceCharge;
      }

      return {
        date: businessDate,
        sales: netSalesWithServiceCharge,
      };
    });

    console.log(`${venue.code} DONE! `);

    return {
      mtdSales,
      ytdSales,
      lastUpdated,
      dayNetSalesWithServiceCharge,
      historicSales,
    };
  });

  const monthIndex = date.month() + 1; // i.e. Jan = 1

  const yearMonthArray: string[] = Array.from(Array(monthIndex).keys()).map((val) => {
    return toStandardYearMonthFormat(date.year(), val + 1);
  });

  // Returns array of YTD months budget for the venue -->
  // Then returns last month's budget and the year's budget
  const budgetPromiseArray = yearMonthArray.map(
    (yearMonthString) => () => getVenueMonthlyBudget(venue.name, yearMonthString)
  );

  const budgetPromise = Promise.all(budgetPromiseArray.map((promise) => promise())).then(
    (budgetPromiseResponse) => {
      return {
        monthBudget: budgetPromiseResponse[budgetPromiseResponse.length - 1] as number,
        yearBudget: safeSum(budgetPromiseResponse as number[]),
      };
    }
  );

  return Promise.all([salesFiguresPromise, budgetPromise]).then(
    ([salesFiguresResponse, budgetResponse]) => ({
      mtdSales: salesFiguresResponse.mtdSales,
      ytdSales: salesFiguresResponse.ytdSales,
      lastUpdated: salesFiguresResponse.lastUpdated,
      dayNetSalesWithServiceCharge: salesFiguresResponse.dayNetSalesWithServiceCharge,
      historicSales: salesFiguresResponse.historicSales,
      monthBudget: budgetResponse.monthBudget,
      yearBudget: budgetResponse.yearBudget,
    })
  );
};

export default getVenueGroupwideSalesDashboardData;
