import {
  DailyIncentiveLog,
  DailyMetric,
  DatabaseUpdateResponse,
  FieldAddUpdatePromiseProps,
  IncentiveAdminMonthlyParams,
  IncentiveMechanic,
  IncentiveParamsInputVarType,
} from "../../typings/common";
import {
  addIncentiveParam,
  getDayFullMonthIncentiveParams,
  getMonthIncentiveParams,
  getMtdDailySales,
  getVenueRecordId,
  updateIncentiveParam,
} from "../../services/AirtablePlus";
import { aggregateDatabaseUpdateResponse, percentageValueTransformer } from "../../utils/globals";
import {
  getIncentiveDashboardValsForEvp,
  isEvpGrkIncentiveConsolidationLogicActive,
} from "../../utils/evpGrkIncentiveConsolidationLogic";
import {
  getIncentivesDashboardValsForTcc,
  isTccIncentiveConsolidationLogicActive,
} from "../../utils/tccIncentiveConsolidationLogic";

import { Moment } from "moment";
import computeIncentivesDailyAdditions from "../../utils/computeIncentivesDailyAdditions";
import getIncentiveDashboardValsNoLock from "../../utils/getIncentiveDashboardValsNoLock";
import getMtdSalesEndResult from "../../utils/getMtdSalesEndResult";
import moment from "moment";
import { netSalesWithServiceChargeFromRecord } from "../../utils/dailyRow";

type IncentiveParamsFieldAddUpdatePromiseProps = FieldAddUpdatePromiseProps & {
  field: IncentiveParamsInputVarType;
};

export const getIncentiveDashboardVals = (
  venueName: string,
  date: Moment,
  incentiveMechanic: IncentiveMechanic
): Promise<DailyIncentiveLog> => {
  // Temporary implementation of custom logic to combine EVP AS and GK numbers in Incentive Dashboard
  if (isEvpGrkIncentiveConsolidationLogicActive && venueName === "Extra Virgin Pizza") {
    return getIncentiveDashboardValsForEvp(date);
  }
  // Temporary implementation of custom logic to combine TCC A, B, S in Incentive Dashboard
  else if (
    isTccIncentiveConsolidationLogicActive &&
    venueName === "The Coconut Club (Beach Road)"
  ) {
    return getIncentivesDashboardValsForTcc(date);
  } else if (incentiveMechanic === "monthlyPotWithDailyAddition") {
    return getIncentiveDashboardValsNoLock(venueName, date);
  } else if (incentiveMechanic === "monthlyPot") {
    const incentiveStructureParamsPromise = getDayFullMonthIncentiveParams(venueName, date);

    // daily sales = net sales with service charge
    const mtdSalesPromise = getMtdDailySales(venueName, date);

    return Promise.all([incentiveStructureParamsPromise, mtdSalesPromise]).then(
      ([incentiveStructureParamsResponse, mtdSalesResponse]) => {
        const mtdSales = getMtdSalesEndResult(mtdSalesResponse, date);

        const { salesExceedDailyTarget, additionPreTargetPortion, additionPostTargetPortion } =
          computeIncentivesDailyAdditions(
            incentiveStructureParamsResponse,
            mtdSales.dailySales,
            date.format("YYYY-MM-DD")
          );

        const potBeforeAddition = mtdSalesResponse.reduce((accum, curr) => {
          const iterNetSalesWithServiceCharge = netSalesWithServiceChargeFromRecord(curr);
          const iterAdditions = computeIncentivesDailyAdditions(
            incentiveStructureParamsResponse,
            iterNetSalesWithServiceCharge,
            curr.fields.business_date ?? ""
          );
          return (
            accum + iterAdditions.additionPreTargetPortion + iterAdditions.additionPostTargetPortion
          );
        }, 0);

        const potAfterAddition =
          potBeforeAddition + additionPreTargetPortion + additionPostTargetPortion;

        const isPotUnlocked =
          mtdSales.monthlySales >= (incentiveStructureParamsResponse.monthlyTarget ?? 0);

        // Note: preTargetPortion calculation independent of isPotUnlocked as this value is hypothetical
        // (assuming isPotUnlocked)
        const preTargetPortion =
          Math.min(incentiveStructureParamsResponse.monthlyTarget ?? 0, mtdSales.monthlySales) *
          (incentiveStructureParamsResponse.monthlyTargetCut ?? 0);

        const postTargetPortion = isPotUnlocked
          ? (incentiveStructureParamsResponse.monthlyTargetBonusCut ?? 0) *
            ((mtdSales.monthlySales ?? 0) - (incentiveStructureParamsResponse.monthlyTarget ?? 0))
          : 0;

        const potReward =
          (potAfterAddition + preTargetPortion + postTargetPortion) /
          (incentiveStructureParamsResponse.numberToSplit ?? 1);

        const dailyIncentiveLog = {
          ...incentiveStructureParamsResponse,
          ...mtdSales,
          salesExceedDailyTarget,
          additionPreTargetPortion,
          additionPostTargetPortion,
          potBeforeAddition,
          potAfterAddition,
          isPotUnlocked,
          preTargetPortion,
          postTargetPortion,
          potReward,
        };

        return dailyIncentiveLog;
      }
    );
  } else {
    return Promise.reject("venueDataSource invalid");
  }
};

export const recordToDailyIncentiveLog = (
  record: Airtable.Record<DailyIncentiveLog>
): DailyIncentiveLog => {
  let output: {
    [key: string]: any;
  } = {};

  const fieldsToConvert: (keyof DailyIncentiveLog)[] = [
    "dailyTarget",
    "dailyTargetCut",
    "dailyTargetBonusCut",
    "numberToSplit",
    "monthlyTarget",
    "lastUpdated",
    "dailySales",
    "salesExceedDailyTarget",
    "additionPreTargetPortion",
    "additionPostTargetPortion",
    "potBeforeAddition",
    "potAfterAddition",
    "monthlySales",
    "isPotUnlocked",
    "potReward",
  ];

  fieldsToConvert.forEach((key) => {
    output[key] = record.fields[key];
  });

  return output;
};

// Incentive - Params database query to IncentiveAdminMonthlyParams object
export const getIncentiveAdminMonthlyParams = (
  venue: string,
  year: number,
  month: number,
  usePercentageValueTransformer: boolean = true
) =>
  getMonthIncentiveParams(venue, year, month, false).then((incentiveParamsRecords) => {
    let incentiveAdminMonthlyParams: {
      [key: string]: any;
      dailyTargets: DailyMetric;
    } = {
      dailyTargets: {},
    };
    incentiveParamsRecords.forEach((incentiveParamsRecord) => {
      const varType = incentiveParamsRecord.fields.input_var_type;

      if (varType === "numberToSplit" || varType === "monthlyTarget") {
        incentiveAdminMonthlyParams[varType] = incentiveParamsRecord.fields.input_var_value;
      } else if (
        varType === "dailyTargetCut" ||
        varType === "dailyTargetBonusCut" ||
        varType === "monthlyTargetCut" ||
        varType === "monthlyTargetBonusCut"
      ) {
        if (usePercentageValueTransformer) {
          incentiveAdminMonthlyParams[varType] = percentageValueTransformer.backendToFrontend(
            incentiveParamsRecord.fields.input_var_value
          ); // Percentage
        } else {
          incentiveAdminMonthlyParams[varType] = incentiveParamsRecord.fields.input_var_value;
        }
      } else if (varType === "dailyTarget") {
        const day = incentiveParamsRecord.fields.day ?? 0;
        const dailyTarget = incentiveParamsRecord.fields.input_var_value;

        incentiveAdminMonthlyParams.dailyTargets[day] = dailyTarget;
      }
    });

    return incentiveAdminMonthlyParams as IncentiveAdminMonthlyParams;
  });

type ValRecordIdPair = {
  value: number;
  recordId: string;
};

export const getIncentiveAdminMonthlyParamsWithRecordIds = (
  venue: string,
  year: number,
  month: number
) => {
  return getMonthIncentiveParams(venue, year, month, false).then((incentiveParamsRecords) => {
    const incentiveAdminMonthlyParams: {
      dailyTargetCut?: ValRecordIdPair;
      dailyTargetBonusCut?: ValRecordIdPair;
      numberToSplit?: ValRecordIdPair;
      monthlyTarget?: ValRecordIdPair;
      dailyTargets: {
        [key: number]: ValRecordIdPair;
      };
      monthlyTargetCut?: ValRecordIdPair;
      monthlyTargetBonusCut?: ValRecordIdPair;
    } = {
      dailyTargets: {},
    };

    incentiveParamsRecords.forEach((incentiveParamsRecord) => {
      const varType = incentiveParamsRecord.fields.input_var_type;

      if (
        varType === "dailyTargetCut" ||
        varType === "dailyTargetBonusCut" ||
        varType === "numberToSplit" ||
        varType === "monthlyTarget" ||
        varType === "monthlyTargetCut" ||
        varType === "monthlyTargetBonusCut"
      ) {
        incentiveAdminMonthlyParams[varType] = {
          value: incentiveParamsRecord.fields.input_var_value,
          recordId: incentiveParamsRecord.id,
        };
      } else if (varType === "dailyTarget") {
        const day = incentiveParamsRecord.fields.day ?? 0;

        incentiveAdminMonthlyParams["dailyTargets"][day] = {
          value: incentiveParamsRecord.fields.input_var_value,
          recordId: incentiveParamsRecord.id,
        };
      }
    });

    return incentiveAdminMonthlyParams;
  });
};

const fieldAddUpdatePromisePropsToPromise = (
  venueRecordId: string,
  year: number,
  month: number,
  props: IncentiveParamsFieldAddUpdatePromiseProps
) => {
  if (props.toAdd) {
    // create promise
    if (props.field !== "dailyTarget") {
      return addIncentiveParam({
        venue: venueRecordId,
        year: year ?? 0,
        month: month ?? 0,
        input_var_type: props.field,
        input_var_value: props.value,
        modified_by: props.modifiedBy,
      });
    } else {
      return addIncentiveParam({
        venue: venueRecordId,
        year: year ?? 0,
        month: month ?? 0,
        input_var_type: "dailyTarget",
        input_var_value: props.value,
        day: props.day,
        modified_by: props.modifiedBy,
      });
    }
  } else {
    // update promise
    if (props.recordIdToUpdate !== undefined) {
      if (props.field !== "dailyTarget") {
        return updateIncentiveParam(props.recordIdToUpdate, {
          venue: venueRecordId,
          year: year ?? 0,
          month: month ?? 0,
          input_var_type: props.field,
          input_var_value: props.value,
          modified_by: props.modifiedBy,
        });
      } else {
        return updateIncentiveParam(props.recordIdToUpdate, {
          venue: venueRecordId,
          year: year ?? 0,
          month: month ?? 0,
          input_var_type: "dailyTarget",
          input_var_value: props.value,
          day: props.day,
          modified_by: props.modifiedBy,
        });
      }
    } else {
      return Promise.reject({
        message: "Attempted to update without valid recordId",
      });
    }
  }
};

// IncentiveAdminMonthlyParams object to add or update Incentive - Params database promise + result
export const pushIncentiveAdminQuery = (
  userInputParams: IncentiveAdminMonthlyParams,
  incentiveMechanic?: IncentiveMechanic,
  username?: string
): Promise<DatabaseUpdateResponse> => {
  // ) => {
  if (userInputParams.venue && userInputParams.year && userInputParams.month) {
    return Promise.all([
      getIncentiveAdminMonthlyParamsWithRecordIds(
        userInputParams.venue,
        userInputParams.year,
        userInputParams.month
      ),
      getVenueRecordId(userInputParams.venue),
    ]).then(([incentiveAdminMonthlyParamsResponse, venueRecordId]) => {
      let fieldsAddUpdatePromiseProps: IncentiveParamsFieldAddUpdatePromiseProps[] = [];
      const fieldsToCheck: IncentiveParamsInputVarType[] =
        incentiveMechanic === "monthlyPotWithDailyAddition"
          ? ["dailyTargetCut", "dailyTargetBonusCut", "numberToSplit", "monthlyTarget"]
          : ["monthlyTargetCut", "monthlyTargetBonusCut", "numberToSplit", "monthlyTarget"];
      fieldsToCheck.forEach((fieldToCheck) => {
        if (fieldToCheck !== "dailyTarget") {
          const fieldValue =
            fieldToCheck !== "dailyTargetCut" &&
            fieldToCheck !== "dailyTargetBonusCut" &&
            fieldToCheck !== "monthlyTargetCut" &&
            fieldToCheck !== "monthlyTargetBonusCut"
              ? (userInputParams[fieldToCheck] as number)
              : percentageValueTransformer.frontendToBackend(
                  userInputParams[fieldToCheck] as number
                );
          fieldsAddUpdatePromiseProps.push({
            field: fieldToCheck,
            toAdd: incentiveAdminMonthlyParamsResponse[fieldToCheck] === undefined,
            value: fieldValue,
            recordIdToUpdate:
              incentiveAdminMonthlyParamsResponse[fieldToCheck] === undefined
                ? undefined
                : incentiveAdminMonthlyParamsResponse[fieldToCheck]?.recordId,
            modifiedBy: username,
          });
        }
      });

      if (incentiveMechanic === "monthlyPotWithDailyAddition") {
        const dayCount = moment()
          .year(userInputParams.year as number)
          .month((userInputParams.month as number) - 1)
          .daysInMonth();
        const fieldsToAddDailyTargets: {
          [key: number]: boolean;
        } = {};
        Array.from(Array(dayCount).keys()).map((val, i) => {
          const dayIndex = i + 1;
          if (
            incentiveAdminMonthlyParamsResponse &&
            incentiveAdminMonthlyParamsResponse.dailyTargets &&
            userInputParams.dailyTargets
          ) {
            fieldsAddUpdatePromiseProps.push({
              field: "dailyTarget",
              toAdd: incentiveAdminMonthlyParamsResponse.dailyTargets[dayIndex] === undefined,
              value: userInputParams.dailyTargets[dayIndex] ?? 0,
              day: dayIndex,
              recordIdToUpdate:
                incentiveAdminMonthlyParamsResponse.dailyTargets[dayIndex] === undefined
                  ? undefined
                  : incentiveAdminMonthlyParamsResponse.dailyTargets[dayIndex].recordId,
              modifiedBy: username,
            });
          }
        });
      }

      const fieldsAddUpdatePromises = fieldsAddUpdatePromiseProps.map(
        (props) =>
          // Note: not actually calling the promise here!
          () =>
            fieldAddUpdatePromisePropsToPromise(
              venueRecordId,
              userInputParams.year ?? 0,
              userInputParams.month ?? 0,
              props
            )
      );

      return Promise.all(fieldsAddUpdatePromises.map((t) => t())).then((responseArray) =>
        Promise.resolve(aggregateDatabaseUpdateResponse(responseArray))
      );
    });
  } else {
    return Promise.resolve({
      rowsAddAttempt: 0,
      rowsAdded: 0,
      rowsUpdateAttempt: 0,
      rowsUpdated: 0,
      message: "venue, year or month missing in args",
    });
  }
};
