import React from "react"; 
import styled from "styled-components"; 
import { formatCurrency } from "../utils/currency";
import { safeSum } from "../utils/globals";
import { 
  FlexRow, 
  Left, 
  Center, 
  Right, 
  Text as ogText, 
  BoldText as ogBoldText 
} from "./StandardComponents";

interface ProgressBarProps {
  barHeight?: number, 
  start?: number, 
  progress: number | number[], 
  multipleStackedLabels?: string[], // if progress is an array with multiple numbers
  target?: number, 
  end: number, 
  startText?(
    progress: number, 
    end: number, 
    start?: number, 
    target?: number
    ): string, 
  targetText?(
    progress: number, 
    end: number, 
    start?: number, 
    target?: number
    ): string,
  endTopText?(
    progress: number, 
    end: number, 
    start?: number, 
    target?: number
    ): string, 
  endBottomText?(
    progress: number, 
    end: number, 
    start?: number, 
    target?: number
    ): string, 
  outline?: boolean, 
  includeTargetLine?: boolean
}

const Wrapper = styled.div`
  padding: 8px 0px; 
  display: flex; 
  flex-direction: column; 
`

const Text = styled(ogText)`
  margin: 8px 0px; 
`

const BoldText = styled(ogBoldText)`
  margin: 8px 0px; 
`

const ContainerWrapper = styled.div<{ 
  barHeight: number, 
  target?: number, 
  includeTargetLine?: boolean
}>`
  position: relative; 
  padding: ${({ barHeight, target, includeTargetLine }) => target !== undefined && includeTargetLine ? 0.25*barHeight : 0}px 0px; 
  box-sizing: border-box; 
`

const Container = styled.div<{ 
  barHeight: number, 
  outline: boolean 
}>`
  height: ${({ barHeight }) => barHeight}px; 
  background-color: #F6F7FB; 
  border-radius: 3px; 
  padding: 0px; 
  display: flex; 
  flex-direction: row; 
  flex: 1; 
  z-index: 3; 
  border: ${({ outline }) => outline ? `solid 0.5px #646C7780;` : `none; `} 
`

const Progress = styled.div<{
  barHeight: number, 
  progress: number, 
  start: number, 
  end: number, 
  achieved: boolean
}>`
  height: ${({ barHeight }) => barHeight}px; 
  width: ${({ progress, start, end }) => {
    const widthPercent = (progress - start) / (end - start); 
    return widthPercent*100; 
  }}%; 
  border-radius: 3px; 
  border: 0px; 
  background-color: ${({achieved}) => achieved ? `#7FC6A4` : `#273561`}; 
  z-index: 1;
  transition: all 0.2s ease-in-out; 
  // -webkit-transition: all 0.2s ease-in-out;
  // -moz-transition: all 0.2s ease-in-out;
  // -ms-transition: all 0.2s ease-in-out;
  // -o-transition: all 0.2s ease-in-out;
`

const TargetLine = styled.div<{
  barHeight: number
}>`
  position: absolute; 
  top: 0%; 
  left: 50%; 
  height: ${({ barHeight }) => 1.5*barHeight}px; 
  // border-image-source: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='8%2c12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  // border-image-width: 1px; 
  border: 1px dashed #646C77; 
  z-index: 2; 

`

const ProgressBar: React.FC<ProgressBarProps> = (
  {
    barHeight = 42, 
    start = 0, 
    progress, 
    target, 
    end, 
    startText, 
    targetText,
    endTopText, 
    endBottomText, 
    // Currently implemented without end logic
    // i.e. end hardcoded to be end = start + 2*(target-start)
    // To save dev work on positioning targetText and endTopText, 
    outline = false, 
    includeTargetLine = true
  }
) => {

  const achieved = progress >= end || 
    (target !== undefined && progress >= target); 

  return (
		<Wrapper>
			{endTopText !== undefined && (
				<Right>
					<Text>
						{endTopText(
							Array.isArray(progress) ? safeSum(progress) : progress,
							end,
							start,
							target
						)}
					</Text>
				</Right>
			)}
			<ContainerWrapper
				barHeight={barHeight}
				target={target}
				includeTargetLine={includeTargetLine}
			>
				<Container barHeight={barHeight} outline={outline}>
					{!Array.isArray(progress) && (
						<Progress
							barHeight={barHeight}
							progress={progress}
							start={start}
							end={end}
							achieved={achieved}
						></Progress>
					)}
					{Array.isArray(progress) &&
						progress.map((iterProgress, i) => (
							<Progress
								barHeight={barHeight}
								progress={iterProgress}
								start={start}
								end={end}
								achieved={achieved}
							></Progress>
						))}
				</Container>
				{includeTargetLine && <TargetLine barHeight={barHeight}></TargetLine>}
			</ContainerWrapper>

			<FlexRow>
				{startText !== undefined && (
					<Left>
						<BoldText>
							{startText(
								Array.isArray(progress) ? safeSum(progress) : progress,
								end,
								start,
								target
							)}
						</BoldText>
					</Left>
				)}
				{targetText !== undefined && (
					<>
						<Center>
							<Text>
								{targetText(
									Array.isArray(progress) ? safeSum(progress) : progress,
									end,
									start,
									target
								)}
							</Text>
						</Center>
						<Right></Right>
					</>
				)}
				{endBottomText !== undefined && (
					<Right>
						<Text>
							{endBottomText(
								Array.isArray(progress) ? safeSum(progress) : progress,
								end,
								start,
								target
							)}
						</Text>
					</Right>
				)}
			</FlexRow>
		</Wrapper>
	);
}

export default ProgressBar; 