import React, { FormEvent } from "react"; 
import styled from "styled-components"; 

const Wrapper = styled.div`
  display: block; 
  text-align: left; 
  margin: 0px 4px; 
`

const Text = styled.p`
  display: block; 
  font-size: 13px; 
  font-weight: 500; 
  color: #646C77; 
  margin: 4px auto 2px auto; 
`

const DropDown = styled.select`
  border: 0.5px solid #646C77;
  border-radius: 4px;
  margin: 2px auto 16px auto; 
  width: 100%; 
  box-sizing: border-box; 
  font-size: 16px; 
  padding: 12px 12px; 
  color: #273561; 
  input:focus {
    box-shadow: 0 0 0 3px #646C77; 
  }
  -webkit-appearance: none; 
`

const OptGroup = styled.optgroup`
  font-size: 16px; 
`

export type SelectFieldProps = {
  label?: string, 
  placeholderLabel?: string, 
  defaultValue?: string,
  options: Option[],
  changeHandler?: (arg0: any) => void
}

type Option = {
  value: string, 
  label: string
}

const SelectField = ({
  label, 
  placeholderLabel, 
  defaultValue,
  changeHandler,
  options = []
}: SelectFieldProps) => {

  const [ value, setValue ] = React.useState<string>(); 

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue); 
    }
  })

  const handleChange = (event: FormEvent) => {
    if (changeHandler !== undefined) {
      const value = (event.target as HTMLFormElement).value; 
      changeHandler(value); 
    }
  }
  
  return (
    <Wrapper>
      <label>
        { label && <Text>{label}</Text>}
        <DropDown value={value} onChange={handleChange}>
          <OptGroup>
            <option disabled selected hidden>{placeholderLabel}</option>
            { options && options.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>)) }
          </OptGroup>
        </DropDown>
      </label>
    </Wrapper>
  )
}

export default SelectField; 