import React from "react"
import { DailyRow } from "../../typings/common"; 
import { formatCurrency } from "../../utils/currency"; 
import styled from "styled-components"; 
import { toHumanDateFormatWithDow } from "../../utils/date";
import Checkbox from "../InputFields/Checkbox"; 
import { Text } from "../StandardComponents"; 

export type ComparativeDataPointProps = {
  key: string, 
  label: string, 
  active: boolean, 
  changeHandler?: (arg0: any) => void
}

const Wrapper = styled.div `
  margin-left: 4px; 
  text-align: left; 
`

const TextMoney = styled(Text) `
  float: right; 
  margin-right: 0px; 
`

const ComparativeDataPoint: React.FC<ComparativeDataPointProps> = ({
  key, 
  label, 
  active, 
  changeHandler
}) => {

  return (
    <Wrapper>
      <Checkbox 
        active={active}
        disabled={false}
        changeHandler={changeHandler}>
        <Text>{ label }</Text>
      </Checkbox>
    </Wrapper>
  )
}

export default ComparativeDataPoint; 