import React from "react"
import styled from "styled-components"
import { 
  ArrowFont, 
  LinkedSectionHeader, 
  Section
} from "./StandardComponents"

const Wrapper = styled.div`
  margin: 8px auto 20px auto; 
  text-align: left; 
`

const RightWrapper = styled.div`
  display: inline-block; 
  position: relative; 
  float: right; 
  margin-right: 8px; 
`

type ExpandableSectionProps = {
  sectionHeaderText: string, 
  children: any, 
  defaultExpanded: boolean, 
  headerComponent?: React.ReactNode
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  sectionHeaderText, 
  children, 
  defaultExpanded, 
  headerComponent
}) => {

  const [ isExpanded, setIsExpanded ] = React.useState<boolean>(defaultExpanded); 

  const childWithContainer = (
    <Section>
      {children}
    </Section>
  )

  const arrow = (
    <RightWrapper>
      <ArrowFont>
        {isExpanded ? "▲" : "▼"}
      </ArrowFont>
    </RightWrapper>
  )

  return (
    <Wrapper>
      <LinkedSectionHeader onClick={() => setIsExpanded(! isExpanded)}>
        {sectionHeaderText}
        {arrow}
        <RightWrapper>{headerComponent}</RightWrapper>
      </LinkedSectionHeader>
      { isExpanded && childWithContainer }
    </Wrapper>
  ); 
}