import {
  AirtableSelectParams,
  DailyRow,
  Venue,
  User,
  AirtableUpdateParams,
  Budget,
  IncentiveParam,
  DailyIncentiveLog,
  LightspeedIntegrationData,
  LightspeedCredentials,
  Bug,
  AdhocInputValueType,
  AdhocDataInput,
  RecognitionData,
} from "../typings/common";
import { Record } from "airtable";
import { fromStandardDateTimeFormat } from "../utils/date";
const AirtablePlus = require("airtable-plus");

const airtableRawSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_RAW_SHEET,
});

const airtableVenueSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_VENUE_SHEET,
});

const airtableUserSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_USER_SHEET,
});

const airtableBudgetSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_BUDGET_SHEET,
});

const airtableYearlySheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_YEARLY_DATA_SHEET,
});

const airtableIncentiveParamsSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_INCENTIVE_PARAMS_SHEET,
});

const airtableIncentiveLockedDataSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_INCENTIVE_LOCKED_DATA_SHEET,
});

const airtableHolidaysDataSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_HOLIDAYS_DATA_SHEET,
});

const airtableLightspeedIntegrationSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_LIGHTSPEED_INTEGRATION_SHEET,
});

const airtableBugSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_BUG_SHEET,
});

const airtableAdhocInputSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_ADHOC_INPUT_SHEET,
});

const airtableOpenArmsRecognitionSheet = new AirtablePlus({
  baseID: process.env.REACT_APP_TABLE_ID,
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  tableName: process.env.REACT_APP_OPEN_ARMS_RECOGNITION_SHEET,
});

export function getAirtableRowsRawData(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<DailyRow>[]> {
  return airtableRawSheet.read({
    ...airtableSelectParams,
    sort: [
      {
        field: "business_date",
        direction: "asc",
      },
    ],
  });
}

export function getAirtableRowsVenue(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<Venue>[]> {
  return airtableVenueSheet.read(airtableSelectParams);
}

export function getAirtableRowsUser(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<User>[]> {
  return airtableUserSheet.read(airtableSelectParams);
}

export function getAirtableRowsBudget(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<Budget>[]> {
  return airtableBudgetSheet.read(airtableSelectParams);
}

export function getAirtableRowsYearlyData(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<DailyRow>[]> {
  return airtableYearlySheet.read(airtableSelectParams);
}

export function getAirtableRowsIncentiveParams(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<IncentiveParam>[]> {
  return airtableIncentiveParamsSheet.read(airtableSelectParams);
}

export function getAirtableRowsIncentiveLocked(
  airtableSelectParams: AirtableSelectParams = {}
): Promise<Record<DailyIncentiveLog>[]> {
  return airtableIncentiveLockedDataSheet.read(airtableSelectParams);
}

export function getAirtableRowsOccasion(airtableSelectParams: AirtableSelectParams = {}): Promise<
  Record<{
    date: string;
    occasionName: string;
    type: "Holiday" | "Observance";
  }>[]
> {
  return airtableHolidaysDataSheet.read(airtableSelectParams);
}

export function updateAirtableRowsUser(
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<User>[]> {
  return airtableUserSheet.updateWhere(airtableUpdateParams.where, airtableUpdateParams.data);
}

export function addAirtableRowsIncentiveParams(
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<IncentiveParam>> {
  return airtableIncentiveParamsSheet.create(airtableUpdateParams.data);
}

export function addAirtableRowBug(
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<Bug>> {
  return airtableBugSheet.create(airtableUpdateParams.data);
}

export function updateAirtableRowsIncentiveParams(
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<IncentiveParam>> {
  console.log(airtableUpdateParams);
  return airtableIncentiveParamsSheet.update(airtableUpdateParams.rowId, airtableUpdateParams.data);
}

export const getLightspeedStagingCredentialsRows = (): Promise<
  Record<LightspeedIntegrationData>[]
> =>
  airtableLightspeedIntegrationSheet.read({
    filterByFormula: "{dataType}='StagingApiCredentials'",
  });

export const getLightspeedStagingCredentials = (): Promise<LightspeedCredentials> =>
  getLightspeedStagingCredentialsRows().then((credResponse) => {
    const accessToken = credResponse.find((row) => row.fields.key === "accessToken")?.fields.value;
    const refreshToken = credResponse.find((row) => row.fields.key === "refreshToken")?.fields
      .value;

    return {
      accessToken,
      refreshToken,
      expiry: fromStandardDateTimeFormat(credResponse[0].fields.lastModified ?? ""),
    };
  });

export const getLightspeedProductionCredentialsRows = (): Promise<
  Record<LightspeedIntegrationData>[]
> =>
  airtableLightspeedIntegrationSheet.read({
    filterByFormula: "{dataType}='ProductionApiCredentials'",
  });

export const updateLightspeedProductionCredentials = async (
  venueCode: string,
  accessToken: string,
  refreshToken: string
): Promise<boolean> => {
  const updateAccessTokenResult: boolean = await airtableLightspeedIntegrationSheet
    .updateWhere(
      `AND({dataType}='ProductionApiCredentials', {key}='accessToken', {venueCode}='${venueCode}')`,
      {
        value: accessToken,
      }
    )
    .then((response: Record<LightspeedIntegrationData>[]) => response.length === 1);

  const updateRefreshTokenResult: boolean = await airtableLightspeedIntegrationSheet
    .updateWhere(
      `AND({dataType}='ProductionApiCredentials', {key}='refreshToken', {venueCode}='${venueCode}')`,
      {
        value: refreshToken,
      }
    )
    .then((response: Record<LightspeedIntegrationData>[]) => response.length === 1);

  return updateAccessTokenResult && updateRefreshTokenResult;
};

export const getAdhocInput = (
  airtableSelectParams: AirtableSelectParams = {}
): Promise<
  Record<{
    venueCode: string;
    date: string;
    valueType: AdhocInputValueType;
    value: number;
    lastUpdated: string;
  }>[]
> =>
  airtableAdhocInputSheet.read({
    ...airtableSelectParams,
    fields: ["venueCode", "date", "valueType", "value", "lastUpdated"],
  });

export const addAirtableRowAdhocInput = (
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<AdhocDataInput>> => airtableAdhocInputSheet.create(airtableUpdateParams.data);

export const updateAirtableRowAdhocInput = (
  airtableUpdateParams: AirtableUpdateParams
): Promise<Record<AdhocDataInput>> =>
  airtableAdhocInputSheet.update(airtableUpdateParams.rowId, airtableUpdateParams.data);

export const addAirtableRowOpenArmsRecognition = (
  params: AirtableUpdateParams
): Promise<Record<RecognitionData>> => airtableOpenArmsRecognitionSheet.create(params.data);

export const getAirtableRowOpenArmsRecognition = (
  params: AirtableSelectParams = {}
): Promise<Record<RecognitionData>[]> => airtableOpenArmsRecognitionSheet.read(params);
