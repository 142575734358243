import moment from 'moment';
import React from 'react'; 
import styled from 'styled-components'; 
import { Dow, Occasion } from '../../typings/common';
import { FlexRow, HeadlineText, Left, Right } from '../StandardComponents';
import CalendarCell from './CalendarCell';

type ValueCalendarProps = {
  year: number, 
  month: number, 
  holidays?: Occasion[], 
  observances?: Occasion[], 
  dailyValues?: {
    [key: number]: number | undefined
  }, 
  changeHandler: (id: number, input: number) => void, 
  disabled?: (dayIndex: number) => boolean
}

const CalendarGrid = styled.div`
  display: grid; 
  grid-gap: 20px 20px; 
  grid-template-columns: 7; 
  grid-template-rows: 7; 
  margin: 16px 8px; 
`

const DowHeader = styled(HeadlineText)<{ colIndex: number }>`
  grid-row-start: 1; 
  grid-column-start: ${({colIndex}) => colIndex}; 
  text-align: center; 
`

const ValueCalendar: React.FC<ValueCalendarProps> = ({
  year, 
  month, 
  holidays, 
  observances, 
  dailyValues, 
  changeHandler, 
  disabled
}) => {

  const firstDow = moment().year(year).month(month-1).date(1).day(); 
  const dayCount = moment().year(year).month(month-1).daysInMonth(); 

  React.useEffect(() => {

  })

  const holidayDayIndices = 
    holidays !== undefined ? 
      holidays.map((holiday) => holiday.date.date()) : 
      []; 
  const observanceDayIndices = 
    observances !== undefined ? 
      observances.map((observance) => observance.date.date()) : 
      []; 

  return (
    <CalendarGrid onKeyDown={(e) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault(); 
      }
    }}>
      <DowHeader colIndex={1}>
        M
      </DowHeader>
      <DowHeader colIndex={2}>
        T
      </DowHeader>
      <DowHeader colIndex={3}>
        W
      </DowHeader>
      <DowHeader colIndex={4}>
        T
      </DowHeader>
      <DowHeader colIndex={5}>
        F
      </DowHeader>
      <DowHeader colIndex={6}>
        S
      </DowHeader>
      <DowHeader colIndex={7}>
        S
      </DowHeader>

      {
        Array.from(Array(dayCount).keys()).map((val, i) => {
          
          const dayIndex = i+1; 
          const posIndex = 
            i + (firstDow > 0 ? firstDow : 7) - 1;  
          const rowIndex = 2+posIndex/7; 
          const colIndex = 1+posIndex%7; 
          const occasionType = 
            holidayDayIndices.includes(dayIndex) ? 
              "Holiday" : 
              (
                observanceDayIndices.includes(dayIndex) ? 
                  "Observance" : 
                  undefined
              ); 
          
          return (
						<CalendarCell
							fieldName={dayIndex.toString()}
							key={dayIndex.toString()}
							rowIndex={rowIndex}
							colIndex={colIndex}
							fieldId={dayIndex}
							fieldValue={
								dailyValues && dailyValues[dayIndex]
									? (dailyValues[dayIndex] as number)
									: undefined
							}
							occasionType={occasionType}
							changeHandler={changeHandler}
							disabled={disabled ? disabled(dayIndex) : false}
						/>
					); 
        })
      }
    </CalendarGrid>
  )
}

export default ValueCalendar; 

const initialRef = () => {
  const arr = [...Array(31).keys()];
  let output: {
    [key: number]: any; 
  } = {}; 
  arr.forEach((i) => {
    output[i+1] = null; 
  }); 
  return output; 
};