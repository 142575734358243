import React from "react";
import {
  getAvailableVenues,
  getSalesHistoryLastYear,
  getSalesHistoryRecent,
  getVenueMonthlyBudget,
} from "../../services/AirtablePlus";
import { getDailyRowFilterByFormula } from "../../services/AirtablePlusFilterByFormula";
import MainHeader from "../MainHeader";
import { fromStandardDateFormat, toHumanDateTimeFormat } from "../../utils/date";
import {
  DailyRow,
  SalesHistoryRecent,
  SalesHistoryLastYear,
  Channel,
  Venue,
  Dashboard,
  TlbgConcept,
} from "../../typings/common";
import { toDailyRow } from "../../utils/dailyRow";
import SalesDashboardMainBody from "./SalesDashboardMainBody";
import {
  getSpecificSalesHistory,
  toggleChannelSelection,
  toggleSalesHistoryLastYear,
  toggleSalesHistoryRecent,
} from "../../utils/salesHistory";
import { deaChannels, defaultSalesChannels, dtaChannels, loggedInUser } from "../../utils/globals";
import LoadingOverlay from "react-loading-overlay";
import SelectField from "../InputFields/SelectField";
import {
  Loading,
  MobileLayout,
  Section,
  SectionHeaderNoLine,
  SectionSubHeader,
  StandardPullToRefresh,
  Text,
} from "../StandardComponents";
import TextField from "../InputFields/TextField";
import SideBar from "../SideBarNew";
import moment from "moment";
import { getAirtableRowsRawData } from "../../services/AirtableService";

type SelectionProps = {
  venue: string | undefined;
  date: string | undefined;
};

const SalesDashboard: React.FC = (props) => {
  const [selection, setSelection] = React.useState<SelectionProps>();
  const [availableVenues, setAvailableVenues] = React.useState<Venue[]>([]);
  const [venueDailySalesDataset, setVenueDailySalesDataset] = React.useState<DailyRow>();
  const [salesHistoryRecent, setSalesHistoryRecent] = React.useState<SalesHistoryRecent>();
  const [salesHistoryLastYear, setSalesHistoryLastYear] = React.useState<SalesHistoryLastYear>();
  const [monthlyBudget, setMonthlyBudget] = React.useState<number>();
  const [pullToRefreshStatus, setPullToRefreshStatus] = React.useState<boolean>(false);

  const [salesTrendChannelSelection, setSalesTrendChannelSelection] =
    React.useState<Channel[]>(defaultSalesChannels);

  const [dtaChannelSelection, setDtaChannelSelection] = React.useState<Channel[]>(dtaChannels); // Delivery and takeaway analysis

  const [deaChannelSelection, setDeaChannelSelection] = React.useState<Channel[]>(deaChannels); // Delivery and takeaway analysis

  const [loading, setLoading] = React.useState<boolean>(false);

  async function fetchAvailableVenues(): Promise<void> {
    const availableVenuesResponse = await getAvailableVenues(Dashboard.sales, loggedInUser());

    setAvailableVenues(availableVenuesResponse);
    setLoading(false);
  }

  async function getVenueDailySalesDataset(): Promise<void> {
    const venueDailySalesDatasetResponse = await getAirtableRowsRawData({
      filterByFormula: getDailyRowFilterByFormula({
        venue: selection?.venue,
        onDateString: selection?.date,
      }),
    });

    if (venueDailySalesDatasetResponse.length === 1) {
      const dailyRow = toDailyRow(venueDailySalesDatasetResponse[0]);
      if (dailyRow !== undefined) {
        setVenueDailySalesDataset(dailyRow);
      }
    } else if (venueDailySalesDatasetResponse.length < 1) {
      setVenueDailySalesDataset(undefined);
      console.log("Cannot find entry", venueDailySalesDatasetResponse);
    } else {
      console.log("Multiple entries", venueDailySalesDatasetResponse);
      setVenueDailySalesDataset(undefined);
    }
  }

  async function getSalesHistoryRecentDataset(date: string, venue: string): Promise<void> {
    const getSalesHistoryRecentResponse = await getSalesHistoryRecent({ date, venue });

    // Manipulate data into right form
    if (Array.isArray(getSalesHistoryRecentResponse)) {
      const l6wDowSales = getSpecificSalesHistory(
        getSalesHistoryRecentResponse,
        "l6wDowSales",
        true
      );

      const ttlmMtdSales = getSpecificSalesHistory(
        getSalesHistoryRecentResponse,
        "ttlmMtdSales",
        true
      );

      const salesTrendL6w = getSpecificSalesHistory(
        getSalesHistoryRecentResponse,
        "salesTrendL6w",
        true
      );

      const mtdSales = getSpecificSalesHistory(getSalesHistoryRecentResponse, "mtdSales", true);

      const tempSalesHistoryRecent = {
        l6wDowSales: l6wDowSales,
        ttlmMtdSales: ttlmMtdSales,
        salesTrendL6w: salesTrendL6w,
        mtdSales: mtdSales,
      };

      setSalesHistoryRecent(tempSalesHistoryRecent);
    }
  }

  async function getSalesHistoryLyDataset(date: string, venue: string): Promise<void> {
    const getSalesHistoryLastYearResponse = await getSalesHistoryLastYear({ date, venue });

    // Manipulate data into right form
    if (Array.isArray(getSalesHistoryLastYearResponse)) {
      const tmlyDowSales = getSpecificSalesHistory(
        getSalesHistoryLastYearResponse,
        "tmlyDowSales",
        true
      );

      const tmlyMtdSales = getSpecificSalesHistory(
        getSalesHistoryLastYearResponse,
        "tmlyMtdSales",
        true
      );

      const salesTrendL6wLy = getSpecificSalesHistory(
        getSalesHistoryLastYearResponse,
        "salesTrendL6wLy",
        true
      );

      const tempSalesHistoryLastYear = {
        tmlyDowSales: tmlyDowSales,
        tmlyMtdSales: tmlyMtdSales,
        salesTrendL6wLy: salesTrendL6wLy,
      };

      setSalesHistoryLastYear(tempSalesHistoryLastYear);
    }
  }

  async function getVenueMonthlyBudgetData(venue: string, date: string): Promise<void> {
    const getVenueMonthlyBudgetDataResponse = await getVenueMonthlyBudget(venue, date);

    setMonthlyBudget(getVenueMonthlyBudgetDataResponse as number);
  }

  React.useEffect(() => {
    setLoading(true);

    async function fetchAvailableVenuesAndLoadDefaults() {
      await fetchAvailableVenues();
      const defaultVenue = (props as any).location.state?.defaultVenue;
      if (defaultVenue !== undefined) {
        setSelection({
          venue: defaultVenue,
          date: moment().format("YYYY-MM-DD"),
        });
      }
    }

    fetchAvailableVenuesAndLoadDefaults();
  }, []);

  const loadData = (currentSelection?: SelectionProps) => {
    if (currentSelection && currentSelection?.date && currentSelection?.venue) {
      setLoading(true);

      const venueDataSource = availableVenues.find(
        (venue) => venue.name === selection?.venue
      )?.data_source;
      const venueDataSourceDayOne = availableVenues.find(
        (venue) => venue.name === selection?.venue
      )?.data_source_day_one;
      console.log(venueDataSourceDayOne);

      if (venueDataSource === "airtable") {
        Promise.all([
          getVenueDailySalesDataset(),
          getSalesHistoryRecentDataset(currentSelection?.date, currentSelection?.venue),
          getSalesHistoryLyDataset(currentSelection?.date, currentSelection?.venue),
          getVenueMonthlyBudgetData(currentSelection?.venue, currentSelection?.date),
        ]).then(() => {
          setLoading(false);
        });
      }
    }
  };

  React.useEffect(() => {
    loadData(selection);
  }, [selection]);

  const handleVenueChange = (selectedValue: string) => {
    setSelection({
      venue: selectedValue,
      date: selection?.date,
    });
  };

  const handleDateChange = (selectedDate: string) => {
    setSelection({
      venue: selection?.venue,
      date: selectedDate,
    });
  };

  const handlel6wDowSalesComparativeDataChange = (businessDate: string) => {
    if (salesHistoryRecent) {
      const updated = toggleSalesHistoryRecent(salesHistoryRecent, "l6wDowSales", businessDate);
      setSalesHistoryRecent(updated);
    }
  };

  const handleTmlyDowSalesComparativeDataChange = (businessDate: string) => {
    if (salesHistoryLastYear) {
      const updated = toggleSalesHistoryLastYear(
        salesHistoryLastYear,
        "tmlyDowSales",
        businessDate
      );
      setSalesHistoryLastYear(updated);
    }
  };

  const handleSalesTrendChannelSelectionChange = (code: string) => {
    const updated = toggleChannelSelection(salesTrendChannelSelection, code);
    setSalesTrendChannelSelection(updated as Channel[]);
  };

  const handleDtaChannelSelectionChange = (code: string) => {
    const updated = toggleChannelSelection(dtaChannelSelection, code);
    setDtaChannelSelection(updated as Channel[]);
  };

  const handleDeaChannelSelectionChange = (code: string) => {
    const updated = toggleChannelSelection(deaChannelSelection, code);
    setDeaChannelSelection(updated as Channel[]);
  };

  React.useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          position: "fixed",
          height: "100vh",
        }),
      }}
    >
      <StandardPullToRefresh onRefresh={async () => loadData(selection)}>
        <MobileLayout>
          <MainHeader secondaryLogo={selection?.venue as TlbgConcept} />

          <SideBar selectedItem="Sales" />

          <SectionHeaderNoLine>Venue daily sales report</SectionHeaderNoLine>
          <SectionSubHeader>
            {`Last updated: ${
              venueDailySalesDataset?.last_updated
                ? toHumanDateTimeFormat(venueDailySalesDataset.last_updated)
                : "-"
            }`}
          </SectionSubHeader>
          <Section>
            <SelectField
              label="Venue"
              placeholderLabel="Select venue..."
              changeHandler={handleVenueChange}
              defaultValue={selection?.venue}
              options={availableVenues
                .filter((venue) => venue.name !== undefined)
                .map((venue) => ({
                  label: venue.name as string,
                  value: venue.name as string,
                }))}
            />

            <TextField
              defaultDate={
                selection?.date !== undefined
                  ? fromStandardDateFormat(selection?.date).toDate()
                  : undefined
              }
              label="Date"
              inputType="date"
              changeHandler={handleDateChange}
            />
          </Section>

          {selection?.date && selection?.venue ? (
            venueDailySalesDataset !== undefined ? (
              <SalesDashboardMainBody
                venueDailySalesDataset={venueDailySalesDataset}
                salesTrendChannelSelection={salesTrendChannelSelection}
                salesTrendChannelSelectionChangeHandler={handleSalesTrendChannelSelectionChange}
                dtaChannelSelection={dtaChannelSelection}
                dtaChannelSelectionChangeHandler={handleDtaChannelSelectionChange}
                deaChannelSelection={deaChannelSelection}
                deaChannelSelectionChangeHandler={handleDeaChannelSelectionChange}
                salesHistoryRecent={salesHistoryRecent}
                salesHistoryLastYear={salesHistoryLastYear}
                monthlyBudget={monthlyBudget}
                l6wDowSalesComparativeDataChangeHandler={handlel6wDowSalesComparativeDataChange}
                tmlyDowSalesComparativeDataChangeHandler={handleTmlyDowSalesComparativeDataChange}
              />
            ) : (
              <Text>No data found for selected venue and date.</Text>
            )
          ) : null}
        </MobileLayout>
      </StandardPullToRefresh>
    </LoadingOverlay>
  );
};

export default SalesDashboard;
