import React from "react"
import { Text as ogText } from "../StandardComponents"; 
import styled from "styled-components"; 
import ComparativeDataPoint, { ComparativeDataPointProps } from "./ComparativeDataPoint";
import { ExpandableComponent } from "../ExpandableComponent";

type ComparativeDataProps = {
  expandIntroText?: string, 
  headerText?: string
  comparativeData?: ComparativeDataPointProps[], 
  comparativeDataChangeHandler: (arg0: string, arg1: boolean) => void, // arg1 is current (pre-toggled)
  allCheckText?: string // If included, there will be a checkbox before all the options that checks every box
}

const Text = styled(ogText)`
  margin-top: 0px; 
  margin-left: 0px; 
`

const ComparativeData: React.FC<ComparativeDataProps> = ({
  expandIntroText, 
  headerText, 
  comparativeData, 
  comparativeDataChangeHandler, 
  allCheckText
}) => {

  const isAllActive = comparativeData ?
    comparativeData?.every((point) => point.active) : 
    false; 

  const comparativeDataContainer = (
    <>
      { headerText && <Text>{ headerText}</Text> }
      {
        allCheckText && 
          <ComparativeDataPoint 
            key="{ALL}"
            label={allCheckText}
            active={isAllActive}
            changeHandler={() => {
              comparativeDataChangeHandler(
                "{ALL}", 
                isAllActive
              )
            }}
            />
      }
      { 
        comparativeData?.map((comparativeDataPoint) => {
          return <ComparativeDataPoint
            key={comparativeDataPoint?.key} 
            label={comparativeDataPoint?.label} 
            active={comparativeDataPoint?.active}
            changeHandler={() => {
              comparativeDataChangeHandler(
                comparativeDataPoint?.key, 
                comparativeDataPoint.active
              )
            }} />
        })
      }
    </>
  )

  return (
    <ExpandableComponent
      sectionHeaderText={expandIntroText ?? ""}
      defaultExpanded={false}
    >
      { comparativeDataContainer } 
    </ExpandableComponent>
  )
}

export default ComparativeData; 