import { HistoricSalesItem, HistoricSalesTrendViewOption } from "../../typings/groupwideDashboard";
import moment, { Moment } from "moment";

import { generateTimeSeriesSalesDataPointLabel } from "../../components/HighCharts/HcLineChartTimeSeries";
import historicSalesToDataPoints from "./historicSalesToDataPoints";
import { thisTimeLastYear } from "../date";

const historicSalesToSeriesData = (
  historicSalesItems: HistoricSalesItem[],
  historicSalesTrendView: HistoricSalesTrendViewOption
) => {
  if (!historicSalesItems.length) {
    return {};
  }
  const thisYearEndDate = historicSalesItems[historicSalesItems.length - 1].date;
  const lastYearEndDate = thisTimeLastYear(thisYearEndDate, historicSalesTrendView !== "monthly");

  const thisYearStartDate = getStartDate(thisYearEndDate, historicSalesTrendView);
  const lastYearStartDate = getStartDate(lastYearEndDate, historicSalesTrendView);

  const thisYearDataPoints = historicSalesToDataPoints(
    historicSalesItems,
    thisYearStartDate,
    thisYearEndDate,
    historicSalesTrendView
  );

  const lastYearDataPoints = historicSalesToDataPoints(
    historicSalesItems,
    lastYearStartDate,
    lastYearEndDate,
    historicSalesTrendView
  ).map((dataPoint) => ({
    ...dataPoint,
    pointLabel: generateTimeSeriesSalesDataPointLabel(dataPoint.pointLabel, dataPoint.value, true),
  }));

  // Custom logic to include previousPeriodSales to pull delta within graphing module
  const augmentedThisYearDataPoints = thisYearDataPoints.map((dataPoint, index) => ({
    ...dataPoint,
    pointLabel: generateTimeSeriesSalesDataPointLabel(
      dataPoint.pointLabel,
      dataPoint.value,
      true,
      lastYearDataPoints[index]?.value ?? 0
    ),
  }));

  return {
    [`Sales (${moment().year()})`]: augmentedThisYearDataPoints,
    [`Sales (${moment().year() - 1})`]: lastYearDataPoints,
  };
};

const getStartDate = (date: Moment, historicSalesTrendView: HistoricSalesTrendViewOption) =>
  historicSalesTrendView === "daily"
    ? date.clone().subtract(42, "days")
    : historicSalesTrendView === "weekly"
    ? date.clone().subtract(52, "weeks").endOf("isoWeek").add(1, "day") // Start of period to be the first full clean week
    : historicSalesTrendView === "monthly"
    ? date.clone().subtract(12, "months").startOf("month")
    : moment();

export default historicSalesToSeriesData;
