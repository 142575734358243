import React from "react"
import { DailyRow } from "../../typings/common"; 
import { formatCurrency } from "../../utils/currency"; 
import styled from "styled-components"; 
import { toHumanDateFormatWithDow } from "../../utils/date";
import Checkbox from "../InputFields/Checkbox"; 
import { Text } from "../StandardComponents"; 

type ComparativeDataPointProps = {
  comparativeDataPoint?: DailyRow | null, 
  isIrrelevant: boolean, 
  changeHandler: (arg0: any) => void
}

const Wrapper = styled.div `
  margin-left: 4px; 
  text-align: left; 
`

const TextMoney = styled(Text) `
  float: right; 
  margin-right: 0px; 
`

const ComparativeDataPoint: React.FC<ComparativeDataPointProps> = ({
  comparativeDataPoint, 
  isIrrelevant, 
  changeHandler
}) => {

  return (
    <Wrapper>
      <Checkbox 
        active={ 
          isIrrelevant ? 
          false : 
          ( comparativeDataPoint?.includedInSalesHistory?.toggle !== undefined ? 
            comparativeDataPoint?.includedInSalesHistory?.toggle : 
            false ) 
          }
        disabled={isIrrelevant}
        changeHandler={changeHandler}>
        <Text>{ comparativeDataPoint?.business_date !== undefined ? 
          toHumanDateFormatWithDow(comparativeDataPoint?.business_date) : 
          null }</Text>
        <TextMoney>{ formatCurrency(comparativeDataPoint?.sumChannelSales) }</TextMoney>
      </Checkbox>
    </Wrapper>
  )
}

export default ComparativeDataPoint; 