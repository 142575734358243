import { membersPath } from "./paths";
import generateAppSecret from "./generateAppSecret";

const getEmployees = (
  fields: (keyof FacebookOrgMember)[] = [
    "name",
    "id",
    "department",
    "organization",
    "division",
    "email",
  ]
): Promise<FacebookOrgMember[]> => {
  const appSecret = generateAppSecret();
  const requestUrl = `${membersPath}?${new URLSearchParams({
    access_token: process.env.REACT_APP_FACEBOOK_WORKPLACE_ACCESS_TOKEN ?? "",
    appsecret_proof: appSecret.proof,
    appsecret_time: appSecret.time.toString(),
    limit: (1000).toString(),
    fields: fields.join(","),
  })}`;

  console.log(appSecret); // DEBUG

  return fetch(`${process.env.REACT_APP_CORS_BYPASS_SERVER_URL}${requestUrl}`)
    .then((res) => res.json())
    .then((res: DataArrayResponse) => res.data as FacebookOrgMember[]);
};

export default getEmployees;
