import React from 'react'; 
import Highcharts, { PointLabelObject } from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official'; 
import VisPalette from '../../utils/visPalette';
import { formatCurrency } from '../../utils/currency';

type BarChartProps = {
  seriesName: string
  barChartData: BarChartDataPoint[]
  valueIsCurrency: boolean
}

type BarChartDataPoint = {
  name: string, 
  value: number
}

const barChartDataOptions = ({ seriesName, barChartData, valueIsCurrency }: BarChartProps) => {
  
  function tooltipFormatterCurrency(this: PointLabelObject) {
    return `<b>${this.x}</b>`
      + `<br/>$${formatCurrency(this.y)} `
  }

  function tooltipFormatterQuantity(this: PointLabelObject) {
    return `<b>${this.x}</b>`
      + `<br/>${this.y} `
  }

  return {
    chart: {
      type: 'bar', 
      backgroundColor: "#F6F7FB", 
      borderRadius: '4'
    },
    colorByPoint: true, 
    colors: VisPalette, 
    title: {
      text: null
    },
    xAxis: {
      categories: barChartData.map((barChartDataPoint) => barChartDataPoint.name), 
    },
    yAxis: {
      min: 0, 
      title: null, 
      gridLineWidth: 0, 
      labels: {
        enabled: false
      }
    },
    tooltip: {
      // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      formatter: valueIsCurrency ? tooltipFormatterCurrency : tooltipFormatterQuantity, 
      shared: true
    },
    legend: {
      enabled: false
    }, 
    plotOptions: {
      bar: {
        stacking: 'normal', 
        borderRadius: "4" 
      }
    },
    series: [{
      name: seriesName,
      data: barChartData.map((barChartDataPoint) => barChartDataPoint.value)
    }], 
    credits: {
      enabled: false
    }
  };
}

const HcBarChart: React.FC<BarChartProps> = (barChartProps) => {
  return <HighchartsReact highcharts={Highcharts} options={barChartDataOptions(barChartProps)} />; 
}

export default HcBarChart; 