import { Tooltip } from '@material-ui/core';
import React from 'react'; 
import styled from 'styled-components'; 
import { ValueTransformer } from '../../typings/common';
import { formatNumber } from '../../utils/globals';
import NumericField from '../NumericField';
import { FieldInputNumeric, FlexRow, HintIcon, IconWrapper, Left, Right } from '../StandardComponents';

type LineTextFieldProps = {
  fieldId: string, 
  fieldName?: string, 
  fieldHint?: string, 
  fieldValue?: number, 
  fieldValuePrefix?: string, 
  fieldValueSuffix?: string, 
  disabled?: boolean; 
  editable?: boolean, 
  changeHandler?: (id: string, input: number) => void, 
  valueTransformer?: ValueTransformer
}

const Wrapper = styled(FlexRow)`
  margin: 12px auto; 
  align-items: center; 
  justify-content: center; 
`

const Label = styled.span`
  align-self: center; 
  flex: 1; 
  font-size: 14px; 
  color: #646C77; 
  margin: 0px; 
`

const Prefix = styled.span`
  align-self: center; 
  width: 24px; 
  display: flex; 
  font-size: 14px; 
  color: #646C77; 
  margin: 0px; 
  text-align: right; 
`

const NonEditableContent = styled.span`
  width: 100%; 
  font-size: 14px; 
  color: #646C77; 
  margin: 0px; 
  text-align: right; 
`

const Suffix = styled.span` 
  align-self: center; 
  width: 24px; 
  font-size: 14px; 
  color: #646C77; 
  margin: 0px; 
  text-align: left; 
`

const FieldInputWrapper = styled.div<{
}>`
  display: flex; 
  flex: 1; 
  flex-direction: row; 
`

// const convertForDisplay = (input: number | string | undefined) =>
//   input !== undefined ? 
//     ( typeof input === "number" ? 
//         formatNumber(input) : 
//         input
//     ) : 
//     ""; 

const LineTextField: React.FC<LineTextFieldProps> = ({
  fieldId, 
  fieldName, 
  fieldHint, 
  fieldValue, 
  fieldValuePrefix, 
  fieldValueSuffix, 
  disabled, 
  editable=true,
  changeHandler, 
  valueTransformer
}) => {
  // const [ displayFieldValue, setDisplayFieldValue ] = React.useState<string>(convertForDisplay(fieldValue))

  return (
    <Wrapper>
      <Label>
        {fieldName}{ fieldHint && "  "}
        {
          fieldHint && 
            (
              <Tooltip
                title={
                  <div>
                    {fieldHint}
                  </div>
                }>
                <IconWrapper>
                  <HintIcon />
                </IconWrapper>
              </Tooltip>
            )
        }
      </Label>
      <FieldInputWrapper>
        
        <Prefix>
          {fieldValuePrefix}
        </Prefix>
        {
          editable && (
            <NumericField 
              value={fieldValue}
              disabled={disabled}
              onChange={(event) => {
                if (changeHandler) {
                  changeHandler(
                    fieldId, 
                    parseFloat((event.target as HTMLInputElement).value)
                  )
                }
              }}
              />
            // <FieldInputNumeric 
            //   value={
            //     valueTransformer ? 
            //       (
            //         fieldValue !== undefined && fieldValue !== "" ? 
            //           valueTransformer.backendToFrontend(fieldValue as number) : 
            //           ""
            //       ) : 
            //       fieldValue
            //   }
            //   disabled={! editable}
            //   onChange={(event) => {
            //     if (changeHandler) {
            //       changeHandler(
            //         fieldId, 
            //         valueTransformer ? 
            //           valueTransformer?.frontendToBackend(
            //             parseFloat((event.target as HTMLInputElement).value)
            //           ) : 
            //           parseFloat((event.target as HTMLInputElement).value)
            //       )
            //     }
            //   }}
            //   />
          )
        }
        {
          ! editable && (
            <NonEditableContent>
              {fieldValue}
            </NonEditableContent>
          )
        }
        <Suffix>
          {fieldValueSuffix}
        </Suffix>
            
      </FieldInputWrapper>
    </Wrapper>
  )
}

export default LineTextField; 