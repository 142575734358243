type LightspeedApiRefreshResponse = {
  isSuccessful: boolean;
};

const lightspeedApiRefresh = (businessDate: string): Promise<boolean> =>
  fetch(
    `${process.env.REACT_APP_CORS_BYPASS_SERVER_URL}http://128.199.173.130:8080/lightspeed-query?date=${businessDate}`,
    {
      method: "POST",
    }
  )
    .then((res) => {
      console.log(res);
      return res.json();
    })
    .then((res: LightspeedApiRefreshResponse) => {
      console.log(res);
      return res.isSuccessful;
    });

export default lightspeedApiRefresh;
