import React from "react"
import styled from "styled-components"
import { Wrapper } from "./Wrapper"
import ComparativeData from "./ComparativeData"
import { SalesSummaryRowType, DeltaProps, DailyRow } from "../../typings/common"
import Delta from "../Delta"

type SalesSummaryRowProps = {
  itemNameText?: string
  itemValueText?: string | number
  type?: SalesSummaryRowType
  delta?: DeltaProps, 
  comparativeData?: DailyRow[], 
  comparativeDataChangeHandler?: (arg0: string) => void, 
  noGap?: boolean
}

const ItemName = styled.div`
  text-align: left; 
  flex: 1; 
`

const ItemValue = styled.div`
  text-align: right; 
  min-width: 72px; 
`

const SalesSummaryRow: React.FC<SalesSummaryRowProps> = ({
  itemNameText = "-", 
  itemValueText = "-", 
  type, 
  delta, 
  comparativeData, 
  comparativeDataChangeHandler, 
  noGap = false
}) => {
  const doNothing = () => {
    
  }

  return (
    <>
      <Wrapper type={type} noGap={noGap}>
        <ItemName>{itemNameText}</ItemName>
        { delta !== undefined ? <Delta {...delta} /> : null }
        {/* <ItemValue>{itemValueText}</ItemValue> */}
        <ItemValue>{itemValueText}</ItemValue>
      </Wrapper>
      { comparativeData ? 
        <ComparativeData 
          comparativeData={comparativeData} 
          comparativeDataChangeHandler={(comparativeDataChangeHandler !== undefined) ? comparativeDataChangeHandler : doNothing}/> : 
        null }
    </>
  )
}

export default SalesSummaryRow; 