import React from "react"; 
import styled from "styled-components"; 
import { formatCurrency } from "../utils/currency";

type ProgressBarProps = {
  progress: number | undefined, 
  goal: number | undefined, 
}

const Wrapper = styled.div`
  margin: 18px 4px 64px 4px;
`

const Container = styled.div`
  height: 42px; 
  background-color: #F6F7FB; 
  border-radius: 3px; 
  padding: 0px; 
  line-height: 32px; 
  display: flex; 
  flex-direction: row; 
`

const Progress = styled.div`
  height: 42px; 
  text-align: right; 
  font-weight: 500; 
  line-height: 32px; 
  padding-right: 16px;
  border-radius: 3px; 
  border: 0px; 
`

const ProgressText = styled.span`
  float: left; 
  margin: 4px 2px; 
  font-weight: 500; 
  font-size: 13px; 
  color: #646C77; 
`

const GoalText = styled.span`
  float: right; 
  margin: 4px 2px;  
  font-weight: 500; 
  font-size: 13px; 
  color: #646C77; 
`

const progressColor = "#273561"; 

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, goal }) => {
  if (progress === undefined) {
    progress = 0; 
  }
  if (goal === undefined) {
    goal = 0; 
  }

  return (
    <Wrapper>
      <Container>
        <Progress style={{
          width: `${(progress <= goal ? progress / goal : 1)*100}%`, 
          backgroundColor: `${progressColor}`
        }}>
        </Progress>

      </Container>
      <ProgressText>{`${formatCurrency(progress)} ${ goal !== 0 ? `(${(progress / goal * 100).toFixed(0)}%)` : ""}`}</ProgressText>
      <GoalText>{goal !== 0 ? formatCurrency(goal) : ""}</GoalText>
    </Wrapper> 
  )
}

export default ProgressBar; 