import React from "react"; 
import { 
  Table, 
  TableContainer, 
  TableRow, 
  TableHead, 
  TableCell, 
  TableBody,
  Tooltip
} from '@material-ui/core';
import { 
  Text, 
  CheckCircleIcon, 
  ErrorIcon, 
  IconWrapper
} from "./StandardComponents"; 
import { VenueAssuranceData } from "../typings/common";
import styled from 'styled-components'; 
import { airtableRequiredFields } from "../utils/globals";

const VenueDataAssuranceTable: React.FC<VenueAssuranceData> = ({ 
  lastYearData, 
  yearToDateData, 
  metrics
}) => {

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Metric</TableCell>
            <TableCell>LY</TableCell>
            <TableCell>YTD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { lastYearData &&
            yearToDateData && 
            metrics
              .filter((metric) => (
                ! (airtableRequiredFields
                  .RawData
                  .dataAssuranceExcludedFromTrack
                  .includes(metric))))
              .map((metric) => (

                <TableRow key={metric}>
                  <TableCell>
                    <Text>
                      { metric }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Tooltip 
                      title={
                        <div>
                          Daily sum: {lastYearData.dataAssuranceEntries[metric]?.dailySum.toFixed(0)}<br/>
                          System sum: {lastYearData.dataAssuranceEntries[metric]?.systemSum.toFixed(0)}
                        </div>
                      }>
                      <IconWrapper>
                        {
                          lastYearData.dataAssuranceEntries[metric]?.sumsTally ? 
                          <CheckCircleIcon /> : 
                          <ErrorIcon />
                        }
                      </IconWrapper>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip 
                      title={
                        <div>
                          Daily sum: {yearToDateData.dataAssuranceEntries[metric]?.dailySum.toFixed(0)}<br/>
                          System sum: {yearToDateData.dataAssuranceEntries[metric]?.systemSum.toFixed(0)}
                        </div>
                      }>
                      <IconWrapper>
                        {
                          yearToDateData.dataAssuranceEntries[metric]?.sumsTally ? 
                          <CheckCircleIcon /> : 
                          <ErrorIcon />
                        }
                      </IconWrapper>
                    </Tooltip>
                  </TableCell>
                </TableRow>

              )
          ) }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VenueDataAssuranceTable; 