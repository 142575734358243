import React, { FormEvent } from "react"; 
import TextField from "./InputFields/TextField"; 
import MainHeader from "./MainHeader";
import AuthService from "../services/AuthService";
import { useHistory } from "react-router-dom"; 
import LoadingOverlay from "react-loading-overlay"; 
import { 
  FailedAlert, 
  MobileLayout, 
  Button,
  Loading
} from "./StandardComponents";
import { styled } from "@material-ui/styles";

type LoginProps = {
  defaultUsername?: string, 
  defaultPassword?: string, 
  postLoginCallback?: () => void
}

const Login: React.FC<LoginProps> = ({
  defaultUsername = "", 
  defaultPassword = "", 
  postLoginCallback
}) => {

  const [ username, setUsername ] = React.useState<string>(""); 
  const [ password, setPassword ] = React.useState<string>(""); 
  const [ showFailedAuthAlert, setshowFailedAuthAlert ] = React.useState<boolean>(false); 
  const [ loading, setLoading ] = React.useState<boolean>(false); 
  const history = useHistory(); 

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    setLoading(true); 
    event.preventDefault(); 

    async function authenticateLogin() {
      const authResult = await AuthService.authenticate(username, password); 
      console.log("authResult", authResult); 
      if (authResult) {
        if (postLoginCallback !== undefined) {
          postLoginCallback();
        } 

        history.push(`/`); 
      }
      else {
        setshowFailedAuthAlert(true); 
      }
      
      setLoading(false); 
    }

    authenticateLogin(); 
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(100, 108, 119, 0.2)', 
          zIndex: 2000, 
          position: 'fixed', 
          height: '100vh'
        })
      }}>
      <MobileLayout>
        <MainHeader />
        { 
          showFailedAuthAlert && (
            <FailedAlert>
              Invalid username and password
            </FailedAlert>
          ) 
        }
        <form 
          onSubmit={handleLogin} 
          style={{
            flex: 1
          }}>
          <TextField 
            label="Username" inputType="text"
            changeHandler={(input) => setUsername(input)}/>
          <TextField label="Password" inputType="password"
            changeHandler={(input) => setPassword(input)}/>
          <Button type="submit" disabled={username === "" || password === ""}>Login</Button>
        </form>
      </MobileLayout>
    </LoadingOverlay>
  )
}

export default Login; 