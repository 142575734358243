import { messagePath } from "./paths";

const CORS_BYPASS_SERVER_URL = process.env.REACT_APP_CORS_BYPASS_SERVER_URL;

const sendMessage = (recipientId: string, message: string): Promise<MessageResponse> => {
  const requestUrl = `${messagePath}?${new URLSearchParams({
    access_token: process.env.REACT_APP_FACEBOOK_WORKPLACE_ACCESS_TOKEN ?? "",
  })}`;

  const body = {
    recipient: {
      id: recipientId.toString(),
    },
    message: {
      text: message,
    },
  };

  return fetch(`${CORS_BYPASS_SERVER_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

export default sendMessage;
