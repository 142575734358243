import React from "react";
import styled from "styled-components";
import {
  YearMonth,
  Page,
  PermissionsDashboard,
  Venue,
  DashboardType,
  Dashboard,
} from "../typings/common";
import {
  ArrowFont,
  DesktopMenuItem,
  PopupMenu,
  Separator,
  ThinTopHeader,
} from "./StandardComponents";
import icon_hamburger from "../assets/icon_hamburger.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  toHumanDateFormat,
  toHumanMonthYearString,
  toStandardYearMonthFormat,
} from "../utils/date";
import { getUserPermissionsDashboard } from "../services/AirtablePlus";
import { loggedInUser, menuTitle, path } from "../utils/globals";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ItemText = styled.p`
  display: inline-block;
  font-size: 11px;
  color: #273561;
  font-weight: 600;
  margin: 0px 0px 0px 36px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ItemWrapper = styled.div``;

const HamburgerIcon = styled.img.attrs({ src: icon_hamburger })`
  display: inline-block;
  max-height: 10px;
  object-fit: contain;
  margin: 0px 4px;
`;

const PopupMenuWrapper = styled.div`
  display: block;
  position: relative;
`;

type DateType = "day" | "month";

type IncentivesDashboardHeaderProps = {
  venueOptions?: Venue[];
  venueChangeHandler: (arg0: any) => void;
  venueSelected?: string;
  venuePlaceholder?: string;
  dateType: DateType;
  dateChangeHandler?: (arg0: Date) => void;
  dateSelected?: Date;
  monthOptions?: YearMonth[];
  monthChangeHandler?: (arg0: string) => void; // arg0: yearMonthString
  monthSelected?: YearMonth; // yearMonthString
  menuOuterContainerId: string;
  menuSelectedItem: Page;
  outerContainerId?: string;
  context: DashboardType;
};

const IncentivesDashboardHeader: React.FC<IncentivesDashboardHeaderProps> = (props) => {
  const [isNavMenuOpen, setIsNavMenuOpen] = React.useState<boolean>(false);
  const [isVenueMenuOpen, setIsVenueMenuOpen] = React.useState<boolean>(false);
  const [isMonthMenuOpen, setIsMonthMenuOpen] = React.useState<boolean>(false);
  const [permissionsDashboard, setPermissionsDashboard] = React.useState<PermissionsDashboard[]>(
    []
  );

  React.useEffect(() => {
    getUserPermissionsDashboard(loggedInUser()).then((permissionsDashboardResponse) => {
      setPermissionsDashboard(permissionsDashboardResponse);
    });

    const clickOutsideHandler = (event: Event) => {
      console.log("event.target", event.target);
      if (event.target !== document.getElementById("popupMenuNav")) {
        setIsNavMenuOpen(false);
      }
      if (event.target !== document.getElementById("popupMenuVenue")) {
        setIsVenueMenuOpen(false);
      }
    };

    const wrapper = props.outerContainerId ? document.getElementById(props.outerContainerId) : null;

    if (props.outerContainerId && wrapper) {
      wrapper.addEventListener("click", clickOutsideHandler);
    }

    return () => {
      if (props.outerContainerId && wrapper) {
        wrapper.removeEventListener("click", clickOutsideHandler);
      }
    };
  }, []);

  return (
    <ThinTopHeader>
      <PopupMenuWrapper>
        <ItemText
          onClick={() => {
            setIsVenueMenuOpen(true);
            setIsNavMenuOpen(false);
            setIsMonthMenuOpen(false);
          }}
          id="popupMenuVenue"
        >
          {props.venueSelected !== ""
            ? // Custom logic to not show combined entity name for manual data input
              props.context !== Dashboard.salesInput
              ? props.venueOptions?.find((venue) => venue.name === props.venueSelected)
                  ?.combined_entity_name ?? props.venueSelected
              : props.venueSelected
            : // Hackish code I am extremely ashamed of but works so wtv...
              props.venuePlaceholder}
          <ArrowFont>▼</ArrowFont>
        </ItemText>
        {isVenueMenuOpen && (
          <PopupMenu>
            {props.venueOptions &&
              props.venueOptions.map((venue) => (
                <DesktopMenuItem
                  onClick={() => {
                    setIsVenueMenuOpen(false);
                    props.venueChangeHandler(venue.name);
                  }}
                  selected={props.venueSelected === venue.name}
                  key={venue.code}
                >
                  {
                    // Custom logic to not show combined entity name for manual data input
                    props.context !== Dashboard.salesInput
                      ? venue.combined_entity_name
                        ? venue.combined_entity_name
                        : venue.name
                      : venue.name
                  }
                </DesktopMenuItem>
              ))}
          </PopupMenu>
        )}
      </PopupMenuWrapper>

      {props.dateType === "day" && (
        <ItemWrapper>
          <DatePicker
            selected={props.dateSelected}
            customInput={
              <ItemText>
                {toHumanDateFormat(moment(props.dateSelected))}
                <ArrowFont>▼</ArrowFont>
              </ItemText>
            }
            onChange={props.dateChangeHandler ?? (() => {})}
            onCalendarOpen={() => {
              setIsNavMenuOpen(false);
              setIsVenueMenuOpen(false);
            }}
            popperPlacement="bottom-end"
          />
        </ItemWrapper>
      )}

      {props.dateType === "month" && (
        <PopupMenuWrapper>
          <ItemText
            onClick={() => {
              setIsMonthMenuOpen(true);
              setIsVenueMenuOpen(false);
              setIsNavMenuOpen(false);
            }}
            id="popupMenuMonth"
          >
            {props.monthSelected
              ? toHumanMonthYearString(
                  moment()
                    .year(props.monthSelected.year)
                    .month(props.monthSelected.month - 1)
                )
              : "Select date"}
            <ArrowFont>▼</ArrowFont>
          </ItemText>
          {isMonthMenuOpen && (
            <PopupMenu>
              {props.monthOptions &&
                props.monthOptions.map((yearMonth) => {
                  const value = toStandardYearMonthFormat(yearMonth.year, yearMonth.month);
                  const label = toHumanMonthYearString(
                    moment()
                      .year(yearMonth.year)
                      .month(yearMonth.month - 1)
                  );
                  return (
                    <DesktopMenuItem
                      onClick={() => {
                        setIsMonthMenuOpen(false);
                        if (props.monthChangeHandler) {
                          props.monthChangeHandler(value);
                        }
                      }}
                      selected={props.venueSelected === value}
                      key={value}
                    >
                      {label}
                    </DesktopMenuItem>
                  );
                })}
            </PopupMenu>
          )}
        </PopupMenuWrapper>
      )}

      <PopupMenuWrapper>
        <ItemText
          onClick={() => {
            setIsNavMenuOpen(true);
            setIsVenueMenuOpen(false);
            setIsMonthMenuOpen(false);
          }}
          id="popupMenuNav"
        >
          <HamburgerIcon />
          Menu
        </ItemText>
        {isNavMenuOpen && (
          <PopupMenu>
            {permissionsDashboard.includes("Sales") && (
              <DesktopMenuItem
                href={path.salesDashboard}
                selected={props.menuSelectedItem === "Sales"}
              >
                {menuTitle.salesDashboard}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("Marketing") && (
              <DesktopMenuItem
                href={path.marketingDashboard}
                selected={props.menuSelectedItem === "Marketing"}
              >
                {menuTitle.marketingDashboard}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("Incentives") && (
              <DesktopMenuItem
                href={path.incentivesDashboard}
                selected={props.menuSelectedItem === "Incentives"}
              >
                {menuTitle.incentivesDashboard}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("IncentivesAdmin") && (
              <DesktopMenuItem
                href={path.incentivesDashboardAdmin}
                selected={props.menuSelectedItem === "IncentivesAdmin"}
              >
                {menuTitle.incentivesDashboardAdmin}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("DataAssurance") && (
              <DesktopMenuItem
                href={path.assuranceDashboard}
                selected={props.menuSelectedItem === "DataAssurance"}
              >
                {menuTitle.assuranceDashboard}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("Groupwide") && (
              <DesktopMenuItem
                href={path.groupwideDashboard}
                selected={props.menuSelectedItem === "Groupwide"}
              >
                {menuTitle.groupwideDashboard}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("SalesDataInput") && (
              <DesktopMenuItem
                href={path.salesInput}
                selected={props.menuSelectedItem === "SalesDataInput"}
              >
                {menuTitle.salesDataInput}
              </DesktopMenuItem>
            )}
            {(permissionsDashboard.includes("ManualAdjustmentRequest") ||
              permissionsDashboard.includes("ManualAdjustments") ||
              permissionsDashboard.includes("ManualApiRefresh")) && <Separator />}
            {permissionsDashboard.includes("ManualAdjustmentRequest") && (
              <DesktopMenuItem
                href={path.manualAdjustmentRequest}
                selected={props.menuSelectedItem === "ManualAdjustmentRequest"}
              >
                {menuTitle.manualAdjustmentRequest}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("ManualAdjustments") && (
              <DesktopMenuItem
                href={path.manualAdjustments}
                selected={props.menuSelectedItem === "ManualAdjustments"}
              >
                {menuTitle.manualAdjustments}
              </DesktopMenuItem>
            )}
            {permissionsDashboard.includes("ManualApiRefresh") && (
              <DesktopMenuItem
                href={path.posDataRefresh}
                selected={props.menuSelectedItem === "ManualApiRefresh"}
              >
                {menuTitle.posDataRefresh}
              </DesktopMenuItem>
            )}
            <Separator />
            <DesktopMenuItem
              href={path.setPassword}
              selected={props.menuSelectedItem === "SetPassword"}
            >
              {menuTitle.setPassword}
            </DesktopMenuItem>
            <DesktopMenuItem
              href={path.changelog}
              selected={props.menuSelectedItem === "Changelog"}
            >
              {menuTitle.changelog}
            </DesktopMenuItem>
            <DesktopMenuItem href={path.logout} selected={props.menuSelectedItem === "Login"}>
              {menuTitle.logout}
            </DesktopMenuItem>
          </PopupMenu>
        )}
      </PopupMenuWrapper>
    </ThinTopHeader>
  );
};

export default IncentivesDashboardHeader;
