import React from "react"; 
import styled from "styled-components";
import logo from "../assets/tlbg_logo.png"; 
import { TlbgConcept } from "../typings/common";
import { TlbgConceptCode } from "../utils/globals";
import ConceptLogo from "./ConceptLogo";
import RefreshButton from './RefreshButton'; 

const LogoWrapper = styled.div`
  display: flex; 
  width: 132px; 
`

const Logo = styled.img.attrs({
  src: logo
}) `
  display: flex; 
  flex: 1; 
  width: 76px; 
  height: 40px; 
  object-fit: contain; 
` 

const Wrapper = styled.div<{ isFixed: boolean }>`
  ${({isFixed}) => (
    isFixed ? 
      `
        position: fixed; 
        top: 0; 
        left: 0; 
        right: 0; 
        height: 58px; 
        padding: 8px 0px; 
      ` : 
      `
      `
    )
  }
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  background-color: #ffffff; 
  z-index: 1000;
`

const SecondaryLogoWrapper = styled.div`
  display: flex; 
  height: 54px; 
  margin: auto 0px; 
  border-left: 0.5px solid #646C77; 
  align-items: center; 
`

const MainHeader: React.FC<{
  refresh?: () => void, 
  secondaryLogo?: TlbgConcept, 
  isFixed?: boolean
}> = ({
  refresh, 
  secondaryLogo, 
  isFixed = true
}) => { 
  
  return (
    <Wrapper isFixed={isFixed}>
      {
        refresh && 
        <RefreshButton onClick={() => refresh()} />
      }
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {
        secondaryLogo && 
        <SecondaryLogoWrapper>
          { ConceptLogo[TlbgConceptCode[secondaryLogo]]("header") }
        </SecondaryLogoWrapper>
      }
    </Wrapper>
  ); 
}
  
export default MainHeader; 
