import { Moment } from "moment";
import { netSalesWithServiceChargeFromRecord } from "./dailyRow";
import { safeSum } from "./globals";
import { DailyRow } from "../typings/common";
import { toStandardDateFormat, fromStandardDateTimeFormat } from "./date";

const getMtdSalesEndResult = (res: Airtable.Record<DailyRow>[], date: Moment) => {
  let currDayFigure = 0;
  let lastUpdated: Moment | undefined;
  const dailyNetSalesWithServiceCharge = res.map((dailyRowRecord) => {
    const dayNetSalesWithServiceCharge = netSalesWithServiceChargeFromRecord(dailyRowRecord);

    if (dailyRowRecord.fields.business_date === toStandardDateFormat(date)) {
      currDayFigure = dayNetSalesWithServiceCharge;
      lastUpdated = fromStandardDateTimeFormat(
        dailyRowRecord.fields.last_modified ?? "2000-01-01T00:00:00.000Z"
      );
    }

    return dayNetSalesWithServiceCharge;
  });

  return {
    dailySales: currDayFigure,
    monthlySales: safeSum(dailyNetSalesWithServiceCharge),
    lastUpdated: lastUpdated,
  };
};

export default getMtdSalesEndResult;
