import React from 'react'; 
import Highcharts, { TooltipFormatterContextObject } from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official'; 
import VisPalette from '../../utils/visPalette';
import { formatCurrency } from '../../utils/currency';
import { Moment } from 'moment';
import { DailyRow } from '../../typings/common';
import { toHumanDateFormatWithDow, toStandardDateFormat } from '../../utils/date';
import { safeSum } from '../../utils/globals';
import { ChartWrapper } from '../StandardComponents';

// Follow https://www.highcharts.com/demo/area-stacked-percent

type DistTimeChartProps = {
  seriesName: string,
  distTimeChartData: DailyRow[],
  valueIsCurrency: boolean, 
  daysToTrack: number, 
  lastDate: Moment, 
  metricsToPlot: (keyof DailyRow)[], 
  metricsName?: {
    [key: string]: string
  }
}

const convertDistTimeSeriesDailyRowDataToArray = (
  dailyRows: DailyRow[], 
  lastDate: Moment, 
  daysToTrack: number, 
  metricsToPlot: (keyof DailyRow)[], 
  metricsName?: {
    [key: string]: string
  }
) => {

  let output: {
    [key: string]: (number|null)[]
  } = {}; 

  // Get only entries that have at least one non-zero value
  metricsToPlot = metricsToPlot
    .filter((metric: keyof DailyRow) => {
      const metricsArray = dailyRows
        .map((dailyRow) => dailyRow[metric]); 
      const metricsSum = safeSum(metricsArray as number[]); 

      return metricsSum !== 0; 
    }); 

  metricsToPlot.forEach((metric: keyof DailyRow, index) => {
    output[metric] = []; 
  }); 

  for (let i = lastDate.clone().subtract(daysToTrack-1, "days"); 
    i.isSameOrBefore(lastDate); 
    i.add(1, "day")) {

    const dailyRow = dailyRows.find((dailyRow) => dailyRow.business_date === toStandardDateFormat(i)); 
    let dailyTotal = 0; 
    metricsToPlot.forEach((metric) => {
      if (dailyRow !== undefined && dailyRow[metric] !== undefined) {
        const metricFigure = 
          dailyRow[metric] as number >= 0 ? 
          dailyRow[metric] as number :
          0; 
          // Removing the negative values to remove kinks from distribution
          // i.e. Sum not 100%
        output[metric].push(metricFigure); 
        dailyTotal += metricFigure; 
      }
      else {
        output[metric].push(0); 
      }
    }); 
    // FOLLOWING CHUNK OF CODE IS THE SOURCE OF BUG (Cannot read property '0' of undefined)
    // if day has no sales, make gap, i.e. null
    if (dailyTotal === 0 && ! i.isSame(lastDate)) {
      metricsToPlot.forEach((metric) => {
        output[metric][output[metric].length-1] = null; 
      })
    }
  }

  const outputArray: {
    name: string, 
    data: (number|null)[], 
    color: string
  }[] = []; 
  const outputKeyCount = Object.keys(output).length; 
  Object.keys((output)).forEach((key, index) => {
    outputArray.push({
      name: metricsName === undefined ? key : metricsName[key], 
      data: output[key], 
      color: VisPalette[outputKeyCount - index - 1]
    })
  }); 

  // // If the data for every metric is empty, return a data array with 0
  // if (outputArray.every((element) => element.data.length === 0)) {
  //   return []
  // }

  return outputArray; 
}

const getDistTimeChartDataOptions = ({ 
  seriesName, 
  distTimeChartData,
  valueIsCurrency,  
  daysToTrack, 
  lastDate, 
  metricsToPlot, 
  metricsName
 }: DistTimeChartProps) => {
  
  function tooltipFormatter(this: TooltipFormatterContextObject) {
    const humanDateWithDow = toHumanDateFormatWithDow(
      lastDate.clone().subtract(daysToTrack-1 - this.x, "days")
      ); 

    const percentage = this.percentage?.toFixed(0); 
    const amountText = 
      valueIsCurrency ? 
      `${formatCurrency(this.y)}` : 
      `${this.y}`; 
    return `<b>${humanDateWithDow}</b><br/>${this.series.name}: ${percentage}% - ${amountText}`
  }

  const seriesData = convertDistTimeSeriesDailyRowDataToArray(
    distTimeChartData, 
    lastDate, 
    daysToTrack, 
    metricsToPlot, 
    metricsName
  ); 

  // console.log("seriesData", seriesData); 

  const output = {
    chart: {
      type: 'area', 
      backgroundColor: "#F6F7FB", 
      borderRadius: '8', 
      height: '320px'
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }, 
      labels: {
        enabled: false
      }, 
      gridlineWidth: 0, 
      min: 0
    },
    xAxis: {
      visible: false, 
    },
    lineWidth: 0, 
    lineColor: null, 
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom', 
      x: 0, 
      y: 0
    },
    tooltip: {
      formatter: tooltipFormatter
    }, 
    plotOptions: {
      area: {
        stacking: 'percent', 
        lineWidth: 0.5, 
      }, 
      series: {
        marker: {
          enabled: false
        }, 
        connectNulls: false, 
        gapSize: 1
      }
    },
    series: seriesData, 
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }, 
    credits: {
      enabled: false
    }
  }; 

  return output
}

const HcDistTimeChart: React.FC<DistTimeChartProps> = (distTimeChartProps) => {
  const distTimeChartDataOptions = getDistTimeChartDataOptions(distTimeChartProps); 
  return (
    <ChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={distTimeChartDataOptions} />
    </ChartWrapper>
  ); 
}

export default HcDistTimeChart; 