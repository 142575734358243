import moment, { isMoment, Moment } from "moment";
import { DailyRow, TimePeriod } from "../typings/common";

export const toHumanDateFormat = (date: Moment | string) => {
  if (typeof date === "string") {
    date = moment(date, "YYYY-MM-DD");
  }
  return date.format("D MMM YYYY");
};

export const toHumanDateFormatWoYear = (date: Moment | string) => {
  if (typeof date === "string") {
    date = moment(date, "YYYY-MM-DD");
  }
  return date.format("D MMM");
};

export const toHumanDateFormatWithDow = (date: Moment | string) => {
  if (isMoment(date) && !date.isValid()) {
    return "";
  }
  if (typeof date === "string") {
    date = moment(date, "YYYY-MM-DD");
  }
  return date.format("D MMM YYYY (ddd)");
};

export const toHumanDateTimeFormat = (date: Moment | string) => {
  if (typeof date === "string") {
    date = moment(date, "YYYY-MM-DD HH:mm");
  }
  return date.format("D MMM YYYY hh:mma");
};

export const fromHumanDateFormat = (dateString: string) => {
  return moment(dateString, "DD MMM YYYY");
};

export const toStandardDateFormat = (date: Moment | Date) => {
  return date instanceof Date ? moment(date).format("YYYY-MM-DD") : date.format("YYYY-MM-DD");
};

export const toStandardDateTimeFormat = (dateTime: Moment) => {
  return moment(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

export const fromStandardDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD");
};

export const toStandardYearMonthFormat = (year: number, month: number) => {
  return moment()
    .year(year)
    .month(month - 1)
    .format("YYYY-MM");
};

export const fromStandardDateTimeFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ");
};

export const getTmly = (date: Moment) => {
  const fromDate = date.clone().subtract(1, "years").date(1);
  const toDate = date.clone().subtract(1, "years").endOf("month");

  return {
    from: fromDate,
    to: toDate,
  };
};

export const getTtlmMtd = (date: Moment) => {
  const ttlm = date.clone().subtract(1, "month");
  const { from: fromDate, to: toDate } = getMtd(ttlm);

  return {
    from: fromDate,
    to: toDate,
  };
};

export const getL30d = (date: Moment) => {
  const fromDate = date.clone().subtract(30, "days");
  const toDate = date.clone();

  return {
    from: fromDate,
    to: toDate,
  };
};

export const getL6w = (date: Moment) => {
  const fromDate = date.clone().subtract(6 * 7, "days");
  const toDate = date.clone();

  return {
    from: fromDate,
    to: toDate,
  };
};

export const getMtd = (date: Moment) => {
  const fromDate = date.clone().date(1);

  return {
    from: fromDate,
    to: date,
  };
};

export const thisTimeLastYear = (date: Moment, useWeekIndex: boolean = true) => {
  if (useWeekIndex) {
    // const year = date.year(); // Year is not used because it will cause inconsistent behaviour at the start/end of the year
    const weekYear = date.weekYear();
    const weekIndex = date.week();
    const dow = date.day();
    const ttly = moment()
      .clone()
      .weekYear(weekYear - 1)
      .week(weekIndex)
      .day(dow);
    return ttly;
  } else {
    const ttly = date.clone().year(date.year() - 1);
    return ttly;
  }
};

export const getFirstDayOfMonth = (date: Moment) => {
  return date.clone().startOf("month");
};

export const getLastDayOfMonth = (date: Moment) => {
  return date.clone().endOf("month");
};

export const getSmallestMonthRange = (fromDate: Moment, toDate: Moment) => {
  return {
    from: getFirstDayOfMonth(fromDate),
    to: getLastDayOfMonth(toDate),
  };
};

// Quick hack to circumvent Airtable IS_BEFORE query way
// i.e. Dates on Airtable have an implicit 00:00hrs time
export const addOneDayToDateString = (dateString: string) => {
  return toStandardDateFormat(moment(dateString).add(1, "day"));
};

export const subtractOneDayToDateString = (dateString: string) => {
  return toStandardDateFormat(moment(dateString).subtract(1, "day"));
};

export const getTmlyString = (date: Moment) => {
  const ttly = date.clone().subtract(1, "year");
  return toHumanMonthYearString(ttly);
};

export const getYearFromStandardDate = (dateString: string) => {
  const date = fromStandardDateFormat(dateString);
  return date.year();
};

export const getEarliestDate = (dates: Moment[]) =>
  dates.reduce((prev, curr) => {
    if (prev.diff(curr, "second") < 0) {
      return prev;
    } else {
      return curr;
    }
  }, moment());

export const fromStandardYearMonth = (dateString: string) => moment(dateString, "YYYY-MM");

export const toHumanMonthYearString = (date: Moment) => date.format("MMM YYYY");

export const toHumanMonthString = (date: Moment) => date.format("MMM");

export type TimeUnit = "month" | "week" | "day" | "hour" | "minute" | "second";

// Returns array of moments from fromDate to toDate,
// incrementing by {interval} {intervalUnit},
// inclusive of fromDate,
// and toDate but only if toDate is a multiple of {interval} {intervalUnit} more than fromDate
export const fromToMoment = (
  fromDate: Moment,
  toDate: Moment,
  interval: number = 1,
  intervalUnit: TimeUnit = "day"
) => {
  let output: Moment[] = [];
  for (
    let iterDate = fromDate.clone();
    iterDate.isSameOrBefore(toDate);
    iterDate.add(interval, intervalUnit)
  ) {
    output.push(iterDate.clone());
  }
  return output;
};

export const makeDayHead = (dateTime: Moment) =>
  dateTime.clone().hours(0).minutes(0).seconds(0).milliseconds(0);

export const makeDayTail = (dateTime: Moment) =>
  dateTime.clone().hours(23).minutes(59).seconds(59).milliseconds(999);

export const dailyRowDateSortFunc = (dailyRowA: DailyRow, dailyRowB: DailyRow) =>
  fromStandardDateFormat(dailyRowA.business_date ?? "").diff(
    fromStandardDateFormat(dailyRowB.business_date ?? "")
  );

export const splitTimePeriodIntoIntervals = (
  timePeriod: TimePeriod,
  intervalLength: number = 7
) => {
  let output: TimePeriod[] = [];

  for (
    let iterDate = timePeriod.from.clone().subtract(1, "day");
    iterDate.isSameOrBefore(timePeriod.to, "day");
    iterDate.add(intervalLength, "day")
  ) {
    const naivePeriodEnd = iterDate.clone().add(intervalLength - 1, "day");
    if (iterDate.isSame(timePeriod.to)) {
      output.push({
        from: iterDate.clone(),
        to: iterDate.clone(),
      });
    } else if (naivePeriodEnd.isAfter(timePeriod.to)) {
      output.push({
        from: iterDate.clone(),
        to: timePeriod.to.clone(),
      });
    } else {
      output.push({
        from: iterDate.clone(),
        to: naivePeriodEnd.clone(),
      });
    }
  }

  return output;
};
