import React, { FormEvent } from "react";
import { FC, useEffect, useState } from "react";
import getEmployees from "../../services/FacebookWorkplace/getEmployees";
import LoadingOverlay from "react-loading-overlay"; 
import Select, { MultiValue } from "react-select";
import handleFormSubmission from "../../services/OpenArmsRecognition/handleFormSubmission";
import { customSelectStyle, Description, Form, Header, Input, InstagramLink, Label, OpenArmsImage, OpenArmsPageLayout, SubmitButton, TextArea, Title, Loading } from "./components";
import { useHistory } from "react-router-dom";
import { path } from "../../utils/globals";

type LabelValuePair = {
	label: string; 
	value: string; 
}

export type FormVal = {
  employees?: FacebookOrgMember[]; 
  message?: string; 
	senderName?: string; 
  // sharePublicly: boolean; 
}

const RecognitionForm: FC = (props) => {
  const [ employees, setEmployees ] = useState<FacebookOrgMember[]>([]); 
  const [ formVal, setFormVal ] = useState<FormVal>({
		// sharePublicly: true
	}); 
  const [ loading, setLoading ] = useState<boolean>(false); 
	const [ notification, setNotification ] = useState<{
		notificationType?: "error" | "success", 
		message?: string
	}>({}); 
	const history = useHistory(); 

  useEffect(() => {
    setLoading(true); 
    getEmployees()
      .then((res) => {
				console.table(res); 
        setEmployees(res); 
        setLoading(false); 
      })
			.catch((err) => {
				setNotification({
					notificationType: "error", 
					message: "Unable to fetch LoBehomies from Facebook Workplace"
				})
			}); 
  }, []); 

	const handleEmployeesChange = (newValue: MultiValue<{
    label: string;
    value: string;
	}>) => {
		// Note: newValue is an array of { label, value }
		setFormVal({
			...formVal, 
			employees: newValue.map((item) => optionItemEmployeeMapper(item, employees)) as FacebookOrgMember[]
		})
	}

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
		console.log(formVal); 

		if (formVal && formVal?.employees && formVal.message) {
			setLoading(true); 

			handleFormSubmission(
				formVal.employees, 
				formVal.message, 
				// formVal.sharePublicly, 
				formVal.senderName ?? ""
			)
			.then((isSuccessful) => {
				if (isSuccessful) {
					setLoading(false); 
					history.push(path.recognitionFormSubmitted);
				} else {
					setLoading(false); 

					// setNotification({
					// 	notificationType: "error", 
					// 	message: errors?.join(", ")
					// }); 
				}
			}); 
		}
		
    console.log("SUBMIT");
	};

  return (
		<LoadingOverlay
			active={loading}
			spinner={Loading}
			styles={{
				overlay: (base: any) => ({
					...base,
					background: "rgba(100, 108, 119, 0.2)",
					zIndex: 2000,
					position: "fixed",
					height: "100vh",
				}),
				wrapper: (base: any) => ({
					...base,
					background: "#f8f6f4",
					display: "flex", 
					flexDirection: "column", 
					alignItems: "center"
				}),
			}}
		>
			<OpenArmsPageLayout>
				<Header>
					<OpenArmsImage />
					<Title>OPEN ARMS</Title>
					<Description>
						A moment to recognise our values in action, to celebrate the
						instances when a peer has demonstrated Intentional Hospitality—and
						to appreciate the people who make Singapore a lovable city for all.
						<br />
						<br />
						<InstagramLink href={"https://www.instagram.com/tlbg.openarms/"}>
							@tlbg.openarms
						</InstagramLink>
					</Description>
				</Header>
				<Form onSubmit={handleSubmit}>
					<Label>
						Your name
						<Input
							value={formVal?.senderName}
							onChange={(e) =>
								setFormVal({
									...formVal,
									senderName: e.target.value,
								})
							}
						/>
					</Label>

					<Label>
						I'd like to recognise
						<Select
							styles={customSelectStyle}
							isMulti={true}
							closeMenuOnSelect={false}
							options={employees && employees.map(employeeOptionItemMapper)}
							placeholder=""
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: () => null,
								ClearIndicator: () => null, 
							}}
							value={formVal.employees?.map(employeeOptionItemMapper)}
							onChange={handleEmployeesChange}
						/>
					</Label>

					<Label>
						Your message
						<TextArea
							value={formVal.message}
							onChange={(e) =>
								setFormVal({
									...formVal,
									message: e.target.value,
								})
							}
						/>
					</Label>
					<SubmitButton type="submit">Submit</SubmitButton>
				</Form>
			</OpenArmsPageLayout>
		</LoadingOverlay>
	);
}

export default RecognitionForm; 

const employeeOptionItemMapper = (employee: FacebookOrgMember) => ({
	label: employee.name ?? "", 
	value: employee.id ?? ""
}); 

const optionItemEmployeeMapper = (
	{ label, value }: LabelValuePair, 
	employeeList: FacebookOrgMember[]
) => employeeList.find((employee) => employee.id === value); 