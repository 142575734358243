import React, { FormEvent } from "react"; 
import styled from "styled-components"; 

const HiddenCheckbox = styled.input.attrs({ 
  type: 'checkbox'
 })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<CheckboxProps>`
  display: table-cell;
  padding: 0px; 
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #646C77;
  translate: scale(0.5, 0.5); 
  vertical-align: middle; 
  text-align: center; 
  background: ${props => props.disabled ? '#DCDCDC' : 'none'}; 
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px #646C77; 
  }
`

const StyledCheckboxCheck = styled.div<CheckboxProps>`
  display: inline-block;
  margin: 0px; 
  padding: 0px; 
  width: 12px; 
  height: 12px;
  background: ${props => (
    props.disabled ? 
    '#DCDCDC' : 
    ( 
      props.active ? 
      '#273561' : 
      '#FFFFFF'
    )
  )};
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Wrapper = styled.div`
  // padding: 0px 0px 0px 4px; 
`

const RawCheckbox = (props: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox 
      checked={props.active} 
      onChange={props.changeHandler} 
      disabled={props.disabled}/>
    <StyledCheckbox 
      active={props.active}
      disabled={props.disabled}>
      <StyledCheckboxCheck
        active={props.active}
        disabled={props.disabled}/>
    </StyledCheckbox>
  </CheckboxContainer>
); 

type CheckboxProps = {
  active: boolean, 
  disabled?: boolean, 
  readOnly?: boolean, 
  changeHandler?: (arg0: FormEvent) => void
}

const Checkbox = (props: CheckboxProps & React.HTMLProps<HTMLInputElement>) => {

  return (
    <Wrapper>
      <label>
        <RawCheckbox
          active={props.active}
          disabled={props.disabled}
          changeHandler={props.changeHandler}
        />
        <>{props.children}</>
      </label>
    </Wrapper>
  )
}

export default Checkbox; 