const VisPalette = [
  "#273561", 
  "#A4B1DB", 
  "#7F91CC", 
  "#5B72BE", 
  "#4159A4"
]

// const VisPalette = [
//   "#273561", 
//   "#4159A4", 
//   "#7F91CC", 
//   "#646C77", 
//   "#646C77"
// ]

export default VisPalette; 