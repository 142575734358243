import { Moment } from "moment";
import { AdhocInputValueType, DashboardType, Dow, TlbgConcept } from "../typings/common";
import { subtractOneDayToDateString, addOneDayToDateString, toStandardDateFormat, fromStandardDateFormat } from "../utils/date";
import { TlbgConceptCode } from "../utils/globals";

type GetDailyRowFilterByFormulaProps = {
  venue?: string, 
  fromDateString?: string, 
  toDateString?: string, 
  dow?: Dow, 
  onDateString?: string
}

type GetYearlyRowFilterByFormulaProps = {
  venue?: string,
  year?: number
}

// Returns the query string for filterByFormula
// The query should get rows that fulfills ALL of the following: 
//    1. Is of given venue (or any venue if left empty)
//    2. Is after fromDateString
//    3. Is before toDateString
//    4. Falls on dow
export const getDailyRowFilterByFormula = ({
  venue, 
  fromDateString, 
  toDateString, 
  dow, 
  onDateString
}: GetDailyRowFilterByFormulaProps) => {

  const filterArgs = [
    venue !== undefined ? `{venue}='${venue}'` : null, 
    fromDateString !== undefined ? `IS_AFTER({business_date}, DATETIME_PARSE('${subtractOneDayToDateString(fromDateString)}'))` : null, 
    toDateString !== undefined ? `IS_BEFORE({business_date}, DATETIME_PARSE('${addOneDayToDateString(toDateString)}'))` : null, 
    dow !== undefined ? `{day_of_week}='${dow}'` : null, 
    onDateString !== undefined ? `IS_SAME({business_date}, DATETIME_PARSE('${onDateString}'), 'day')` : null, 
  ].filter((arg) => arg !== null)
  
  return `AND(${filterArgs.toString()})`; 
}

export const getYearlyRowFilterByFormula = ({
  venue, 
  year
}: GetYearlyRowFilterByFormulaProps) => {

  const filterArgs = [
    venue !== undefined ? `{venue}='${venue}'` : null, 
    year !== undefined ? `{year}='${year}'` : null
  ].filter((arg) => arg !== null)
  
  return `AND(` + filterArgs.toString() + `)`; 
}

export const getVenueFilterByFormula = (
  dashboard?: DashboardType, 
  username?: string
) => {

  const filterArgs = [
    dashboard !== undefined ? 
      `{${dashboard}}` : 
      null, 

    username !== undefined ? 
      `SEARCH("${username}", {accessible_users}) > 0` :
      null

  ].filter((arg) => arg !== null); 

  return `AND(${filterArgs.toString()})`
}

export const getBudgetFilterByFormula = (
  venue: string, 
  date: string
) => {
  const dateMoment = fromStandardDateFormat(date); 
  
  if (Object.keys(TlbgConceptCode).includes(venue)) {
    return `{id}='` +
      `${TlbgConceptCode[venue as TlbgConcept]}:` + 
      `${dateMoment.format("YYYY-MM")}'`
  }
  return ""
}

export const getDayIncentiveParamsFilterByFormula = (
  venue: string, 
  date: Moment, 
  returnsWholeMonth: boolean = false
) => {
  const temp =
		`AND(` +
		`{venue}='${venue}', ` +
		`{year}=${date.year()}, ` +
		`{month}=${date.month() + 1}` +
		(returnsWholeMonth ? `` : `, {day}=${date.date()}`) +
		`)`;  
  return temp; 
}

export const getMonthIncentiveParamsFilterByFormula = (
  venue: string, 
  year: number, 
  month: number, 
  excludeDailyTargets: boolean = true
) => (
  `AND(` + 
    `{venue}='${venue}', ` +
    `{year}=${year}, ` +
    `{month}=${month}` + 
    ( excludeDailyTargets ? 
        `, NOT({input_var_type}='dailyTarget')` : 
        "" ) + 
    `)`
)

export const getMonthIncentiveLockedFilterByFormula = (
  venue: string, 
  date: Moment
) => (
  `AND(` + 
    `{venue}='${venue}', ` +
    `{year}=${date.year()}, ` + 
    `{month}=${date.month() + 1}` + 
    `)`
)

export const getDayIncentiveLockedFilterByFormula = (
  venue: string, 
  date: Moment
) => (
  `{id}='${TlbgConceptCode[venue as TlbgConcept]}:${toStandardDateFormat(date)}'`
  // `AND(` + 
  //   `{venue}='${venue}', ` +
  //   `{year}=${date.year()}, ` + 
  //   `{month}=${date.month() + 1}, ` + 
  //   `{day}=${date.date()}` +
  //   `)`
)


export const getAdhocInputFilterByFormula = (
  venueCode?: string, 
  startDate?: Moment, 
  endDate?: Moment, 
  valueType?: AdhocInputValueType
) => {
  const filterArgs = [
		venueCode ? `{venueCode}='${venueCode}'` : null,
		startDate
			? `IS_AFTER({date}, DATETIME_PARSE('${toStandardDateFormat(
					startDate.subtract(1, "day")
			  )}'))`
			: null,
		endDate
			? `IS_BEFORE({date}, DATETIME_PARSE('${toStandardDateFormat(
					endDate.add(1, "day")
			  )}'))`
			: null,
    valueType
      ? `{valueType}='${valueType}'`
      : null
	].filter((arg) => arg !== null);

  return `AND(${filterArgs.toString()})`
}