import React from "react"
import { SalesSummaryRowType } from "../../typings/common"
import { formatCurrency, getDelta } from "../../utils/currency"
import SalesSummaryRow from "../SalesSummaryRow"

// const dtaNameMap = {
//   totalSales: "Total sales", 
//   orderCount: "Order count", 
//   aov: "Average Order Value (AOV)"
// }

type DtaRowSet = {
  totalSales?: number, 
  orderCount?: number, 
  aov?: number
}

type DtaRowsProps = {
  previous: DtaRowSet, 
  current: DtaRowSet
}

const DtaRows: React.FC<DtaRowsProps> = ({
  previous, 
  current
}) => {
  return (
    <>
      <SalesSummaryRow 
        itemNameText={"Total sales"}
        itemValueText={`${
          formatCurrency(current.totalSales)
        }`}
        delta={getDelta(previous.totalSales, current.totalSales, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>

      <SalesSummaryRow 
        itemNameText={"Order count"}
        itemValueText={current.orderCount !== undefined ? current.orderCount.toFixed(0) : 0}
        delta={getDelta(previous.orderCount, current.orderCount, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>

      
      <SalesSummaryRow 
        itemNameText={"Average Order Value (AOV)"}
        itemValueText={`${
          formatCurrency(current.aov)
        }`}
        delta={getDelta(previous.aov, current.aov, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>
    </>
  )
}

export default DtaRows; 