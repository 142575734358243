import React from "react"; 
import { Channel, ChannelIncDel } from "../typings/common"; 
import styled from "styled-components"; 
import Checkbox from "./InputFields/Checkbox";
import { HeadlineText, SelectionContainer, Text, Panel, PanelContainer } from "./StandardComponents"; 

type ChannelSelectionProps = {
  channels: (Channel | ChannelIncDel)[], 
  channelSelectionChangeHandler: (arg0: string) => void
}

const ChannelSelection: React.FC<ChannelSelectionProps> = ({ 
  channels, 
  channelSelectionChangeHandler
}) => {

  return (
    <SelectionContainer>
      <HeadlineText>Select channel</HeadlineText>
      <PanelContainer>
        <Panel>
        {
          channels
            .filter((channel, index, arr) => index < arr.length / 2)
            .map((channel) => {
              return (
                <Checkbox 
                  key={channel.code}
                  active={channel.active} 
                  changeHandler={() => {
                    channelSelectionChangeHandler(channel.code)
                  }}>
                  <Text>
                    {channel.label}
                  </Text>
                </Checkbox>
              ); 
            })
        }
        </Panel>
        <Panel>
        {
          channels
            .filter((channel, index, arr) => index >= arr.length / 2)
            .map((channel) => {
              return (
                <Checkbox 
                  key={channel.code}
                  active={channel.active} 
                  changeHandler={() => {
                    channelSelectionChangeHandler(channel.code)
                  }}>
                  <Text>
                    {channel.label}
                  </Text>
                </Checkbox>
              ); 
            })
        }
        </Panel>
      </PanelContainer>
    </SelectionContainer>
  )
}

export default ChannelSelection