import React from "react";
import { GroupBase, StylesConfig } from "react-select";
import styled from "styled-components";
import handClap from "../../assets/hand_clap.png"; 

export const OpenArmsPageLayout = styled.div.attrs({
	id: "outer-container",
})`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
	padding: 24px max(5vw, 36px);
	overflow-y: visible;
	max-width: 520px;
	background: #f8f6f4;
`;

export const Header = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center; 
	letter-spacing: 5px; 
`

export const OpenArmsImage = styled.img.attrs({
  src: handClap
})`
  width: 160px; 
`

export const Title = styled.p`
	font-family: Omnes, sans-serif;
	font-size: 24px;
	line-height: 36px;
	font-weight: 400;
	color: #747b61;
	margin: 12px; 
`;

export const Description = styled.p`
	font-family: Livory, serif;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.2px;
	color: #403845;
`;

export const InstagramLink = styled.a`
	:link,
	:visited,
	:hover,
	:active {
		color: #403845;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 20px 0px;
  gap: 40px; 
	flex: 1; 
`;

export const Label = styled.label`
  display: flex; 
  flex-direction: column; 
	font-family: "Omnes", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: #7c8495;
`;

export const Input = styled.input`
	font-family: "Omnes", sans-serif;
	font-size: 14px;
	line-height: 28px !important;
	color: #403845;
	-webkit-appearance: none;
	border-radius: 0px; 

	background-color: #f8f6f4;
	border: 0px;
	border-bottom: 0.5px solid #7c8495;
	padding: 6px 0px;

	&:focus {
		outline: none;
		border-bottom: 1px solid #a1a785;
	}
`;

export const SubmitButton = styled.button`
	height: 48px;
	background-color: #e2cabe;
	margin: 0px; 

	font-family: "Omnes", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: white;
	-webkit-appearance: none;

	border: none;

	&:hover {
		outline: none;
		background-color: #cf9591;
		cursor: pointer;
	}

	:focus {
		outline: none; 
		border: 1px solid #a1a785;
	}
`;

export const customSelectStyle: StylesConfig<
	{
		value: string;
		label: string;
	},
	true,
	GroupBase<{
		value: string;
		label: string;
	}>
> = {
	control: (provided, state) => ({
		...provided,
		border: "none",
		borderBottom: state.isFocused ? "1px solid #A1A785" : "0.5px solid #7C8495",
		borderRadius: "0px",
		boxShadow: "none",
		backgroundColor: "none",
		padding: "0px",

		"&:hover": {
			borderBottom: "0.5px solid #7C84595",
			cursor: "text",
		},
	}),

	valueContainer: (provided, state) => ({
		...provided,
		backgroundColor: "none",
		padding: "none",
		position: "relative",
	}),

	input: (provided, state) => ({
		...provided,
		fontSize: "14px",
		lineHeight: "28px",
		margin: "0px",
		padding: "6px 0px",
		fontFamily: "Omnes",
	}),

	menu: (provided, state) => ({
		...provided,
		backgroundColor: "#F6F0E9",
		borderRadius: "0px",
		fontSize: "14px",
		boxShadow: "none",
		animation: "fadeIn ease 0.4s",
	}),

	menuList: (provided, state) => ({
		...provided,
    maxHeight: "20vh", 
		overflowY: "scroll",
	}),

	multiValueLabel: (provided, state) => ({
		...provided, 
		fontSize: "14px", 
	}), 

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? "#F8F6F4" : "#F6F0E9",
		color: "#403845",
	}),

	multiValue: (provided, state) => ({
		...provided,
		backgroundColor: "#F6F0E9",
		fontSize: "14px",
	}),
};

export const TextArea = styled.textarea`
	font-family: Omnes; 
	margin: 8px 0px;
	font-size: 14px;
	padding: 12px 8px;
	background: none;
	border: 0.5px solid #e2e4e5;
	outline: none;
	-webkit-appearance: none;
	:focus {
		border: 1px solid #a1a785;
	}
`;

const LoadingDot = styled.div`
	border-radius: 50%;
	height: 10px;
	width: 10px;
	background-color: #e2cabe;
	animation-name: preloaderColor;
	animation-timing-function: linear;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	position: absolute;
	:nth-child(1) {
		top: 0;
		left: 0;
		background-color: #273561;
	}
	:nth-child(2) {
		top: 0;
		right: 0;
		animation-delay: 0.25s;
	}
	:nth-child(3) {
		bottom: 0;
		right: 0;
		animation-delay: 0.5s;
	}
	:nth-child(4) {
		bottom: 0;
		left: 0;
		animation-delay: 0.75s;
	}

	@keyframes preloaderColor {
		0% {
			background-color: #e2cabe;
		}
		25% {
			background-color: #e7d4cb;
		}
		40% {
			background-color: #eddfd8;
		}
		100% {
			background-color: #f3e9e5;
		}
	}
`;

const LoadingContainer = styled.div`
	// background-color: #000000;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	transition: opacity 0.25s ease-in-out;
`;

const LoadingDotContainer = styled.div`
	height: 32px;
	width: 32px;
	positive: relative;
	transform: rotate(45deg);
`;

export const Loading = (
	<LoadingContainer>
		<LoadingDotContainer>
			<LoadingDot />
			<LoadingDot />
			<LoadingDot />
			<LoadingDot />
		</LoadingDotContainer>
	</LoadingContainer>
);