import React from 'react'; 
import { DesktopLayout } from './StandardComponents';

const LightspeedConnection: React.FC = (props) => {

  return (
    <DesktopLayout>
      Lightspeed connection successful. 
    </DesktopLayout>
  )
}

export default LightspeedConnection; 