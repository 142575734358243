import React from "react";
import icon_hamburger from "../assets/icon_hamburger.svg";
import icon_close from "../assets/icon_close.svg";
import styled from "styled-components";
import { PopupMenu, MobileMenuItem, DesktopMenuItem, Separator } from "./StandardComponents";
import { PermissionsDashboard, DisplayType, Page } from "../typings/common";
import { getUserPermissionsDashboard } from "../services/AirtablePlus";
import { loggedInUser, menuTitle, path } from "../utils/globals";

const MenuWrapper = styled.div<{
  isOpen: boolean;
}>`
  background: #ffffff;
  position: fixed;
  top: 96px;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100% - 96px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn ease 0.2s;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px;
  max-width: 560px;
`;

const PopupMenuWrapper = styled.div`
  display: block;
  margin: auto 4px;
  position: relative;
`;

const Menu = styled.div`
  position: fixed;
  right: 24px;
  top: 24px;
  z-index: 1000;
`;

type SideBarProps = {
  pageWrapId?: string;
  outerContainerId?: string;
  displayType?: DisplayType;
  selectedItem?: Page;
};

const SideBar: React.FC<SideBarProps> = ({
  pageWrapId,
  outerContainerId,
  displayType = "mobile",
  selectedItem,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [permissionsDashboard, setPermissionsDashboard] = React.useState<PermissionsDashboard[]>(
    []
  );

  React.useEffect(() => {
    getUserPermissionsDashboard(loggedInUser()).then((permissionsDashboardResponse) => {
      setPermissionsDashboard(permissionsDashboardResponse);
    });

    const clickOutsideHandler = (event: Event) => {
      if (event.target !== document.getElementById("popupMenu")) {
        setIsOpen(false);
      }
    };

    const wrapper = outerContainerId ? document.getElementById(outerContainerId) : null;

    if (outerContainerId && wrapper) {
      wrapper.addEventListener("click", clickOutsideHandler);
    }

    return () => {
      if (outerContainerId && wrapper) {
        wrapper.removeEventListener("click", clickOutsideHandler);
      }
    };
  }, []);

  const customHamburgerIcon = <img src={isOpen ? icon_close : icon_hamburger} alt="" />;

  const handleOnOpen = () => {
    setIsOpen(true);
  };
  const handleOnClose = () => {
    setIsOpen(false);
  };

  const MenuItem = displayType === "mobile" ? MobileMenuItem : DesktopMenuItem;

  const menuItems = (
    <MenuItemWrapper>
      {permissionsDashboard.includes("Sales") && (
        <MenuItem href={path.salesDashboard} selected={selectedItem === "Sales"}>
          {menuTitle.salesDashboard}
        </MenuItem>
      )}
      {permissionsDashboard.includes("Marketing") && (
        <MenuItem href={path.marketingDashboard} selected={selectedItem === "Marketing"}>
          {menuTitle.marketingDashboard}
        </MenuItem>
      )}
      {permissionsDashboard.includes("Incentives") && (
        <MenuItem href={path.incentivesDashboard} selected={selectedItem === "Incentives"}>
          {menuTitle.incentivesDashboard}
        </MenuItem>
      )}
      {permissionsDashboard.includes("IncentivesAdmin") && (
        <MenuItem
          href={path.incentivesDashboardAdmin}
          selected={selectedItem === "IncentivesAdmin"}
        >
          {menuTitle.incentivesDashboardAdmin}
        </MenuItem>
      )}
      {permissionsDashboard.includes("DataAssurance") && (
        <MenuItem href={path.assuranceDashboard} selected={selectedItem === "DataAssurance"}>
          {menuTitle.assuranceDashboard}
        </MenuItem>
      )}
      {permissionsDashboard.includes("Groupwide") && (
        <MenuItem href={path.groupwideDashboard} selected={selectedItem === "Groupwide"}>
          {menuTitle.groupwideDashboard}
        </MenuItem>
      )}
      {permissionsDashboard.includes("SalesDataInput") && (
        <MenuItem href={path.salesInput} selected={selectedItem === "SalesDataInput"}>
          {menuTitle.salesDataInput}
        </MenuItem>
      )}
      {permissionsDashboard.includes("OpenArmsRecognition") && (
        <MenuItem
          href={path.recognitionDashboard}
          selected={selectedItem === "OpenArmsRecognition"}
        >
          {menuTitle.recognitionDashboard}
        </MenuItem>
      )}
      {(permissionsDashboard.includes("ManualAdjustmentRequest") ||
        permissionsDashboard.includes("ManualAdjustments") ||
        permissionsDashboard.includes("ManualApiRefresh")) && <Separator />}
      {permissionsDashboard.includes("ManualAdjustmentRequest") && (
        <MenuItem
          href={path.manualAdjustmentRequest}
          selected={selectedItem === "ManualAdjustmentRequest"}
        >
          {menuTitle.manualAdjustmentRequest}
        </MenuItem>
      )}
      {permissionsDashboard.includes("ManualAdjustments") && (
        <MenuItem href={path.manualAdjustments} selected={selectedItem === "ManualAdjustments"}>
          {menuTitle.manualAdjustments}
        </MenuItem>
      )}
      {permissionsDashboard.includes("ManualApiRefresh") && (
        <MenuItem href={path.posDataRefresh} selected={selectedItem === "ManualApiRefresh"}>
          {menuTitle.posDataRefresh}
        </MenuItem>
      )}
      <Separator />
      <MenuItem href={path.setPassword} selected={selectedItem === "SetPassword"}>
        {menuTitle.setPassword}
      </MenuItem>
      <MenuItem href={path.changelog} selected={selectedItem === "Changelog"}>
        {menuTitle.changelog}
      </MenuItem>
      <MenuItem href={path.logout} selected={selectedItem === "Login"}>
        {menuTitle.logout}
      </MenuItem>
    </MenuItemWrapper>
  );

  if (displayType === "mobile") {
    return (
      <Menu onClick={() => (isOpen ? handleOnClose() : handleOnOpen())}>
        {customHamburgerIcon}
        {isOpen && <MenuWrapper isOpen={isOpen}>{menuItems}</MenuWrapper>}
      </Menu>
    );
  } else {
    return (
      <PopupMenuWrapper>
        <img src={icon_hamburger} onClick={() => setIsOpen(!isOpen)} />
        {isOpen && <PopupMenu id="popupMenu">{menuItems}</PopupMenu>}
      </PopupMenuWrapper>
    );
  }
};

export default SideBar;
