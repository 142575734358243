import { IncentiveStructureParameters } from "../typings/common";

const computeIncentivesDailyAdditions = (
  incentiveStructureParams: IncentiveStructureParameters,
  daySales: number,
  currentDay: string
) => {
  if (incentiveStructureParams.wholeMonthDailyTargets === undefined) {
    throw "incentiveStructureParams.wholeMonthDailyTargets is undefined";
  }
  const dailyTarget = incentiveStructureParams.wholeMonthDailyTargets[currentDay] ?? 0;

  const salesExceedDailyTarget = daySales >= (dailyTarget ?? 0);

  const additionPreTargetPortion = salesExceedDailyTarget
    ? (incentiveStructureParams.dailyTargetCut ?? 0) * (dailyTarget ?? 0)
    : 0;

  const additionPostTargetPortion = salesExceedDailyTarget
    ? (incentiveStructureParams.dailyTargetBonusCut ?? 0) * ((daySales ?? 0) - (dailyTarget ?? 0))
    : 0;

  return {
    salesExceedDailyTarget,
    additionPreTargetPortion,
    additionPostTargetPortion,
  };
};

export default computeIncentivesDailyAdditions;
