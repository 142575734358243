import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from "../services/AuthService";
import { AuthResponse, PermissionsDashboard } from "../typings/common";
import Login from "./Login";

type ProtectedRouteProps = {
  component: any; // Issue
  path: string;
  permissionsDashboard?: PermissionsDashboard;
};

const ProtectedRoute = ({
  component: RoutedComponent,
  permissionsDashboard,
  ...otherProps
}: ProtectedRouteProps) => {
  const [authResponse, setAuthResponse] = React.useState<AuthResponse>();

  React.useEffect(() => {
    const checkAuthenticated = async () => {
      const result = await AuthService.isAuthenticated(permissionsDashboard);

      setAuthResponse(result);
    };

    checkAuthenticated();
  }, []);

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (authResponse) {
          if (authResponse === AuthResponse.success) {
            return <RoutedComponent {...props} />;
          } else if (authResponse === AuthResponse.forbidden) {
            return <Login />;
          } else if (authResponse === AuthResponse.unauthorised) {
            return <Redirect to="permission_denied" />;
          }
        } else {
          return (
            <div
              style={{
                backgroundColor: "#DFE1E4",
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
