import { Moment } from "moment";
import { DataPoint } from "../../components/HighCharts/HcLineChartTimeSeries";
import { HistoricSalesItem, HistoricSalesTrendViewOption } from "../../typings/groupwideDashboard";
import {
  fromToMoment,
  thisTimeLastYear,
  toHumanDateFormat,
  toHumanDateFormatWithDow,
  toHumanMonthYearString,
  toStandardDateFormat,
} from "../date";
import quarterlyXAxisLabel from "./quarterlyXAxisLabel";

const historicSalesToDataPoints = (
  historicSalesItems: HistoricSalesItem[],
  startDate: Moment,
  endDate: Moment,
  historicSalesTrendView: HistoricSalesTrendViewOption
): DataPoint[] => {
  let output: DataPoint[] = [];
  const historicSalesItemsFiltered = historicSalesItems.filter((item) =>
    item.date.isBetween(startDate, endDate, "day", "[]")
  );
  const salesItemsDailyMap: {
    [date: string]: number;
  } = historicSalesItemsFiltered.reduce((accum, curr) => {
    const currDate = toStandardDateFormat(curr.date);

    accum[currDate] = curr.sales;
    return accum;
  }, {} as { [date: string]: number });

  if (historicSalesTrendView === "daily") {
    output = fromToMoment(startDate, endDate, 1, "day").map((iterDate) => {
      const iterDateStr = toStandardDateFormat(iterDate);
      return {
        pointLabel: toHumanDateFormatWithDow(iterDate),
        value: salesItemsDailyMap[iterDateStr] ?? 0,
      };
    });
  } else if (historicSalesTrendView === "weekly") {
    output = fromToMoment(startDate, endDate, 1, "week").map((iterWeekStartDate) => {
      const iterWeekEndDate = iterWeekStartDate.clone().endOf("isoWeek");
      const value = fromToMoment(iterWeekStartDate, iterWeekEndDate, 1, "day").reduce<number>(
        (accum, curr) => {
          const currDateStr = toStandardDateFormat(curr);
          return accum + (salesItemsDailyMap[currDateStr] ?? 0);
        },
        0
      );

      return {
        pointLabel: `${toHumanDateFormat(iterWeekStartDate)} to ${toHumanDateFormat(
          iterWeekEndDate
        )}`,
        value,
        xAxisLabel: quarterlyXAxisLabel(iterWeekEndDate, "week", endDate),
      };
    });
  } else if (historicSalesTrendView === "monthly") {
    output = fromToMoment(startDate, endDate, 1, "month").map((iterMonthStartDate) => {
      const iterMonthEndDate = iterMonthStartDate.clone().endOf("month");
      const value = fromToMoment(iterMonthStartDate, iterMonthEndDate, 1, "day").reduce<number>(
        (accum, curr) => {
          const currDateStr = toStandardDateFormat(curr);
          return accum + (salesItemsDailyMap[currDateStr] ?? 0);
        },
        0
      );
      const isEndDateInSameMonth = endDate.isSame(iterMonthStartDate, "month");
      const pointLabel = `${isEndDateInSameMonth ? "MTD " : ""}${toHumanMonthYearString(
        iterMonthEndDate
      )}`;

      return {
        pointLabel,
        value,
        xAxisLabel: quarterlyXAxisLabel(iterMonthEndDate, "month", endDate),
      };
    });
  }

  return output;
};

export default historicSalesToDataPoints;
