import styled from "styled-components"
import React from "react"
import { SalesSummaryRowType } from "../../typings/common"

type WrapperProps = {
  type?: SalesSummaryRowType, 
  noGap: boolean
}

const StandardWrapper = styled.div`
  display: flex; 
  font-size: 14px; 
  flex-direction: row; 
  padding: 8px 0px; 
  flex: 1; 
`

const HeadlineTextWrapper = styled.div`
  display: flex; 
  flex-direction: row; 
  font-size: 14px; 
  font-weight: 600; 
  padding: 8px 0px; 
  color: #273561; 
  text-align: left; 
  flex: 1; 
`

const GrayTextWrapper = styled.div`
  display: flex; 
  font-size: 14px; 
  flex-direction: row; 
  color: #666666;
  padding: 8px 0px; 
  flex: 1; 
`

const GrayBoldTextWrapper = styled.div`
  border-top: 0.5px solid #646C7780;
  display: flex; 
  font-size: 14px; 
  flex-direction: row; 
  padding: 8px 0px; 
  color: #646C77;
  font-weight: 500; 
  flex: 1; 
`

const BlueBoldTextWrapper = styled.div<{ 
  wSummaryLine: boolean, 
  noGap: boolean
}>`
  ${({wSummaryLine}) => wSummaryLine ? 
    `border-top: 0.5px solid #646C7780;
    ` : 
    ""
  }
  display: flex; 
  font-size: 14px; 
  flex-direction: row; 
  padding: ${({noGap}) => noGap ? `0` : `8`}px 0px; 
  color: #273561;
  font-weight: 500; 
  flex: 1; 
`

export const Wrapper: React.FC<WrapperProps> = ({ 
  type, 
  noGap, 
  children
}) => {
  // console.log(type)
  if (type === SalesSummaryRowType.headline) {
    return (<HeadlineTextWrapper>
      {children}
    </HeadlineTextWrapper>)
  } 
  else if (type === SalesSummaryRowType.grayText) {
    return (<GrayTextWrapper>
      {children}
    </GrayTextWrapper>)
  } 
  else if (type === SalesSummaryRowType.grayBoldText) {
    return (<GrayBoldTextWrapper>
      {children}
    </GrayBoldTextWrapper>)
  } 
  else if (type === SalesSummaryRowType.blueBoldTextWSummaryLine) {
    return (<BlueBoldTextWrapper wSummaryLine={true} noGap={noGap}>
      {children}
    </BlueBoldTextWrapper>)
  } 
  else if (type === SalesSummaryRowType.blueBoldText) {
    return (<BlueBoldTextWrapper wSummaryLine={false} noGap={noGap}>
      {children}
    </BlueBoldTextWrapper>)
  } 
  else {
    return (<StandardWrapper>
      {children}
    </StandardWrapper>)
  } 
}