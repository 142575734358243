import React from "react"
import { SalesSummaryRowType } from "../../typings/common";
import { formatCurrency, getDelta } from "../../utils/currency";
import SalesSummaryRow from "../SalesSummaryRow"

// const deaNameMap = {
//   totalSales: "Total sales", 
//   orderCount: "Order count", 
//   aov: "Average Order Value (AOV)"
// }

type DeaRowsSet = {
  totalSales?: number, 
  guestCount?: number, 
  spendPerGuest?: number
}

type DeaRowsProps = {
  current: DeaRowsSet, 
  previous: DeaRowsSet
}

const DeaRows: React.FC<DeaRowsProps> = ({
  current, 
  previous
}) => {
  return (
    <>
      <SalesSummaryRow 
        itemNameText={"Total sales (inc. service charge)"}
        itemValueText={`${
          formatCurrency(current.totalSales)
        }`}
        delta={getDelta(previous.totalSales, current.totalSales, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>

      <SalesSummaryRow 
        itemNameText={"Number of guest"}
        itemValueText={current.guestCount !== undefined ? current.guestCount.toFixed(0) : 0}
        delta={getDelta(previous.guestCount, current.guestCount, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>

      
      <SalesSummaryRow 
        itemNameText={"Average spend per guest"}
        itemValueText={`${
          formatCurrency(current.spendPerGuest)
        }`}
        delta={getDelta(previous.spendPerGuest, current.spendPerGuest, " vs. last year")}
        type={SalesSummaryRowType.grayText}/>
    </>
  )
}

export default DeaRows; 