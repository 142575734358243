import { TlbgConcept } from "../../typings/common";
import { TlbgConceptCode } from "../../utils/globals";
import { addBug, addOpenArmsRecognition } from "../AirtablePlus";
import { getAirtableRowsVenue } from "../AirtableService";
import getManagersFacebook from "../FacebookWorkplace/getManagers";
import sendMessage from "../FacebookWorkplace/sendMessage";
import { managerMessage, recipientMessage } from "./messages";

const handleFormSubmission = (
  employees: FacebookOrgMember[], 
  message: string, 
  // sharePublicly: boolean, 
  senderName: string
): Promise<boolean> => {
  const logs = []; 

  const isSentToAllEmployees = () => Promise.all(employees.map((employee) => 
    isSentToEmployee(employee, senderName, message)
  )); 

  const managersFacebookPromise = () => Promise.all(
		employees.map((employee) =>
			getManagersFacebook(employee.id ?? "")
		)
  );

  const managersVenuePromise = () =>
		Promise.all(
			getEmployeesVenues(employees).map(getVenueManagersFacebookWorkplaceIds)
		);

  const isSentToAllManagers = () =>
		Promise.all([
      managersFacebookPromise(), 
      managersVenuePromise()
    ])
    .then(([
      managersFacebookResponse, 
      managersVenueResponse
    ]) => {
      const managersFacebookIds = managersFacebookResponse
        .flat()
        .map((manager) => manager.id) as string[]; 

      const managersVenueIds = managersVenueResponse
        .flat() as string[]; 

      logs.push({managersFacebookIds}); 
      logs.push({managersVenueIds}); 

      const uniqueManagerIds = [
        ...managersFacebookIds, 
        ...managersVenueIds
      ].filter((id, i, arr) => id && i === arr.findIndex((id2) => id === id2)); 

      logs.push({uniqueManagerIds}); 

      return uniqueManagerIds; 
    }) // Returns FacebookOrgMember[]: Array of unique managers
    .then((managerIds) =>
      sendToManagers(managerIds, employees, senderName, message)
    ); 
  
  const isAllAddedToAirtable = () => Promise.all(
    employees.map((employee) => isAddedToAirtable(employee, senderName, message))
  ); 

  Promise.all(
    getEmployeesVenues(employees)
    .map((venueCode) => getVenueManagersFacebookWorkplaceIds(venueCode))
  )
  .then((venueManagersWorkplaceIds) => console.log(venueManagersWorkplaceIds)); 

  return Promise.all(
		[
			isSentToAllEmployees(),
			isSentToAllManagers(),
			isAllAddedToAirtable()
		]
	).then((res) => {
    console.log(res); 
		const isSuccessful = res.every((each) => each);

    if (! isSuccessful) {
      addBug({
				bugType: "Open Arms Submission",
				bugDescription: `isSentToAllEmployees: ${res[0]}; isSentToAllManagers: ${res[1]}; isAllAddedToAirtable: ${res[2]}; `
			});
    }
		
    return isSuccessful; 
	});
}

export default handleFormSubmission; 

const isSentToEmployee = (
  employee: FacebookOrgMember, 
  senderName: string, 
  message: string
) =>
	sendMessage(
		employee.id ?? "",
		recipientMessage(employee.name ?? "", senderName, message)
	)
  .then((res) => Boolean(res.message_id))
  .catch((err) => {
    console.error(err);
    return false;
  });

const isAddedToAirtable = (
  employee: FacebookOrgMember, 
  senderName: string, 
  message: string
) =>
	addOpenArmsRecognition({
		employeeId: employee.id,
		employeeEmail: employee.email,
		message,
		// sharePublicly,
		senderName,
	}); 

const getEmployeesVenues = (employees: FacebookOrgMember[]) => {
  const employeeVenues = employees
		.map((employees) => employees.division); 
  
  console.log("employeeVenues", employeeVenues); 

  const uniqueVenues = employeeVenues
		.filter(
			(division, i, arr) =>
				division && arr.findIndex((element) => element === division) === i
		); 

  console.log("uniqueVenues", uniqueVenues); 

  const uniqueVenueCodes = uniqueVenues
		.map((division) => TlbgConceptCode[division as TlbgConcept]); 

  console.log("uniqueVenueCodes", uniqueVenueCodes); 

  return uniqueVenueCodes; 
}

const getVenueManagersFacebookWorkplaceIds = (venueCode: string) => getAirtableRowsVenue({
  fields: ["venue_leaders", "venue_leaders_facebook_workplace_id"], 
  filterByFormula: `{code}="${venueCode}"`, 
  maxRecords: 1
})
.then((res) => res[0]?.fields.venue_leaders_facebook_workplace_id); 

const sendToManagers = (
  managerIds: string[], 
  employees: FacebookOrgMember[], 
  senderName: string, 
  message: string
) => {
	const employeeNames = employees.map((employee) => employee.name) as string[];

	const sendToManagersPromises = managerIds.map(
		(managerId) => () =>
			sendMessage(
				managerId ?? "",
				managerMessage(employeeNames, senderName, message)
			).then((res) => {
				return res.message_id !== undefined;
			})
	);

	return Promise.all(sendToManagersPromises.map((p) => p()));
}
