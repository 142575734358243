import moment, { Moment } from "moment";
import { getAdhocInputByDateRange, getVenueRecordId } from "../../services/AirtablePlus";
import { getAdhocInputFilterByFormula } from "../../services/AirtablePlusFilterByFormula";
import { addAdhocInput, updateAdhocInput } from "../../services/AirtablePlus";
import { getAdhocInput } from "../../services/AirtableService";
import { AdhocInputValueType, DailyMetric, DatabaseUpdateResponse, FieldAddUpdatePromiseProps, TlbgConcept } from "../../typings/common";
import { fromStandardDateFormat, fromToMoment, toStandardDateFormat } from "../../utils/date";
import { aggregateDatabaseUpdateResponse, TlbgConceptCode } from "../../utils/globals";

type SalesInputFieldAddUpdatePromiseProps = FieldAddUpdatePromiseProps & {
	field: AdhocInputValueType
}

export const getSalesInputDataMonthly = (
	venueCode: string,
	year: number,
	month: number
): Promise<{
  adhocInput: DailyMetric, 
  lastUpdated: Moment
}> =>
	getAdhocInputByDateRange(
		"netSalesWithServiceCharge",
		venueCode,
		moment()
			.year(year)
			.month(month - 1)
			.startOf("month"),
		moment()
			.year(year)
			.month(month - 1)
			.endOf("month")
	)
	.then((response) => {
		// Change key from date to dayIndex
		let adhocInput: DailyMetric = {}; 
		Object.keys(response.adhocInput).map((key) => {
			adhocInput[fromStandardDateFormat(key).date()] = response.adhocInput[key]; 
		}); 

		return {
			lastUpdated: response.lastUpdated, 
			adhocInput
		}
	});

export const pushSalesInputDataMonthly = (
	salesInput: DailyMetric,
	venue: string,
	year: number,
	month: number,
	username: string
	// ): Promise<DatabaseUpdateResponse> => {
) => {
	const startDate = moment()
		.year(year)
		.month(month - 1)
		.startOf("month");

	const endDate =	moment()
		.year(year)
		.month(month - 1)
		.endOf("month"); 

	return Promise.all([
		getAdhocInput({
			filterByFormula: getAdhocInputFilterByFormula(
				TlbgConceptCode[venue as TlbgConcept],
				startDate.clone(), 
				endDate.clone(), 
				"netSalesWithServiceCharge"
			),
		}),
		getVenueRecordId(venue),
	]).then(([existingDataRecords, venueRecordId]) => {
		
		// DEBUG
		console.log(existingDataRecords, venueRecordId); 

		let existingDataRecordsByDate: {
			[key: string]: Airtable.Record<any>
		} = {}; 
		existingDataRecords.forEach((record) => {
			existingDataRecordsByDate [
				record.fields.date
			] = record; 
		})

		console.log(startDate, endDate); 
		const fieldsAddUpdatePromises: (() => Promise<DatabaseUpdateResponse>)[] = 
			fromToMoment(
				startDate, 
				endDate
			).map((iterDate) => {
				const iterDateString = toStandardDateFormat(iterDate); 
				const iterDateValue = salesInput[iterDate.date()]; 
				if (iterDateString in existingDataRecordsByDate) {
					return () =>
						updateAdhocInput(
							existingDataRecordsByDate[iterDateString].id, 
							{
								value: salesInput[iterDate.date()],
							}
						);
				} 
				else {
					if (iterDateValue) {
						return () =>
							addAdhocInput({
								venue: venueRecordId,
								date: iterDateString,
								valueType: "netSalesWithServiceCharge",
								value: salesInput[iterDate.date()],
							});
					} 
					else {
						console.log(`No value for ${iterDate.date()} to add`);
						return () => Promise.resolve({});
					}
				}
				
			}); 

		console.log(fieldsAddUpdatePromises); 	
		return Promise.all(fieldsAddUpdatePromises.map((promise) => promise()))
			.then((databaseUpdateResponses) => aggregateDatabaseUpdateResponse(databaseUpdateResponses));
	});
};