import { Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import lightspeedApiRefresh from "../services/lightspeedApiRefresh";
import { SnackbarProps } from "../typings/common";
import { fromStandardDateFormat } from "../utils/date";
import SelectField from "./InputFields/SelectField";
import TextField from "./InputFields/TextField";
import MainHeader from "./MainHeader";
import SideBar from "./SideBarNew";
import {
  Button,
  Loading,
  MobileLayout,
  Section,
  SectionHeaderNoLine,
  SectionSubHeader,
  SuccessAlert,
} from "./StandardComponents";

type SelectionProps = {
  venue?: string;
  date?: string;
};

const AVAILABLE_VENUES: {
  label: string;
  value: string;
  queryFunction: (date: string) => Promise<boolean>;
}[] = [
  {
    label: "Le Bon Funk (Club Street)",
    value: "LBF",
    queryFunction: lightspeedApiRefresh,
  },
  {
    label: "Le Bon Funk (Holland Village)",
    value: "FHV",
    queryFunction: lightspeedApiRefresh,
  },
];

const ManualApiRefreshPage: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<SelectionProps>();
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    open: false,
    message: "None",
    severity: "warning",
  });

  const handleRefreshClick = async () => {
    setLoading(true);
    const selectedVenueQueryFunction = AVAILABLE_VENUES.find(
      (venue) => venue.value === selection?.venue
    )?.queryFunction;
    if (!selectedVenueQueryFunction || !selection?.date) {
      setLoading(false);
      return;
    }
    const result = await selectedVenueQueryFunction(selection.date);
    if (result) {
      setSnackbarProps({
        open: true,
        message: "Refresh successful!",
        severity: "success",
      });
      setLoading(false);
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          position: "fixed",
          height: "100vh",
        }),
      }}
    >
      <MobileLayout>
        <MainHeader />

        <SideBar selectedItem="ManualApiRefresh" />
        <Snackbar
          {...snackbarProps}
          autoHideDuration={6000}
          onClose={() => setSnackbarProps({ ...snackbarProps, open: false })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SuccessAlert>{snackbarProps.message}</SuccessAlert>
        </Snackbar>
        <SectionHeaderNoLine>POS Data Refresh</SectionHeaderNoLine>
        <SectionSubHeader>Pulls data from POS API and updates TLBG database</SectionSubHeader>
        <Section>
          <SelectField
            label="Venue"
            placeholderLabel="Select venue..."
            changeHandler={(selected) => setSelection({ ...selection, venue: selected })}
            defaultValue={selection?.venue}
            options={AVAILABLE_VENUES}
          />

          <TextField
            defaultDate={
              selection?.date !== undefined
                ? fromStandardDateFormat(selection?.date).toDate()
                : undefined
            }
            label="Date"
            inputType="date"
            changeHandler={(selected) => setSelection({ ...selection, date: selected })}
          />
        </Section>
        <Button onClick={handleRefreshClick}>Refresh</Button>
      </MobileLayout>
    </LoadingOverlay>
  );
};

export default ManualApiRefreshPage;
