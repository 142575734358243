// To reconcile the same data with different data sources for different periods
import { Moment } from "moment";
import { DailyRow, SalesHistoryLastYear, SalesHistoryRecent } from "../typings/common";
import { toDailyRow } from "../utils/dailyRow";
import { fromStandardDateFormat, fromToMoment, toStandardDateFormat } from "../utils/date";
import { airtableRequiredFields, compareString, weekDayIndexToDow } from "../utils/globals";
import {
  getSpecificSalesHistory,
  salesHistoryLyDateCriteria,
  salesHistoryRecentDateCriteria,
} from "../utils/salesHistory";
import { getDailyRowFilterByFormula } from "./AirtablePlusFilterByFormula";
import { getAirtableRowsRawData } from "./AirtableService";

export const getDailyRowPeriod = (
  dateFrom: Moment,
  dateTo: Moment,
  venue: string,
  airtableRequiredFieldsContext: string[] = airtableRequiredFields.RawData.dailyRowSalesHistoric
  // ): Promise<{ [key:string]: DailyRow }> => {
): Promise<DailyRow[]> =>
  getAirtableRowsRawData({
    fields: airtableRequiredFieldsContext,
    filterByFormula: getDailyRowFilterByFormula({
      venue: venue,
      fromDateString: toStandardDateFormat(dateFrom),
      toDateString: toStandardDateFormat(dateTo),
    }),
  })
    .then((res) => res.map((record) => toDailyRow(record)))
    .then((airtableResponse) =>
      airtableResponse.sort((dailyRowA, dailyRowB) =>
        compareString(dailyRowA.business_date ?? "", dailyRowB.business_date ?? "")
      )
    );

export const dailyRowsToSalesHistoryRecent = (
  dailyRows: DailyRow[],
  currentDate: Moment
): [
  // Returns currentDate's dailyRow and SalesHistoryRecent
  DailyRow,
  SalesHistoryRecent
] => {
  const {
    l6wDowSalesCriterion,
    ttlmMtdSalesCriterion,
    salesTrendL6wCriterion,
    mtdSalesCriterion,
    dateRange,
  } = salesHistoryRecentDateCriteria(currentDate);

  // Tag dailyRows first
  dailyRows.forEach((dailyRow) => {
    const iterDate = fromStandardDateFormat(dailyRow.business_date ?? "");
    dailyRow.includedInSalesHistory = {
      l6wDowSales: l6wDowSalesCriterion(iterDate),
      ttlmMtdSales: ttlmMtdSalesCriterion(iterDate),
      salesTrendL6w: salesTrendL6wCriterion(iterDate),
      mtdSales: mtdSalesCriterion(iterDate),
    };
  });

  const l6wDowSales = getSpecificSalesHistory(dailyRows, "l6wDowSales", true);
  const ttlmMtdSales = getSpecificSalesHistory(dailyRows, "ttlmMtdSales", true);

  const salesTrendL6w = getSpecificSalesHistory(dailyRows, "salesTrendL6w", true);

  const mtdSales = getSpecificSalesHistory(dailyRows, "mtdSales", true);

  const currentDailyRow = dailyRows.find(
    (dailyRow) => dailyRow.business_date === toStandardDateFormat(currentDate)
  );

  return [
    currentDailyRow ?? {
      business_date: toStandardDateFormat(currentDate),
      day_of_week: weekDayIndexToDow(currentDate.day()),
    },
    {
      l6wDowSales,
      ttlmMtdSales,
      salesTrendL6w,
      mtdSales,
    },
  ];
};

export const dailyRowsToSalesHistoryLastYear = (
  dailyRows: DailyRow[],
  currentDate: Moment
): SalesHistoryLastYear => {
  const { tmlyDowSalesCriterion, tmlyMtdSalesCriterion, salesTrendL6wLyCriterion, dateRange } =
    salesHistoryLyDateCriteria(currentDate);

  // Tag dailyRows first
  dailyRows.forEach((dailyRow) => {
    const iterDate = fromStandardDateFormat(dailyRow.business_date ?? "");
    dailyRow.includedInSalesHistory = {
      tmlyDowSales: tmlyDowSalesCriterion(iterDate),
      tmlyMtdSales: tmlyMtdSalesCriterion(iterDate),
      salesTrendL6wLy: salesTrendL6wLyCriterion(iterDate),
    };
  });

  const tmlyDowSales = getSpecificSalesHistory(dailyRows, "tmlyDowSales", true);

  const tmlyMtdSales = getSpecificSalesHistory(dailyRows, "tmlyMtdSales", true);

  const salesTrendL6wLy = getSpecificSalesHistory(dailyRows, "salesTrendL6wLy", true);

  return {
    tmlyDowSales,
    tmlyMtdSales,
    salesTrendL6wLy,
  };
};
