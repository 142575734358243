import { Moment } from "moment";
import { toHumanMonthYearString } from "../date";
import { standardQuarterLabelSeries } from "../globals";

const quarterlyXAxisLabel = (
  date: Moment,
  interval: "month" | "week",
  basedOnLastDate?: Moment // The labels will follow last date if this field is provided
) => {
  const monthIndex = date.month();

  // If basedOnLastDate is not provided, defaults to Jan, Apr, Jul, Oct
  const quarterLabelSeriesToUse = basedOnLastDate
    ? standardQuarterLabelSeries.find((quarterLabelSeriesToUse) =>
        quarterLabelSeriesToUse.monthIndices.includes(basedOnLastDate.month())
      )
    : standardQuarterLabelSeries[0];

  if (interval === "month") {
    return quarterLabelSeriesToUse?.monthIndices.includes(monthIndex)
      ? toHumanMonthYearString(date)
      : "";
  } else if (interval === "week") {
    return quarterLabelSeriesToUse?.monthIndices.includes(monthIndex) &&
      date.clone().startOf("week").isSameOrBefore(date.clone().date(1), "day")
      ? toHumanMonthYearString(date)
      : "";
  }
};

export default quarterlyXAxisLabel;
