import {
  AdhocDataInput,
  AdhocInputValueType,
  Bug,
  DailyRow,
  DashboardType,
  DatabaseUpdateResponse,
  IncentiveMechanic,
  IncentiveParam,
  IncentiveSplitMechanic,
  IncentiveStructureParameters,
  PullHistoricProps,
  PullHistoricPropsDateRange,
  RecognitionData,
} from "../typings/common";
import { TlbgConceptCode, airtableRequiredFields } from "../utils/globals";
import {
  addAirtableRowAdhocInput,
  addAirtableRowBug,
  addAirtableRowOpenArmsRecognition,
  addAirtableRowsIncentiveParams,
  getAdhocInput,
  getAirtableRowOpenArmsRecognition,
  getAirtableRowsBudget,
  getAirtableRowsIncentiveLocked,
  getAirtableRowsIncentiveParams,
  getAirtableRowsOccasion,
  getAirtableRowsRawData,
  getAirtableRowsUser,
  getAirtableRowsVenue,
  getAirtableRowsYearlyData,
  updateAirtableRowAdhocInput,
  updateAirtableRowsIncentiveParams,
} from "./AirtableService";
import {
  fromStandardDateFormat,
  fromStandardDateTimeFormat,
  getFirstDayOfMonth,
  getL6w,
  getLastDayOfMonth,
  getMtd,
  getTmly,
  getTtlmMtd,
  thisTimeLastYear,
  toStandardDateFormat,
  toStandardDateTimeFormat,
} from "../utils/date";
import {
  getAdhocInputFilterByFormula,
  getBudgetFilterByFormula,
  getDailyRowFilterByFormula,
  getDayIncentiveLockedFilterByFormula,
  getDayIncentiveParamsFilterByFormula,
  getMonthIncentiveLockedFilterByFormula,
  getMonthIncentiveParamsFilterByFormula,
  getVenueFilterByFormula,
  getYearlyRowFilterByFormula,
} from "./AirtablePlusFilterByFormula";

import { Moment } from "moment";
import moment from "moment";
import { toDailyRow } from "../utils/dailyRow";

export function getSalesHistoryRecent(pullHistoricProps: PullHistoricProps) {
  const selectedDate = fromStandardDateFormat(pullHistoricProps.date);
  const selectedDateDow = selectedDate.day();
  const l6w = getL6w(selectedDate);
  // const l30d = getL30d(selectedDate);
  const ttlmMtd = getTtlmMtd(selectedDate);
  const mtd = getMtd(selectedDate);
  const fromDate = moment.min([l6w.from, ttlmMtd.from, mtd.from]);
  const toDate = selectedDate.clone();

  const l6wDowSalesCriteria = (date: Moment) => {
    const fulfillsCriteria =
      date.day() === selectedDateDow && date.isBetween(l6w.from, l6w.to, "day", "[)");

    return fulfillsCriteria;
  };

  const ttlmMtdSalesCriteria = (date: Moment) => {
    const fulfillsCriteria = date.isBetween(ttlmMtd.from, ttlmMtd.to, "day", "[]");

    return fulfillsCriteria;
  };

  const salesTrendL6wCriteria = (date: Moment) => {
    const fulfillsCriteria = date.isBetween(l6w.from, l6w.to, "day", "[]");

    return fulfillsCriteria;
  };

  const mtdSalesCriteria = (date: Moment) => {
    const filfillsCriteria = date.isBetween(mtd.from, mtd.to, "day", "[]");

    return filfillsCriteria;
  };

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.dailyRowSalesHistoric,
    filterByFormula: getDailyRowFilterByFormula({
      venue: pullHistoricProps.venue,
      fromDateString: toStandardDateFormat(fromDate),
      toDateString: toStandardDateFormat(toDate),
    }),
  })
    .then((res) => {
      const salesHistory = res.map((record) => {
        let salesHistoryChild = toDailyRow(record);
        // Assign values to includedInSalesHistory depending on matching criteria
        if (salesHistoryChild?.business_date !== undefined) {
          salesHistoryChild["includedInSalesHistory"] = {
            l6wDowSales: l6wDowSalesCriteria(
              fromStandardDateFormat(salesHistoryChild.business_date)
            ),
            ttlmMtdSales: ttlmMtdSalesCriteria(
              fromStandardDateFormat(salesHistoryChild?.business_date)
            ),
            salesTrendL6w: salesTrendL6wCriteria(
              fromStandardDateFormat(salesHistoryChild.business_date)
            ),
            mtdSales: mtdSalesCriteria(fromStandardDateFormat(salesHistoryChild.business_date)),
            toggle: true,
          };
        }
        return salesHistoryChild;
      });

      return salesHistory;
    })
    .catch((err) => console.error(err));
}

export function getSalesHistoryLastYear(pullHistoricProps: PullHistoricProps) {
  const selectedDate = fromStandardDateFormat(pullHistoricProps.date);
  const selectedDateDow = selectedDate.day();
  const ttly = thisTimeLastYear(selectedDate);
  const tmly = getTmly(selectedDate);
  const l6wLy = getL6w(ttly);
  const fromDate = moment.min([ttly, tmly.from, l6wLy.from]);
  const toDate = moment.max([ttly, tmly.to, l6wLy.to]);

  const tmlyDowSalesCriteria = (date: Moment) => {
    const fulfillsCriteria =
      date.day() === selectedDateDow &&
      date.isSameOrAfter(tmly.from) &&
      date.isSameOrBefore(tmly.to);

    return fulfillsCriteria;
  };

  const tmlyMtdSalesCriteria = (date: Moment) => {
    const fulfillsCriteria =
      date.isSameOrBefore(ttly) && date.isSameOrAfter(tmly.from) && date.isSameOrBefore(tmly.to);

    return fulfillsCriteria;
  };

  const salesTrendL6wLyCriteria = (date: Moment) => {
    const fulfillsCriteria = date.isSameOrAfter(l6wLy.from) && date.isSameOrBefore(l6wLy.to);

    return fulfillsCriteria;
  };

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.dailyRowSalesHistoric,
    filterByFormula: getDailyRowFilterByFormula({
      venue: pullHistoricProps.venue,
      fromDateString: toStandardDateFormat(fromDate),
      toDateString: toStandardDateFormat(toDate),
    }),
  })
    .then((res) => {
      const salesHistory = res.map((record) => {
        let salesHistoryChild = toDailyRow(record);
        // Assign values to includedInSalesHistory depending on matching criteria
        if (salesHistoryChild?.business_date !== undefined) {
          salesHistoryChild["includedInSalesHistory"] = {
            tmlyDowSales: tmlyDowSalesCriteria(
              fromStandardDateFormat(salesHistoryChild.business_date)
            ),
            tmlyMtdSales: tmlyMtdSalesCriteria(
              fromStandardDateFormat(salesHistoryChild?.business_date)
            ),
            salesTrendL6wLy: salesTrendL6wLyCriteria(
              fromStandardDateFormat(salesHistoryChild.business_date)
            ),
            toggle: true,
          };
        }
        return salesHistoryChild;
      });
      return salesHistory;
    })
    .catch((err) => console.error(err));
}

export function getMarketingHistoryRecent({ venue, fromDate, toDate }: PullHistoricPropsDateRange) {
  const selectedFromDate = fromStandardDateFormat(fromDate);
  const selectedToDate = fromStandardDateFormat(toDate);
  const selectedFromMonthStartDate = getFirstDayOfMonth(
    selectedFromDate.clone().subtract(2, "month")
  );
  const selectedToMonthEndDate = getLastDayOfMonth(selectedToDate);

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.dailyRowMarketingHistoric,
    filterByFormula: getDailyRowFilterByFormula({
      venue: venue,
      fromDateString: toStandardDateFormat(selectedFromMonthStartDate),
      toDateString: toStandardDateFormat(selectedToMonthEndDate),
    }),
  })
    .then(
      (res) =>
        res
          .filter((record) => record !== null && record !== undefined)
          .map((record) => {
            return toDailyRow(record);
          }) as DailyRow[]
    )
    .catch((err) => console.error(err));
}

export function getMarketingHistoryLy({ venue, fromDate, toDate }: PullHistoricPropsDateRange) {
  const selectedFromDate = fromStandardDateFormat(fromDate);
  const selectedToDate = fromStandardDateFormat(toDate);
  // ttly logic different from that in SalesDashboard (does not adhere to weekIndex)
  const selectedFromDateTtly = thisTimeLastYear(selectedFromDate, false);
  const selectedToDateTtly = thisTimeLastYear(selectedToDate, false);

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.dailyRowMarketingHistoric,
    filterByFormula: getDailyRowFilterByFormula({
      venue: venue,
      fromDateString: toStandardDateFormat(selectedFromDateTtly.subtract(1, "day")),
      toDateString: toStandardDateFormat(selectedToDateTtly),
    }),
  })
    .then(
      (res) =>
        res
          .filter((record) => record !== null && record !== undefined)
          .map((record) => {
            return toDailyRow(record);
          }) as DailyRow[]
    )
    .catch((err) => console.error(err));
}

export function getUserPasswordRaw(username: string) {
  return getAirtableRowsUser({
    fields: airtableRequiredFields.User.passwordRaw,
    filterByFormula: `AND(` + `{username}='${username}', ` + `{active}` + `)`,
  })
    .then((res) => {
      if (res.length === 1) {
        if (res[0].fields !== undefined && res[0].fields.password_raw !== undefined) {
          return res[0].fields.password_raw;
        } else {
          return "Field error";
        }
      } else if (res.length > 1) {
        return "More than one user";
      } else {
        return "No user found";
      }
    })
    .catch((err) => console.error(err));
}

export function getUserPasswordHash(username: string) {
  return getAirtableRowsUser({
    fields: airtableRequiredFields.User.passwordHash,
    filterByFormula: `AND(` + `{username}='${username}', ` + `{active}` + `)`,
  })
    .then((res) => {
      if (res.length === 1) {
        if (res[0].fields !== undefined && res[0].fields.password_hash !== undefined) {
          return res[0].fields.password_hash;
        } else {
          return "Field error";
        }
      } else if (res.length > 1) {
        return "More than one user";
      } else {
        return "No user found";
      }
    })
    .catch((err) => console.error(err));
}

export const getUserPermissionsDashboard = (username: string) => {
  return getAirtableRowsUser({
    fields: airtableRequiredFields.User.userPermissionsDashboard,
    filterByFormula: `AND(` + `{username}='${username}', ` + `{active}` + `)`,
  }).then((res) => {
    if (res.length === 1) {
      if (res[0].fields !== undefined && res[0].fields.permissions_dashboard !== undefined) {
        return res[0].fields.permissions_dashboard;
      } else {
        console.log("Field error");
        return [];
      }
    } else if (res.length > 1) {
      console.log("More than one user");
      return [];
    } else {
      console.log("No user found");
      return [];
    }
  });
};

export function getUserDefaultRedirect(username: string) {
  return getAirtableRowsUser({
    fields: airtableRequiredFields.User.defaultRedirect,
    filterByFormula: `AND(` + `{username}='${username}', ` + `{active}` + `)`,
  }).then((res) => {
    console.log("res", res);
    if (res.length === 1) {
      if (res[0].fields !== undefined && res[0].fields.default_redirect !== undefined) {
        return {
          redirectPath: res[0].fields.default_redirect,
          defaultVenue:
            res[0].fields.default_venue_name !== undefined
              ? res[0].fields.default_venue_name[0]
              : undefined,
        };
      } else {
        return {
          redirectPath: "user_error",
          defaultVenue: undefined,
        };
      }
    } else if (res.length > 1) {
      return {
        redirectPath: "user_error",
        defaultVenue: undefined,
      };
    } else {
      return {
        redirectPath: "no_user_found",
        defaultVenue: undefined,
      };
    }
  });
}

export function getUserAuthDetails(username: string) {
  return getAirtableRowsUser({
    fields: airtableRequiredFields.User.authDetails,
    filterByFormula: `AND(` + `{username}='${username}', ` + `{active}` + `)`,
  }).then((res) => {
    if (res.length === 1) {
      if (res[0].fields !== undefined && res[0].fields.default_redirect !== undefined) {
        return {
          passwordRaw: res[0].fields.password_raw ?? "",
          passwordHash: res[0].fields.password_hash ?? "",
          redirectPath: res[0].fields.default_redirect,
          defaultVenue:
            res[0].fields.default_venue_name !== undefined
              ? res[0].fields.default_venue_name[0]
              : undefined,
        };
      } else {
        return {
          redirectPath: "user_error",
          defaultVenue: undefined,
          passwordHash: "",
          passwordRaw: "",
        };
      }
    } else if (res.length > 1) {
      return {
        redirectPath: "user_error",
        defaultVenue: undefined,
        passwordHash: "",
        passwordRaw: "",
      };
    } else {
      return {
        redirectPath: "no_user_found",
        defaultVenue: undefined,
        passwordHash: "",
        passwordRaw: "",
      };
    }
  });
}

export const getAvailableVenues = (dashboardType?: DashboardType, username?: string) => {
  if (username) {
    return getAirtableRowsVenue({
      fields: airtableRequiredFields.Venue.venue,
      filterByFormula: getVenueFilterByFormula(dashboardType, username),
    }).then((res) =>
      res
        .map((record) => record.fields)
        .sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
    );
  } else {
    return Promise.resolve([]);
  }
};

// For updating venue because of linked field
export const getVenueRecordId = (venue: string) => {
  return getAirtableRowsVenue({
    fields: [],
    filterByFormula: `{name}='${venue}'`,
  }).then((res) => res.map((record) => record.id)[0]);
};

export const getVenueIncentiveMechanic = (venue: string) => {
  return getAirtableRowsVenue({
    fields: airtableRequiredFields.Venue.incentiveMechanic,
    filterByFormula: `{name}='${venue}'`,
  }).then(
    (res) =>
      res.map((record) => ({
        incentiveMechanic: record.fields.incentive_mechanic as IncentiveMechanic,
        incentiveSplitMechanic: record.fields.incentive_split_mechanic as IncentiveSplitMechanic,
      }))[0] ?? null
  );
};

export const getVenueMonthlyBudget = (venue: string, date: string) => {
  return getAirtableRowsBudget({
    filterByFormula: getBudgetFilterByFormula(venue, date),
  })
    .then((res) => {
      if (res.length === 1) {
        return res[0].fields.budget;
      } else {
        return 0;
      }
    })
    .catch((err) => console.error(err));
};

export function getVenueAssuranceDataDaily(venue: string, year: number) {
  let filterByParams: {
    [key: string]: string;
  } = {
    venue: venue,
    fromDateString: `${year}-01-01`,
  };

  if (moment().isAfter(moment().year(year).month(12).date(31), "day")) {
    filterByParams["toDateString"] = `${year}-12-31`;
  }

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.dataAssurance,
    filterByFormula: getDailyRowFilterByFormula(filterByParams),
  }).then((res) => res.map((record) => toDailyRow(record)));
}

export function getVenueAssuranceDataYearly(venue: string, year: number) {
  return getAirtableRowsYearlyData({
    fields: airtableRequiredFields.YearlyData.dataAssurance,
    filterByFormula: getYearlyRowFilterByFormula({
      venue: venue,
      year: year,
    }),
  }).then((res) => {
    if (res.length === 1) {
      return toDailyRow(res[0]);
    } else {
      console.log(`Error, ${res.length} records in the database`);
      return {};
    }
  });
}

export const getMonthIncentiveParams = (
  venue: string,
  year: number,
  month: number,
  excludeDailyTargets: boolean = true
) =>
  getAirtableRowsIncentiveParams({
    filterByFormula: getMonthIncentiveParamsFilterByFormula(
      venue,
      year,
      month,
      excludeDailyTargets
    ),
    fields: excludeDailyTargets
      ? airtableRequiredFields.IncentiveParams.incentiveParams
      : airtableRequiredFields.IncentiveParams.incentiveParamsWithDay,
  });

export const getDayIncentiveParams = (
  venue: string,
  date: Moment
): Promise<IncentiveStructureParameters> => {
  const monthlyParamsPromise = getMonthIncentiveParams(venue, date.year(), date.month() + 1);
  const dailyTargetPromise = getAirtableRowsIncentiveParams({
    filterByFormula: getDayIncentiveParamsFilterByFormula(venue, date),
    fields: airtableRequiredFields.IncentiveParams.incentiveParams,
  });

  return Promise.all([monthlyParamsPromise, dailyTargetPromise]).then(
    ([monthlyParamsResponse, dailyTargetResponse]) => {
      return {
        dailyTarget:
          dailyTargetResponse[0] !== undefined ? dailyTargetResponse[0].fields.input_var_value : 0,
        dailyTargetCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "dailyTargetCut"
          )?.fields.input_var_value ?? 0,
        dailyTargetBonusCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "dailyTargetBonusCut"
          )?.fields.input_var_value ?? 0,
        numberToSplit:
          monthlyParamsResponse.find((element) => element.fields.input_var_type === "numberToSplit")
            ?.fields.input_var_value ?? 0,
        monthlyTarget:
          monthlyParamsResponse.find((element) => element.fields.input_var_type === "monthlyTarget")
            ?.fields.input_var_value ?? 0,
        monthlyTargetCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "monthlyTargetCut"
          )?.fields.input_var_value ?? 0,
        monthlyTargetBonusCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "monthlyTargetBonusCut"
          )?.fields.input_var_value ?? 0,
      };
    }
  );
};

// Difference between getDayFullMonthIncentiveParams and getDayIncentiveParams:
//   getDayFullMonthIncentiveParams provides the daily target for every day in the month
export const getDayFullMonthIncentiveParams = (
  venue: string,
  date: Moment
): Promise<IncentiveStructureParameters> =>
  getMonthIncentiveParams(venue, date.year(), date.month() + 1, false).then(
    (monthlyParamsResponse) => {
      let wholeMonthDailyTargets: {
        [key: string]: number;
      } = {};
      let dailyTarget = 0;

      monthlyParamsResponse
        .filter((record) => record.fields.input_var_type === "dailyTarget")
        .forEach((record) => {
          const dateString = toStandardDateFormat(date.clone().date(record.fields.day ?? 0)) ?? "";
          wholeMonthDailyTargets[dateString] = record.fields.input_var_value;

          if (date.date() === record.fields.day) {
            dailyTarget = record.fields.input_var_value;
          }
        });

      const output = {
        dailyTarget,
        wholeMonthDailyTargets: wholeMonthDailyTargets,
        dailyTargetCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "dailyTargetCut"
          )?.fields.input_var_value ?? 0,
        dailyTargetBonusCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "dailyTargetBonusCut"
          )?.fields.input_var_value ?? 0,
        numberToSplit:
          monthlyParamsResponse.find((element) => element.fields.input_var_type === "numberToSplit")
            ?.fields.input_var_value ?? 0,
        monthlyTarget:
          monthlyParamsResponse.find((element) => element.fields.input_var_type === "monthlyTarget")
            ?.fields.input_var_value ?? 0,
        monthlyTargetCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "monthlyTargetCut"
          )?.fields.input_var_value ?? 0,
        monthlyTargetBonusCut:
          monthlyParamsResponse.find(
            (element) => element.fields.input_var_type === "monthlyTargetBonusCut"
          )?.fields.input_var_value ?? 0,
      };

      return output;
    }
  );

export const getMtdDailySales = (venue: string, date: Moment) => {
  const dateRange = getMtd(date);

  return getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.netSalesWithServiceChargeCalc,
    filterByFormula: getDailyRowFilterByFormula({
      venue: venue,
      fromDateString: toStandardDateFormat(dateRange.from),
      toDateString: toStandardDateFormat(dateRange.to),
    }),
  });
};

// Net sales with service charge
export const getDailySalesSince = (venue: string, fromDate: Moment) =>
  getAirtableRowsRawData({
    fields: airtableRequiredFields.RawData.netSalesWithServiceChargeCalc,
    filterByFormula: getDailyRowFilterByFormula({
      venue: venue,
      fromDateString: toStandardDateFormat(fromDate.clone().subtract(1, "day")),
      toDateString: toStandardDateFormat(moment()),
    }),
  });

export const getMtdPotAdditions = (venue: string, date: Moment) => {
  return getAirtableRowsIncentiveLocked({
    fields: airtableRequiredFields.IncentiveLocked.potAdditions,
    filterByFormula: getMonthIncentiveLockedFilterByFormula(venue, date),
  });
  // .then((res) => res.map((record) => record as Record<IncentiveVars>));
};

export const getDailyIncentiveLog = (venue: string, date: Moment) => {
  return getAirtableRowsIncentiveLocked({
    // Get all fields
    filterByFormula: getDayIncentiveLockedFilterByFormula(venue, date),
  });
  // .then((res) => res.map((record) => record as Record<IncentiveVars>));
};

export const getOccasions = (year: number, month: number) => {
  return getAirtableRowsOccasion({
    filterByFormula: `AND({year}=${year}, {month}=${month})`,
    fields: airtableRequiredFields.Occasion.occasionInfo,
  }).then((res) =>
    res.map((holidayRecord) => {
      return {
        date: fromStandardDateFormat(holidayRecord.fields.date),
        occasionName: holidayRecord.fields.occasionName,
        type: holidayRecord.fields.type,
      };
    })
  );
};

export const addIncentiveParam = (incentiveParam: IncentiveParam) => {
  const incentiveParamStringVal: {
    [key: string]: any;
  } = {};

  Object.keys(incentiveParam).forEach((key) => {
    incentiveParamStringVal[key] =
      key === "venue"
        ? [incentiveParam[key as keyof IncentiveParam] as string]
        : (incentiveParam[key as keyof IncentiveParam] as string);
  });

  return addAirtableRowsIncentiveParams({
    data: incentiveParamStringVal,
  }).then(
    (result) =>
      ({
        rowsAddAttempt: 1,
        rowsAdded: result.id !== undefined ? 1 : 0,
      } as DatabaseUpdateResponse)
  );
};

export const updateIncentiveParam = (recordId: string, incentiveParam: IncentiveParam) => {
  const incentiveParamStringVal: {
    [key: string]: any;
  } = {};

  Object.keys(incentiveParam).forEach((key) => {
    incentiveParamStringVal[key] =
      key === "venue"
        ? [incentiveParam[key as keyof IncentiveParam] as string]
        : (incentiveParam[key as keyof IncentiveParam] as string);
  });

  return updateAirtableRowsIncentiveParams({
    rowId: recordId,
    data: incentiveParamStringVal,
  }).then(
    (result) =>
      ({
        rowsUpdateAttempt: 1,
        rowsUpdated: result.id === recordId ? 1 : 0,
      } as DatabaseUpdateResponse)
  );
};

export const addBug = (bug: Bug) =>
  addAirtableRowBug({
    data: {
      type: bug.bugType ?? "",
      description: bug.bugDescription ?? "",
      date: toStandardDateTimeFormat(bug.date ?? moment()),
    },
  });

export const getAdhocInputByDateRange = (
  valueType: AdhocInputValueType,
  venueCode: string,
  startDate: Moment,
  endDate: Moment
): Promise<{
  adhocInput: {
    [key: string]: number;
  };
  lastUpdated: Moment;
}> =>
  getAdhocInput({
    filterByFormula: getAdhocInputFilterByFormula(venueCode, startDate, endDate, valueType),
  }).then((records) => {
    let output: {
      [key: string]: number;
    } = {};
    let lastUpdated: Moment = moment.max(
      records.map((record) => fromStandardDateTimeFormat(record.fields.lastUpdated))
    );
    records.forEach((record) => {
      output[record.fields.date] = record.fields.value;
    });

    return {
      adhocInput: output,
      lastUpdated,
    };
  });

export const addAdhocInput = (adhocInput: AdhocDataInput) => {
  const adhocInputStringVal: {
    [key: string]: any;
  } = {};

  Object.keys(adhocInput).forEach((key) => {
    adhocInputStringVal[key] =
      key === "venue"
        ? [adhocInput[key as keyof AdhocDataInput] as string]
        : (adhocInput[key as keyof AdhocDataInput] as string);
  });

  return addAirtableRowAdhocInput({
    data: adhocInputStringVal,
  }).then(
    (result) =>
      ({
        rowsAddAttempt: 1,
        rowsAdded: result.id !== undefined ? 1 : 0,
      } as DatabaseUpdateResponse)
  );
};

export const updateAdhocInput = (recordId: string, adhocInput: AdhocDataInput) => {
  const adhocInputStringVal: {
    [key: string]: any;
  } = {};

  Object.keys(adhocInput).forEach((key) => {
    adhocInputStringVal[key] =
      key === "venue"
        ? [adhocInput[key as keyof AdhocDataInput] as string]
        : (adhocInput[key as keyof AdhocDataInput] as string);
  });

  return updateAirtableRowAdhocInput({
    rowId: recordId,
    data: adhocInputStringVal,
  }).then((result) => ({
    rowsUpdateAttempt: 1,
    rowsUpdated: result.id === recordId ? 1 : 0,
  }));
};

export const addOpenArmsRecognition = (recognitionData: RecognitionData) =>
  addAirtableRowOpenArmsRecognition({
    data: {
      ...recognitionData,
      timestamp: toStandardDateTimeFormat(moment()),
    },
  }).then((result) => Boolean(result.id));

export const getOpenArmsRecognition = (
  fromDate?: Moment,
  toDate?: Moment
): Promise<RecognitionData[]> => {
  const dayBeforeFromDate = fromDate && fromDate.clone().subtract(1, "day");
  const dayAfterToDate = toDate && toDate.clone().add(1, "day");

  const stringFilterArgs = [
    fromDate ? `IS_AFTER({timestamp}, DATETIME_PARSE("${dayBeforeFromDate}"), "days")` : null,
    toDate ? `IS_BEFORE({timestamp}, DATETIME_PARSE("${dayAfterToDate}"), "days")` : null,
    `NOT({isTestData})`,
  ]
    .filter((arg) => arg !== null)
    .join(",");

  return getAirtableRowOpenArmsRecognition({
    filterByFormula: `AND(${stringFilterArgs})`,
    sort: [
      {
        field: "timestamp",
        direction: "desc",
      },
    ],
  }).then((res) => res.map((rec) => rec.fields));
};
