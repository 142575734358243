import React from 'react'; 
import styled from 'styled-components'; 
import { Option } from '../../typings/common';
import { ArrowFont } from '../StandardComponents';

const Wrapper = styled.div`
  display: block; 
  position: relative; 
`

const PopupMenu = styled.div`
  position: absolute; 
  top: 32px; 
  left: 0px; 
  border-radius: 3px; 
  background-color: #ffffff; 
  animation: fadeIn ease 0.4s; 
  padding: 8px 12px; 
  display: flex; 
  flex-direction: column;
  width: 180px; 
  flex: 1; 
  z-index: 2000; 
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.05);
  :hover {
    cursor: default; 
  }
`

const DisplayedText = styled.p`
  font-size: 14px; 
  color: #273561;
  font-weight: 500; 
  text-align: left; 
  :hover {
    cursor: pointer; 
  }
  margin: 0px; 
`

const OptionItem = styled.a<{ selected?: boolean }>`
  display: inline; 
  color: ${({ selected }) => selected ? "#273561" : "#646C77"}; 
  font-weight: ${({ selected }) => selected ? "600" : "400"}; 
  font-size: 14px;
  text-align: left; 
  padding: 8px; 
  outline: none; 
  text-decoration: none; 
  :hover {
    cursor: pointer; 
  }
`


type SelectFieldTextProps = {
  id: string, 
  options: Option[], 
  selected: string, 
  changeHandler: (id: string, value: string) => void, 
  displayTextTransformer: (selectedValue: string) => string
}

const SelectFieldText: React.FC<SelectFieldTextProps> = ({
  id, 
  options, 
  selected, 
  changeHandler, 
  displayTextTransformer
}) => {
  const [ isOptionMenuOpen, setIsOptionMenuOpen ] = React.useState<boolean>(false); 

  React.useEffect(() => {
    const close = () => {
      setIsOptionMenuOpen(false); 
    }; 
    
    if (isOptionMenuOpen) {
      window.addEventListener("click", close); 
    }
    
    return () => {
      window.removeEventListener("click", close); 
    }
  }, [isOptionMenuOpen]); 

  return (
    <Wrapper>
      <DisplayedText onClick={() => setIsOptionMenuOpen(true)}>
        {
          displayTextTransformer(selected)
        } 
        <ArrowFont>
          { isOptionMenuOpen ? "▲" : "▼" }
        </ArrowFont>
      </DisplayedText>
      {
        isOptionMenuOpen && 
          <PopupMenu>
            {
              options.map((option) => (
                <OptionItem
                  onClick={() => {
                    setIsOptionMenuOpen(false); 
                    changeHandler(id, option.value ?? ""); 
                  }}
                  selected={selected === option.value}>
                  { option.label }
                </OptionItem>
              ))
            }
          </PopupMenu>
      }
    </Wrapper>
  )
}

export default SelectFieldText