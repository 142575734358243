import React from "react"
import { DeltaProps } from "../../typings/common"
// import TrendingUpIcon from "@material-ui/icons/TrendingUp"
// import TrendingDownIcon from "@material-ui/icons/TrendingDown"
// import TrendingFlatIcon from "@material-ui/icons/TrendingFlat"
import styled from "styled-components"
import { ArrowFont } from "../StandardComponents"

const NegativeWrapper = styled.div`
  text-align: right; 
  padding-top: 2px; 
  height: 16px;
  font-size: 12px; 
  font-weight: 500; 
  color: #D02A2A; 
`

const PositiveWrapper = styled.div`
  text-align: right; 
  font-size: 12px; 
  font-weight: 500; 
  padding-top: 2px; 
  height: 16px; 
  color: #7FC6A4; 
`

const NeutralWrapper = styled.div`
  text-align: right; 
  font-size: 12px; 
  font-weight: 500; 
  padding-top: 2px; 
  height: 16px; 
`

const Delta: React.FC<DeltaProps> = ({
  deltaDirection, 
  deltaText
}) => {

  if (deltaDirection === "+") {
    return (
      <PositiveWrapper>
        <ArrowFont>{"▲"}</ArrowFont>
        {deltaText}
      </PositiveWrapper>
    )
  }
  else if (deltaDirection === "-") {
    return (
      <NegativeWrapper>
         <ArrowFont>{"▼"}</ArrowFont>
        {deltaText}
      </NegativeWrapper>
    )
  }
  else {
    return (
      <NeutralWrapper>
        {deltaText}
      </NeutralWrapper>
    )
  }
}

export default Delta; 