import React from 'react'; 
import Highcharts, { AxisLabelsFormatterContextObject, TooltipFormatterContextObject } from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official'; 
import VisPalette from '../../utils/visPalette';
import { formatCurrency } from '../../utils/currency';
import { Moment } from 'moment';
import { DailyRow } from '../../typings/common';
import { toHumanDateFormatWithDow, toStandardDateFormat } from '../../utils/date';
import { ChartWrapper } from '../StandardComponents';
import { safeMax } from '../../utils/globals';

// Follow https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/line-ajax

type LineChartProps = {
  seriesName: string,
  lineChartData: LineSeries[],
  valueIsCurrency: boolean, 
  multipleTimePeriod?: boolean,
  // True = All LineSeries in lineChartData are looking at 
  // the same value type across different time, 
  // i.e. Sales data (2019), Sales data (2020)
  // False = All LineSeries in lineChartData are looking at 
  // the different type across same time, 
  // i.e. Dine-in, Delivery, Take-out across same time
  daysToTrack: number, 
  metricToPlot: keyof DailyRow, 
  upperBoundToDisplayLogic?: (arg0: number[]) => number
  // Computes the ceiling for data points that will be displayed
  // i.e. Any points above this point might be truncated
}

type LineSeries = {
  lineSeriesName: string, 
  rawLineSeriesDailyRowData: DailyRow[], 
  metricToPlot?: keyof DailyRow
  lastDate: Moment, 
  daysToTrack?: number
}

const convertLineSeriesDailyRowDataToArray = (
  dailyRows: DailyRow[], 
  lastDate: Moment, 
  daysToTrack: number, 
  metricToPlot: keyof DailyRow, 
) => {

  let output: number[] = []; 
  for (let i = lastDate.clone().subtract(daysToTrack-1, "days"); 
    i.isSameOrBefore(lastDate); 
    i.add(1, "day")) {

    const dailyRow = dailyRows.find((dailyRow) => dailyRow.business_date === toStandardDateFormat(i)); 
    if (dailyRow !== undefined && dailyRow[metricToPlot] !== undefined) {
      output.push(dailyRow[metricToPlot] as number)
    }
    else {
      output.push(0)
    }
  }

  return output
}

const getLineChartDataOptions = ({ 
  lineChartData, 
  multipleTimePeriod, 
  daysToTrack, 
  valueIsCurrency, 
  metricToPlot, 
  upperBoundToDisplayLogic
}: LineChartProps) => {

  const lastDates: { [key: string]: any } = {}; 
  lineChartData.forEach((lineSeries) => {
    lastDates[lineSeries.lineSeriesName] = lineSeries.lastDate; 
  }); 

  function tooltipFormatter(this: TooltipFormatterContextObject) {
    const humanDateWithDow = toHumanDateFormatWithDow(
      lastDates[this.series.name].clone().subtract(daysToTrack-1 - this.x, "days")
      ); 
    const secondLineText = valueIsCurrency ? 
      `${formatCurrency(this.y)} ` : 
      `${this.y}`; 
    return `<b>${humanDateWithDow}</b><br/>${secondLineText}`
  }

  function xAxisLabelFormatter(this: AxisLabelsFormatterContextObject<number>) {
    const pointDate = lineChartData[0].lastDate
      .clone().subtract(daysToTrack-1 - this.value, "days"); 
    
    return pointDate.date() === 1 ? pointDate.format("MMM") : ""; 
  }

  const lineSeriesData = lineChartData.map((lineSeries, index) => ({
    name: lineSeries.lineSeriesName, 
    data: convertLineSeriesDailyRowDataToArray(
      lineSeries.rawLineSeriesDailyRowData, 
      lineSeries.lastDate, 
      daysToTrack, 
      metricToPlot
      ), 
    dashStyle: multipleTimePeriod ? 
      (index === 0 ? 
        "Solid" : 
        "ShortDash") : 
      "Solid", 
    color: multipleTimePeriod ? 
      (index === 0 ? 
        VisPalette[0] : 
        VisPalette[0] + "80") : 
      VisPalette[index]
  })); 

  let upperBoundToBeDisplayed = undefined; 
  if (upperBoundToDisplayLogic) {
    // Upper bounds for each series
    const upperBounds = lineSeriesData
      .map((lineSeries) => lineSeries.data)
      .map((lineSeriesDataArray) => upperBoundToDisplayLogic(lineSeriesDataArray))

    upperBoundToBeDisplayed = safeMax(upperBounds); 
    console.log(upperBoundToBeDisplayed); 
  }

  const output = {
    chart: {
      type: 'line', 
      backgroundColor: "#F6F7FB", 
      borderRadius: '8', 
      height: '320px', 
      spacing: [ 24, 24, 0, 18 ]
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }, 
      gridlineWidth: 0, 
      min: 0, 
      ceiling: upperBoundToBeDisplayed
    },
    xAxis: {
      tickInterval: 1, 
      tickLength: 0, 
      labels: {
        enabled: true, 
        step: 1, 
        formatter: xAxisLabelFormatter
      }
    },
    lineColor: null, 
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom', 
      x: 0, 
      y: 0
    },
    tooltip: {
      formatter: tooltipFormatter
    }, 
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }, 
        lineWidth: 1.7,
        pointStart: 0, 
        marker: {
          enabled: false
        }
      }
    },
    series: lineSeriesData,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }, 
    credits: {
      enabled: false
    }
  }; 

  return output
}

const HcLineChart: React.FC<LineChartProps> = (lineChartProps) => {
  const lineChartDataOptions = getLineChartDataOptions(lineChartProps); 
  return (
    <ChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={lineChartDataOptions} />
    </ChartWrapper>
  ); 
}

export default HcLineChart; 