import React from 'react'; 
import Highcharts, { AxisLabelsFormatterContextObject, TooltipFormatterContextObject } from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official'; 
import VisPalette from '../../utils/visPalette';
import { formatCurrency, formatPercentage, getDeltaPercentageText } from '../../utils/currency';
import { Moment } from 'moment';
import { DailyRow } from '../../typings/common';
import { toHumanDateFormatWithDow, toStandardDateFormat } from '../../utils/date';
import { ChartWrapper } from '../StandardComponents';
import { safeMax } from '../../utils/globals';

// Follow https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/line-ajax

type LineChartProps = {
  seriesData: {
    [key: string]: DataPoint[]
  },
  multipleTimePeriod?: boolean,
  // True = All LineSeries in seriesData are looking at 
  // the same value type across different time, 
  // i.e. Sales data (2019), Sales data (2020)
  // False = All LineSeries in seriesData are looking at 
  // the different type across same time, 
  // i.e. Dine-in, Delivery, Take-out across same time
  upperBoundToDisplayLogic?: (arg0: number[]) => number, 
  height?: number,
  // Computes the ceiling for data points that will be displayed
  // i.e. Any points above this point might be truncated
  customTooltipFormatter?: (this: TooltipFormatterContextObject) => string
}

export type DataPoint = {
  pointLabel: string, 
  xAxisLabel?: string, 
  value: number
}

export const generateTimeSeriesSalesDataPointLabel = (
  dateString: string, 
  value: number, 
  valueIsCurrency: boolean, 
  previousPeriodValue?: number, 
  deltaSuffixText: string = 'vs. last year', 
) => (
  previousPeriodValue !== undefined ? 
    `<b>${dateString}</b><br/>${ valueIsCurrency ? formatCurrency(value) : value }<br/>${getDeltaPercentageText(previousPeriodValue, value)} ${deltaSuffixText}` : 
    `<b>${dateString}</b><br/>${ valueIsCurrency ? formatCurrency(value) : value }`
)

const getSeriesDataOptions = ({ 
  seriesData, 
  multipleTimePeriod, 
  upperBoundToDisplayLogic, 
  height
}: LineChartProps) => {

  function tooltipFormatter(this: TooltipFormatterContextObject) {
    // const firstLineText = seriesData[this.series.name][this.x].pointLabel; 
    // const secondLineText = valueIsCurrency ? 
    //   `${formatCurrency(this.y)} ` : 
    //   `${this.y}`; 
    // return `<b>${firstLineText}</b><br/>${secondLineText}`
    return seriesData[this.series.name][this.x].pointLabel; 
  }

  function xAxisLabelFormatter(this: AxisLabelsFormatterContextObject<number>) {
    return seriesData[Object.keys(seriesData)[0]][this.pos] ? 
      seriesData[Object.keys(seriesData)[0]][this.pos].xAxisLabel : 
      ""; 
  }

  const lineSeriesData = Object.keys(seriesData).map((seriesName, index) => ({
    name: seriesName, 
    data: seriesData[seriesName].map((dataPoint) => dataPoint.value), 
    dashStyle: multipleTimePeriod ? 
      (index === 0 ? 
        "Solid" : 
        "ShortDash") : 
      "Solid", 
    color: multipleTimePeriod ? 
      (index === 0 ? 
        VisPalette[0] : 
        VisPalette[0] + "80") : 
      VisPalette[index]
  })); 

  let upperBoundToBeDisplayed = undefined; 
  if (upperBoundToDisplayLogic) {
    // Upper bounds for each series
    const upperBounds = lineSeriesData
      .map((lineSeries) => lineSeries.data)
      .map((lineSeriesDataArray) => upperBoundToDisplayLogic(lineSeriesDataArray))

    upperBoundToBeDisplayed = safeMax(upperBounds); 
  }

  const output = {
    chart: {
      type: 'line', 
      backgroundColor: "#F6F7FB", 
      borderRadius: '8', 
      height: `${ height ?? 320 }px`, 
      spacing: [ 36, 24, 0, 18 ]
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }, 
      gridlineWidth: 0, 
      min: 0, 
      ceiling: upperBoundToBeDisplayed
    },
    xAxis: {
      tickInterval: 1, 
      tickLength: 0, 
      labels: {
        enabled: true, 
        step: 1, 
        formatter: xAxisLabelFormatter
      }
    },
    lineColor: null, 
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom', 
      y: -24, 
      padding: 4
    },
    tooltip: {
      formatter: tooltipFormatter
    }, 
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        }, 
        lineWidth: 1.7,
        pointStart: 0, 
        marker: {
          enabled: false
        }
      }
    },
    series: lineSeriesData,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }, 
    credits: {
      enabled: false
    }
  }; 

  return output
}

const HcLineChart: React.FC<LineChartProps> = (lineChartProps) => {
  const seriesDataOptions = getSeriesDataOptions(lineChartProps); 
  return (
    <ChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={seriesDataOptions} />
    </ChartWrapper>
  ); 
}

export default HcLineChart; 