import React from 'react'; 
import { DailyMetric, Dashboard, TlbgConcept, Venue, YearMonth } from '../../typings/common';
import moment, { Moment } from 'moment'; 
import { getAvailableVenues } from '../../services/AirtablePlus';
import { defaultVenue, loggedInUser, TlbgConceptCode } from '../../utils/globals';
import { toHumanDateTimeFormat, toHumanMonthYearString, toStandardDateTimeFormat } from '../../utils/date';
import { getSalesInputDataMonthly, pushSalesInputDataMonthly } from './SalesInputController';
import LoadingOverlay from "react-loading-overlay"; 
import { 
  ChangesSavedStatusIndicator,
  DesktopLayout, 
  FailedAlert, 
  FlexRow, 
  GrayPanel, 
  HeadlineText, 
  Left, 
  Loading, 
  Right, 
  SaveButton, 
  SectionHeaderNew as SectionHeader, 
  SectionSubHeader, 
  Text as OgText
} from '../StandardComponents';
import styled from 'styled-components';
import IncentivesDashboardHeader from '../IncentivesDashboardHeader';
import MainHeader from '../MainHeader';
import ValueCalendar from '../SalesTargetCalendar';

type SalesInputDashboardProps = {
  venue?: string;
  year: number; 
  month: number; 
}

export type DailySalesData = {
  [key: string]: number; // key: day, val: incentive metrics
}

const SalesInputDashboard: React.FC<SalesInputDashboardProps> = (props) => {
  const [ loading, setLoading ] = React.useState<boolean>(false); 
  const [ availableOptions, setAvailableOptions ] = React.useState<{
    venues: Venue[], 
    months: YearMonth[]
  }>(); 
  const [ dailySalesData, setDailySalesData ] = React.useState<DailyMetric>(); 
  const [ lastUpdated, setLastUpdated ] = React.useState<Moment>(); 
  const [ selection, setSelection ] = React.useState<SalesInputDashboardProps>({
		venue: "",
		year: moment().year(),
		month: moment().month() + 1,
	}); 
  const [ changesSavedStatus, setChangesSavedStatus ] = React.useState<boolean>(true);
	const [ showUnsavedWarning, setShowUnsavedWarning ] =	React.useState<boolean>(false); 

  const handleDateChange = (yearMonthString: string) => {
    const date = moment(yearMonthString, "YYYY-MM");

    setSelection({
      ...selection,
      year: date.year(),
      month: date.month() + 1,
    });
  };

  const dailySalesCalendarChangeHandler = (id: number, input: number) => {
		if (dailySalesData) {
			setDailySalesData({
				...dailySalesData,
				[id]: input
			});
		}
		setChangesSavedStatus(false);
	};

  const saveHandler = () => {
		if (dailySalesData) {
			setLoading(true);

      // // TODO
			pushSalesInputDataMonthly(
        dailySalesData, 
        selection.venue ?? "", 
        selection.year, 
        selection.month, 
        loggedInUser()
      )
      .then((databaseUpdateResponse) => {
        if (
          databaseUpdateResponse.rowsAddAttempt === 
          databaseUpdateResponse.rowsAdded && 
          databaseUpdateResponse.rowsUpdateAttempt === 
          databaseUpdateResponse.rowsUpdated 
        ) {
          setChangesSavedStatus(true); 
          setShowUnsavedWarning(false); 
        }
        else {
          setShowUnsavedWarning(true); 
        }
        setLoading(false); 
      }); 

		} else {
			console.log("dailySalesData is undefined");
		}
	}; 

  async function fetchOptions(): Promise<void> {
    const availableVenueResponse = await getAvailableVenues(
      Dashboard.salesInput, 
      loggedInUser() ?? undefined
    ); 
    const thisYear = moment().year(); 
    const thisMonth = moment().month(); 
    const availableMonths = Array.from(Array(18).keys()).map((val, i) => {
      const currMonth = (thisMonth + i)%12; 
      const currYear = thisYear + ((thisMonth + i - currMonth)/12); 

      return {
        year: currYear, 
        month: currMonth
      }
    }); 

    const availableOptionsTemp = {
      venues: availableVenueResponse, 
      months: availableMonths
    }; 

    setAvailableOptions(availableOptionsTemp); 
    setLoading(false); 
  }

  const monthYearString =
		selection?.year && selection?.month
			? toHumanMonthYearString(
					moment()
						.year(selection.year)
						.month(selection.month - 1)
			  )
			: ""; 

  React.useEffect(() => {
    setLoading(true); 

    if (defaultVenue()) {
      setSelection({
        venue: defaultVenue(), 
        year: moment().year(), 
        month: moment().month() + 1
      })
    }

    fetchOptions();
  }, []); 

  React.useEffect(() => {
    if (
      selection && 
      selection.month &&
      selection.year && 
      selection.venue
    ) {

      setLoading(true); 

      getSalesInputDataMonthly(
        TlbgConceptCode[selection.venue as TlbgConcept], 
        selection.year, 
        selection.month
      )
      .then((response) => {
        console.log(response); 
        setDailySalesData(response.adhocInput); 
        setLastUpdated(response.lastUpdated); 
        setLoading(false); 
      }); 
    }
  }, [ selection ]); 

  return (
		<LoadingOverlay
			active={loading}
			spinner={Loading}
			styles={{
				overlay: (base: any) => ({
					...base,
					background: "rgba(100, 108, 119, 0.2)",
					zIndex: 2000,
					display: "flex",
					flex: 1,
					alignItems: "stretch",
					overflow: "hidden",
				}),
			}}
		>
			<Wrapper>
				<IncentivesDashboardHeader
					venueOptions={availableOptions?.venues}
					venueChangeHandler={(selectedVenue) =>
						setSelection({
							...selection,
							venue: selectedVenue,
						})
					}
					venueSelected={selection.venue}
					venuePlaceholder="Select venue"
					dateType="month"
					monthChangeHandler={handleDateChange}
					monthOptions={availableOptions?.months}
					monthSelected={{
						year: selection.year,
						month: selection.month,
					}}
					menuOuterContainerId="outer-container"
					menuSelectedItem="SalesDataInput"
					context={Dashboard.salesInput}
					outerContainerId="outer-container"
				/>

				<DesktopLayout>
					<FlexRow marginBottom={16}>
						<Left zIndex={1001}>
							<HeadlineText>Sales data input (manual)</HeadlineText>
						</Left>
						<MainHeader
							secondaryLogo={selection?.venue as TlbgConcept}
							isFixed={false}
						/>
						<Right zIndex={1001}>
							<Text>
								Last updated:{" "}
								{lastUpdated && toHumanDateTimeFormat(lastUpdated)}
							</Text>
						</Right>
					</FlexRow>
					{showUnsavedWarning && (
						<FailedAlert>
							Error saving your changes. Please contact administrator.
						</FailedAlert>
					)}
					<FlexRow>
						<GrayPanel>
							<SectionHeader>
								Actual daily sales input - {monthYearString}
							</SectionHeader>
							<SectionSubHeader>
								Daily sales figures are defined as: Net sales (after any
								discount given) + service charge, excluding GST
							</SectionSubHeader>

							<ValueCalendar
								year={selection.year}
								month={selection.month}
								changeHandler={dailySalesCalendarChangeHandler}
								dailyValues={dailySalesData}
							/>
						</GrayPanel>
					</FlexRow>

					<BottomRightSection>
						<ChangesSavedStatusIndicator status={changesSavedStatus} />

						<SaveButton onClick={saveHandler}>Save</SaveButton>
					</BottomRightSection>
				</DesktopLayout>
			</Wrapper>
		</LoadingOverlay>
	); 
}

export default SalesInputDashboard;


const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const Text = styled(OgText)`
	margin: 4px 0px;
`;

const BottomRightSection = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-end;
`;
