import {
  DailyIncentiveLog,
  DailyRow,
  IncentiveMechanic,
  IncentiveStructureParameters,
  IncentiveVars,
  MtdSalesForIncentiveCalc,
} from "../typings/common";
import { fromStandardDateFormat, fromToMoment, toStandardDateFormat } from "./date";

import { Moment } from "moment";
import moment from "moment";
import { safeSum } from "./globals";

export const computeDayEndState = (
  incentiveMechanic: IncentiveMechanic,
  incentiveStructureParams: IncentiveStructureParameters,
  startState: IncentiveVars,
  dailySales: number
): IncentiveVars => {
  if (incentiveMechanic === "monthlyPotWithDailyAddition") {
    const salesExceedDailyTarget =
      incentiveStructureParams.dailyTarget !== 0 &&
      dailySales >= (incentiveStructureParams.dailyTarget ?? 0);

    const preTargetPortion = Math.min(incentiveStructureParams.dailyTarget ?? 0, dailySales);
    const postTargetPortion = Math.max(0, dailySales - (incentiveStructureParams.dailyTarget ?? 0));

    const additionPreTargetPortion = salesExceedDailyTarget
      ? preTargetPortion * (incentiveStructureParams.dailyTargetCut ?? 0)
      : 0;

    const additionPostTargetPortion = salesExceedDailyTarget
      ? postTargetPortion * (incentiveStructureParams.dailyTargetBonusCut ?? 0)
      : 0;

    const monthlySales = (startState.monthlySales ?? 0) + dailySales;
    const potAfterAddition =
      (startState.potAfterAddition ?? 0) + additionPreTargetPortion + additionPostTargetPortion;

    return {
      potBeforeAddition: startState.potAfterAddition,
      potAfterAddition,
      monthlySales,
      isPotUnlocked: monthlySales > (incentiveStructureParams.monthlyTarget ?? 0),
      potReward: potAfterAddition / (incentiveStructureParams.numberToSplit ?? -1),
      dailySales,
      additionPreTargetPortion,
      additionPostTargetPortion,
      preTargetPortion,
      postTargetPortion,
    };
  } else {
    return {
      potAfterAddition: -1,
      monthlySales: -1,
      isPotUnlocked: false,
      potReward: -1,
    };
  }
};

export const initialIncentiveState = (): IncentiveVars => ({
  potAfterAddition: 0,
  monthlySales: 0,
  isPotUnlocked: false,
  potReward: 0,
});

export const aggregateMtdSalesForIncentiveCalc = (
  mtdSalesArray: MtdSalesForIncentiveCalc[],
  date: Moment
): MtdSalesForIncentiveCalc => {
  let dailySales: {
    [key: string]: number;
  } = {};

  fromToMoment(date.clone().startOf("month"), date.clone()).forEach((iterDate) => {
    const iterDateString = toStandardDateFormat(iterDate);
    dailySales[iterDateString] = safeSum(
      mtdSalesArray.map((mtdSalesItem) => mtdSalesItem.dailySales[iterDateString] ?? 0)
    );
  });

  return {
    dailySales,
    currDaySales: safeSum(mtdSalesArray.map((mtdSalesItem) => mtdSalesItem.currDaySales)),
    mtdSales: safeSum(mtdSalesArray.map((mtdSalesItem) => mtdSalesItem.mtdSales)),
  };
};

export const monthDailyRowsToMtdSalesForIncentiveCalc = (
  dailyRows: DailyRow[], // DailyRows in the month
  date: Moment
): MtdSalesForIncentiveCalc => {
  let dailySales: {
    [key: string]: number;
  } = {};
  let mtdSales = 0,
    currDaySales = 0;

  dailyRows.forEach((dailyRow) => {
    const iterDate = fromStandardDateFormat(dailyRow.business_date ?? "");
    if (iterDate.isSameOrBefore(date, "day")) {
      dailySales[dailyRow.business_date ?? ""] = dailyRow.net_sales_with_service_charge ?? 0;
      mtdSales += dailyRow.net_sales_with_service_charge ?? 0;
      if (iterDate.isSame(date, "day")) {
        currDaySales = dailyRow.net_sales_with_service_charge ?? 0;
      }
    }
  });

  return {
    currDaySales,
    mtdSales,
    dailySales,
  };
};

export const computeMtdEndState = (
  mtdSalesForIncentiveCalc: MtdSalesForIncentiveCalc,
  incentiveStructureParams: IncentiveStructureParameters,
  date: Moment
): DailyIncentiveLog => {
  let incentiveState = initialIncentiveState();

  // Compute daily pot addition
  fromToMoment(date.clone().startOf("month"), date.clone()).forEach((iterDate) => {
    const dateString = toStandardDateFormat(iterDate) ?? "";
    let dayIncentiveStructureParams: IncentiveStructureParameters = {
      ...incentiveStructureParams,
    };

    if (incentiveStructureParams.dailyTargets) {
      dayIncentiveStructureParams = {
        ...dayIncentiveStructureParams,
        dailyTarget: incentiveStructureParams.dailyTargets[iterDate.date()],
      };
    }
    incentiveState = computeDayEndState(
      "monthlyPotWithDailyAddition", // Only this is supported for now
      dayIncentiveStructureParams,
      incentiveState,
      mtdSalesForIncentiveCalc.dailySales[dateString] ?? 0
    );
  });

  return {
    ...incentiveStructureParams,
    ...incentiveState,
    lastUpdated: moment(),
  };
};
