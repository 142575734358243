import React, { KeyboardEventHandler } from 'react'; 
import styled, { StyledComponent } from 'styled-components'; 
import { ValueTransformer } from '../typings/common';
import { formatNumber } from '../utils/globals';
import { FieldInput, FieldInputNumeric } from './StandardComponents'; 
import PropTypes from 'prop-types'; 

type NumericFieldProps = {
  value?: number, 
  disabled?: boolean, 
  valueTransformer?: ValueTransformer, 
  onChange?: (arg0: React.ChangeEvent<HTMLInputElement>) => void, 
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>, 
  onBlur?: (arg0: React.ChangeEvent<HTMLInputElement>) => void
}

const NumericField: React.FC<NumericFieldProps> = ({
  value, 
  disabled, 
  valueTransformer, 
  onChange, 
  onKeyDown, 
  onBlur
}) => {
  const editableFieldRef = React.useRef<HTMLInputElement>(null); 
  const [ isEditing, setIsEditing ] = React.useState<boolean>(false); 

  React.useEffect(() => {
    if (
      isEditing && 
      editableFieldRef && 
      editableFieldRef.current
    ) {
      editableFieldRef.current.focus(); 
      // Makes editable field focus so onBlur can be triggered later
    }
  }, [isEditing])

  return (
    <>
      {
        isEditing ?
          <FieldInputNumeric 
            value={value}
            onChange={onChange}
            onBlur={() => {
              setIsEditing(false); 
            }}
            disabled={disabled}
            ref={editableFieldRef}
            /> : 
          <FieldInput
            type="text"
            value={(value !== undefined) ? formatNumber(value) : ""}
            onFocus={() => {
              setIsEditing(true); 
            }}
            disabled={disabled}
            onKeyDown={onKeyDown}
            />
      }
    </>
  ); 
}

export default NumericField; 