import { entries } from 'd3';
import { AggregationFunction, DailyRow, DataAssuranceCol, YearlyRow } from '../typings/common'; 
import { aggregateDailyRows } from './dailyRow';
import { fromStandardDateFormat, fromStandardDateTimeFormat, toStandardDateFormat } from './date';
import { airtableRequiredFields } from './globals';

export function getDailySumFromDailyRows(
  dailyRows: DailyRow[], 
  metrics: (keyof DailyRow)[]
) {

  let aggFuncMap: {
    [key: string]: AggregationFunction
  } = {}; 
  metrics.forEach((metric) => {
    aggFuncMap[metric] = "sum"; 
  }); 
  
  return aggregateDailyRows(
    dailyRows, 
    metrics, 
    aggFuncMap
  ); 
}

export function getDataAssuranceColFromDailyRow(
  dailyRows: DailyRow[], 
  systemSumRow: YearlyRow, 
  year: number, 
  metrics: (keyof DailyRow)[]
): DataAssuranceCol {
  let dataAssuranceCol: DataAssuranceCol = {
    year: year-1, 
    assuredFromDate: systemSumRow.business_date_from !== undefined ? 
      fromStandardDateTimeFormat(systemSumRow.business_date_from) : 
      undefined, 
    assuredToDate: systemSumRow.business_date_to !== undefined ? 
      fromStandardDateTimeFormat(systemSumRow.business_date_to) : 
      undefined, 
    dataAssuranceEntries: {}
  }; 

  const filteredDailyRows = dailyRows.filter((dailyRow) => {
    return dailyRow.business_date !== undefined ? 
      fromStandardDateFormat(dailyRow.business_date)
        .isBetween(
          dataAssuranceCol.assuredFromDate, 
          dataAssuranceCol.assuredToDate, 
          "day",
          "[]"
        ) : 
      false
  }); 
  
  const aggregatedDailySumRow: DailyRow = getDailySumFromDailyRows(
    filteredDailyRows, 
    metrics
  ); 
  const metricsToAssure = metrics.filter((metric) => (
    ! airtableRequiredFields
      .RawData.dataAssuranceExcludedFromTrack
      .includes(metric)))

  metricsToAssure.forEach((metric) => {
    const dailySumRowMetric = aggregatedDailySumRow[metric] as number; 
    const systemSumRowMetric = systemSumRow[metric] as number; 
    dataAssuranceCol.dataAssuranceEntries[metric] = {
      dailySum: dailySumRowMetric, 
      systemSum: systemSumRowMetric, 
      sumsTally: dailySumRowMetric.toFixed(0) === systemSumRowMetric.toFixed(0)
    }
  })

  return dataAssuranceCol
}

export function getVenueAssuranceStatus(
  dataAssuranceCols: DataAssuranceCol[], 
  metricsToAssure: (keyof DailyRow) []
) {

  console.log("dataAssuranceCols", dataAssuranceCols); 

  const dataAssuranceColsTallyResults = dataAssuranceCols
    .map((dataAssuranceCol) => {
      const entries = Object
        .keys(dataAssuranceCol.dataAssuranceEntries)
        .filter((metricName) => (metricsToAssure).includes(metricName as keyof DailyRow))
        .map((metricName) => dataAssuranceCol.dataAssuranceEntries[metricName as keyof DailyRow])

      const entriesTally = entries
        .map((metric) => metric?.sumsTally); 
      console.log("entriesTally", entriesTally)
      return entriesTally.every((entry) => entry); 
    })
  console.log(dataAssuranceColsTallyResults)
  const allValuesTally = dataAssuranceColsTallyResults
    .every((dataAssuranceColTallyResults) => dataAssuranceColTallyResults); 

  return allValuesTally;  
}

export function generateVenueAssuranceData(
  lastYearData: DataAssuranceCol, 
  yearToDateData: DataAssuranceCol, 
  metrics: (keyof DailyRow)[]
) {
  
  return {
    lastYearData: lastYearData, 
    yearToDateData: yearToDateData, 
    metrics: metrics, 
    assuranceStatus: getVenueAssuranceStatus(
      [
        lastYearData, 
        // Temporarily removed to ignore lastYearData 
        yearToDateData
      ], 
      metrics.filter((metric) => ! (airtableRequiredFields
        .RawData
        .dataAssuranceExcludedFromTrack
        .includes(metric)))
    )
  }
}
