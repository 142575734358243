import React from "react"
import { SalesSummaryRowType, ChannelSales } from "../../typings/common";
import { formatCurrency, formatPercentage } from "../../utils/currency";
import SalesSummaryRow from "../SalesSummaryRow"

type SalesByChannelProps = {
  salesByChannelData?: any
}

const SalesByChannel: React.FC<SalesByChannelProps> = ({
  salesByChannelData
}) => {
  return (
    <>
      {
        salesByChannelData.map((channelSales: ChannelSales) => {
          return (
            <SalesSummaryRow 
              key={channelSales.name}
              itemNameText={channelSales.name}
              itemValueText={`${
                formatCurrency(channelSales.value)
              } (${
                formatPercentage(channelSales.percentageOfTotalSales)
              } of sales)`}
              type={SalesSummaryRowType.grayText}
            />
          )
        })
      }
    </>
  )
}

export default SalesByChannel; 