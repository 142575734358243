import { GitCommit, GitDailySummary } from "../typings/common"; 
import moment from "moment";
import { toStandardDateFormat } from "../utils/date";

const paToken = process.env.REACT_APP_GITHUB_PAT; 
const user = process.env.REACT_APP_GITHUB_USER; 
const repo = process.env.REACT_APP_GITHUB_REPO; 

export function getGitHubPush(): Promise<GitCommit[]> {
  return fetch(
    // "https://api.github.com/users/jarvin95", 
    `https://api.github.com/repos/${user}/${repo}/commits?per_page=100`, 
    {
      method: 'get', 
      headers: new Headers({
        'Authorization': `Basic ${btoa(`${user}:${paToken}`)}`, 
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
    .then((res) => {
      if (! res.ok) {
        throw new Error(res.statusText); 
      }
      console.log(res); 
      return (res.json() as unknown) as Array<GitCommit>
    }); 
}

export function toGitDailySummaryArray(gitCommits: GitCommit[]) {
  let output: GitDailySummary = {}; 
  gitCommits.forEach((gitCommit) => {
    if (gitCommit.commit &&
      gitCommit.commit.author &&
      gitCommit.commit.author.date) {
      
      const date = moment(gitCommit.commit.author.date);
      const dateString = toStandardDateFormat(date) as string; 
      if (! Object.keys(output).includes(dateString)) {
        output[dateString] = []; 
      }
      
      if (gitCommit.commit.message && 
        ! output[dateString].includes(gitCommit.commit.message)) {

        output[dateString].push(gitCommit.commit.message); 
      }
    }
  })

  return output; 
}