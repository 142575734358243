import { DeltaProps } from "../typings/common";

const retainCentsCurrencyFormatter = new Intl.NumberFormat('en-SG', {
  style: 'currency', 
  currency: 'SGD'
}); 

const currencyFormatter = new Intl.NumberFormat('en-SG', {
  style: 'currency', 
  currency: 'SGD', 
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}); 

export const formatCurrency = (currency: string | number | undefined, retainCents: boolean = false) => {
  if (currency === undefined) {
    return "-"
  }
  if (typeof currency === "string") {
    currency = Number.parseFloat(currency)
  }

  if (isNaN(currency)) {
    return "-"
  }
  return retainCents ? retainCentsCurrencyFormatter.format(currency) : currencyFormatter.format(currency)
}

export const formatPercentage = (
  percentage: number | undefined, 
  decimalPlace: number = 0, 
  ignoreNegative: boolean = false) => {
  if (percentage === undefined || isNaN(percentage) || percentage === Infinity) {
    return "-%"; 
  }
  else {
    return `${(percentage*100 * ((percentage < 0 && ignoreNegative) ? -1 : 1))
      .toFixed(decimalPlace)}%`; 
  }
}

export const getDelta = (x0: number | undefined, x: number | undefined, suffix: string = ""): DeltaProps => {
  if (x0 === undefined || x === undefined) {
    return {
      deltaDirection: "0", 
      deltaText: "NIL"
    }
  }

  let deltaDirection: DeltaProps["deltaDirection"] = "0"; 
  if (x > x0) {
    deltaDirection = "+"; 
  }
  else {
    deltaDirection ="-"
  }

  const deltaText = 
    x !== 0 ? 
    (
      x0 !== 0 ? 
      `${Math.abs(((x - x0) / x0 * 100)).toFixed(0)}%` : 
      `∞%`
    ) : 
    ""; 

  return {
    deltaDirection: deltaDirection, 
    deltaText: `${deltaText}${suffix}`
  }
}

export const getDeltaPercentageText = (
  x0: number, 
  x1: number
) => {
  const percentageDelta = (x1-x0)/x0; 
  const percentageDeltaText = formatPercentage(percentageDelta, 0, true); 
  return `${ percentageDelta > 0 ? 
    `▲ ${percentageDeltaText}` : 
    (percentageDelta < 0 ? 
      `▼ ${percentageDeltaText}` : 
      ``)}`
}