import React from 'react'; 
import MainHeader from './MainHeader'; 
import LoadingOverlay from 'react-loading-overlay'; 
import SideBar from './SideBarNew';
import { getGitHubPush, toGitDailySummaryArray } from '../services/GitHub'; 
import { Dashboard, GitDailySummary } from '../typings/common'
import { HeadlineText, Loading, MobileLayout, Section } from './StandardComponents';

const ChangeLog: React.FC = () => {
  
  const [ loading, setLoading ] = React.useState<boolean>(false); 
  const [ gitDailySummary, setGitDailySummary ] = React.useState<GitDailySummary>(); 

  React.useEffect(() => {
    async function getGitHubCommitDataset() {
      const getGitHubCommitResponse = await getGitHubPush(); 
      console.log(getGitHubCommitResponse); 
      const gitDailySummaryTemp = toGitDailySummaryArray(getGitHubCommitResponse); 
      console.log(gitDailySummaryTemp); 
      setGitDailySummary(gitDailySummaryTemp); 

      setLoading(false); 
    }
    
    setLoading(true); 
    getGitHubCommitDataset(); 
  }, [])

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(100, 108, 119, 0.2)', 
          zIndex: 2000, 
          height: '100vh', 
          position: 'fixed'
        })
      }}>
        <MobileLayout>
          
          <MainHeader />
          <SideBar 
            pageWrapId="outer-container"
            outerContainerId="outer-container"
            selectedItem="Changelog"
          />

          {
            gitDailySummary && Object.keys(gitDailySummary).map((commitDate) => (
              <Section key={commitDate}>
                <HeadlineText>{commitDate}</HeadlineText>
                <ul>
                {
                  gitDailySummary[commitDate].map((gitEntry, index) => (
                    <li key={`${commitDate}-${index}`}>
                      {gitEntry}
                    </li>
                  ))
                }
                </ul>
              </Section>
            ))
          }

        </MobileLayout>

      </LoadingOverlay>
  ); 
}

export default ChangeLog; 