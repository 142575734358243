// This is for the temporary consolidation of EVP Asia Square's and Grab Kitchen's figures
// in IncentivesDashboard

import { fromStandardDateTimeFormat, toStandardDateFormat } from "./date";
import {
  getDailyIncentiveLog,
  getDayIncentiveParams,
  getMtdDailySales,
  getMtdPotAdditions,
} from "../services/AirtablePlus";

import { DailyIncentiveLog } from "../typings/common";
import { Moment } from "moment";
import { netSalesWithServiceChargeFromRecord } from "./dailyRow";
import { recordToDailyIncentiveLog } from "../components/IncentivesDashboard/IncentivesController";
import { safeSum } from "./globals";

export const isEvpGrkIncentiveConsolidationLogicActive = true;

export const evp = "Extra Virgin Pizza";
export const grk = "Grab Kitchen";

const mtdSalesPromise = (venue: string, date: Moment) =>
  getMtdDailySales(venue, date).then((res) => {
    let currDayFigure = 0;
    let lastUpdated: Moment | undefined;
    const dailyNetSalesWithServiceCharge = res.map((dailyRowRecord) => {
      const dayNetSalesWithServiceCharge = netSalesWithServiceChargeFromRecord(dailyRowRecord);

      if (dailyRowRecord.fields.business_date === toStandardDateFormat(date)) {
        currDayFigure = dayNetSalesWithServiceCharge;
        lastUpdated = fromStandardDateTimeFormat(
          dailyRowRecord.fields.end_time ?? "2000-01-01T00:00:00.000Z"
        );
      }

      return dayNetSalesWithServiceCharge;
    });

    return {
      dailySales: currDayFigure,
      monthlySales: safeSum(dailyNetSalesWithServiceCharge),
      lastUpdated: lastUpdated,
    };
  });

export const getIncentiveDashboardValsForEvp = (date: Moment): Promise<DailyIncentiveLog> => {
  const searchIncentiveLockedPromise = getDailyIncentiveLog(evp, date);

  const incentiveStructureParamsPromise = getDayIncentiveParams(evp, date);

  const mtdPotBeforeAdditionPromise = getMtdPotAdditions(evp, date).then((res) => {
    const dailyPotSize = res.map(
      (incentiveLockedRecords) =>
        (incentiveLockedRecords.fields.additionPreTargetPortion ?? 0) +
        (incentiveLockedRecords.fields.additionPostTargetPortion ?? 0)
    );

    return safeSum(dailyPotSize);
  });

  return searchIncentiveLockedPromise.then((searchIncentiveLockedResponse) => {
    // console.log("searchIncentiveLockedResponse",
    //   searchIncentiveLockedResponse);
    if (searchIncentiveLockedResponse.length === 1) {
      // Day is in Incentive - Locked database
      // i.e. Non-live data
      return recordToDailyIncentiveLog(searchIncentiveLockedResponse[0]);
    } else if (searchIncentiveLockedResponse.length === 0) {
      // Day's data is not in 'Incentive - Locked' database
      // i.e. Live data --> Gets the following:
      //   (a) Incentive structure params from Incentive - Params
      //   (b) MTD sales from Raw Data
      //   (c) MTD pot size from Incentive - Locked
      return Promise.all([
        incentiveStructureParamsPromise,
        mtdSalesPromise(evp, date),
        mtdSalesPromise(grk, date),
        mtdPotBeforeAdditionPromise,
      ]).then(
        ([
          incentiveStructureParamsResponse,
          evpMtdSalesResponse,
          grkMtdSalesReponse,
          mtdPotBeforeAdditionResponse,
        ]) => {
          console.log("evpMtdSalesResponse", evpMtdSalesResponse);
          console.log("grkMtdSalesReponse", grkMtdSalesReponse);

          const mtdSalesResponse = {
            dailySales: evpMtdSalesResponse.dailySales + grkMtdSalesReponse.dailySales,
            monthlySales: evpMtdSalesResponse.monthlySales + grkMtdSalesReponse.monthlySales,
            lastUpdated: evpMtdSalesResponse.lastUpdated?.isBefore(grkMtdSalesReponse.lastUpdated)
              ? evpMtdSalesResponse.lastUpdated
              : grkMtdSalesReponse.lastUpdated,
          };

          const salesExceedDailyTarget =
            mtdSalesResponse.dailySales >= (incentiveStructureParamsResponse.dailyTarget ?? 0);

          const additionPreTargetPortion = salesExceedDailyTarget
            ? (incentiveStructureParamsResponse.dailyTargetCut ?? 0) *
              (incentiveStructureParamsResponse.dailyTarget ?? 0)
            : 0;

          const additionPostTargetPortion = salesExceedDailyTarget
            ? (incentiveStructureParamsResponse.dailyTargetBonusCut ?? 0) *
              ((mtdSalesResponse.dailySales ?? 0) -
                (incentiveStructureParamsResponse.dailyTarget ?? 0))
            : 0;

          const potAfterAddition =
            mtdPotBeforeAdditionResponse + additionPreTargetPortion + additionPostTargetPortion;

          const isPotUnlocked =
            mtdSalesResponse.monthlySales >= (incentiveStructureParamsResponse.monthlyTarget ?? 0);

          // Note: preTargetPortion calculation independent of isPotUnlocked as this value is hypothetical
          // (assuming isPotUnlocked)
          const preTargetPortion =
            Math.min(
              incentiveStructureParamsResponse.monthlyTarget ?? 0,
              mtdSalesResponse.monthlySales
            ) * (incentiveStructureParamsResponse.monthlyTargetCut ?? 0);

          const postTargetPortion = isPotUnlocked
            ? (incentiveStructureParamsResponse.monthlyTargetBonusCut ?? 0) *
              ((mtdSalesResponse.monthlySales ?? 0) -
                (incentiveStructureParamsResponse.monthlyTarget ?? 0))
            : 0;

          const dailyIncentiveLog = {
            ...incentiveStructureParamsResponse,
            dailySales: mtdSalesResponse.dailySales,
            lastUpdated: mtdSalesResponse.lastUpdated,
            salesExceedDailyTarget: salesExceedDailyTarget,
            additionPreTargetPortion: additionPreTargetPortion,
            additionPostTargetPortion: additionPostTargetPortion,
            potBeforeAddition: mtdPotBeforeAdditionResponse,
            potAfterAddition: potAfterAddition,
            monthlySales: mtdSalesResponse.monthlySales,
            isPotUnlocked: isPotUnlocked,
            preTargetPortion: preTargetPortion,
            postTargetPortion: postTargetPortion,
            potReward:
              (potAfterAddition + preTargetPortion + postTargetPortion) /
              (incentiveStructureParamsResponse.numberToSplit ?? 1),
          };

          return dailyIncentiveLog;
        }
      );
    } else {
      console.log("More than one entry in 'Incentive - Locked'");
      return {};
    }
  });
};
