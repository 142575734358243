import React from "react"
import styled from "styled-components"
import { ArrowFont, ExpandableChildContainer, LinkedText } from "./StandardComponents"

const Wrapper = styled.div`
  margin: 2px auto 24px auto; 
  text-align: left; 
`

type ExpandableComponentProps = {
  sectionHeaderText: string, 
  children: any, 
  defaultExpanded: boolean
}

export const ExpandableComponent: React.FC<ExpandableComponentProps> = ({
  sectionHeaderText, 
  children, 
  defaultExpanded
}) => {

  const [ isExpanded, setIsExpanded ] = React.useState<boolean>(defaultExpanded); 

  const childWithContainer = (
    <ExpandableChildContainer>
      {children}
    </ExpandableChildContainer>
  )

  return (
    <Wrapper>
      <LinkedText onClick={() => setIsExpanded(! isExpanded)}>
        {sectionHeaderText}
        <ArrowFont>
          {isExpanded ? "▲" : "▼"}
        </ArrowFont>
      </LinkedText>
      { isExpanded && childWithContainer }
    </Wrapper>
  )
}