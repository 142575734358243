import { AggregationFunction, Channel, ChannelIncDel, ChannelSales, DailyRow, DeltaProps } from "../typings/common"; 
import { Record } from "airtable"
import { aggregationFunctions, gst, safeSum, significantProportionThreshold } from "../utils/globals"
import { fromStandardDateFormat, fromStandardDateTimeFormat, fromStandardYearMonth, toHumanMonthYearString } from "./date";
import { getDelta } from "./currency";

export const toDailyRow = (record: Record<DailyRow> | DailyRow, fromDailyRow: boolean = false) => {
  
  // Supplementing the fields from database with calculated fields
  const dailyRow = fromDailyRow ? record as DailyRow : (record as Record<DailyRow>).fields

  if (dailyRow.total_collection !== undefined && dailyRow.gst !== undefined) {
    dailyRow.net_sales_with_service_charge = dailyRow.total_collection - dailyRow.gst - (dailyRow["tips|amount"] ?? 0); 
  }

  if (dailyRow.net_sales_with_service_charge !== undefined && dailyRow.service_charge !== undefined) {
    dailyRow.net_sales = dailyRow.net_sales_with_service_charge - dailyRow.service_charge; 
  }
  
  if (dailyRow.net_sales !== undefined && dailyRow.discounts !== undefined) {
    dailyRow.gross_sales = dailyRow.net_sales - dailyRow.discounts; 
  }

  if (dailyRow["channel|dine_in|sales"] !== undefined && dailyRow["channel|dine_in|check_count"] !== undefined) {
    dailyRow["channel|dine_in|aov"] = dailyRow["channel|dine_in|sales"] / dailyRow["channel|dine_in|check_count"]
  }

  if (dailyRow["channel|take_out|sales"] !== undefined && dailyRow["channel|take_out|check_count"] !== undefined) {
    dailyRow["channel|take_out|aov"] = dailyRow["channel|take_out|sales"] / dailyRow["channel|take_out|check_count"]; 
  }

  if (dailyRow["channel|delivery|sales"] !== undefined && dailyRow["channel|delivery|check_count"] !== undefined) {
    dailyRow["channel|delivery|aov"] = dailyRow["channel|delivery|sales"] / dailyRow["channel|delivery|check_count"]; 
  }

  if (dailyRow["channel|events|sales"] !== undefined && dailyRow["channel|events|check_count"] !== undefined) {
    dailyRow["channel|events|aov"] = dailyRow["channel|events|sales"] / dailyRow["channel|events|check_count"]; 
  }

  if (dailyRow["channel|others|sales"] !== undefined && dailyRow["channel|others|check_count"] !== undefined) {
    dailyRow["channel|others|aov"] = dailyRow["channel|others|sales"] / dailyRow["channel|others|check_count"]; 
  }

  if ( dailyRow["channel|dine_in|sales"] !== undefined &&
    dailyRow["channel|take_out|sales"] !== undefined &&
    dailyRow["channel|delivery|sales"] !== undefined &&
    dailyRow["channel|events|sales"] !== undefined && 
    dailyRow["channel|others|sales"] !== undefined && 
    dailyRow["service_charge"] !== undefined) {
    
    dailyRow["sumChannelSales"] 
      = dailyRow["channel|dine_in|sales"] 
      + dailyRow["channel|take_out|sales"]
      + dailyRow["channel|delivery|sales"]
      + dailyRow["channel|events|sales"]
      + dailyRow["channel|others|sales"]
      + dailyRow["service_charge"]; 
  }

  if (dailyRow["channel|dine_in|sales"] !== undefined && 
    dailyRow["service_charge"] !== undefined) {
    dailyRow["dine_in_w_svc|sales"] = 
      dailyRow["channel|dine_in|sales"] + 
      dailyRow["service_charge"]; 
  }

  if (dailyRow["dine_in_w_svc|sales"] !== undefined && dailyRow["channel|dine_in|guests"] !== undefined) {
    dailyRow.average_spend_per_guest = dailyRow["dine_in_w_svc|sales"] / dailyRow["channel|dine_in|guests"]; 
  }
  
  if (dailyRow["delivery|deliveroo|sales"] !== undefined) {
    dailyRow["delivery|deliveroo|sales_less_svc"] 
      = dailyRow["delivery|deliveroo|sales"] / (1 + gst); 
  }
  if (dailyRow["delivery|foodpanda|sales"] !== undefined) {
    dailyRow["delivery|foodpanda|sales_less_svc"] 
      = dailyRow["delivery|foodpanda|sales"] / (1 + gst); 
  }
  if (dailyRow["delivery|grabfood|sales"] !== undefined) {
    dailyRow["delivery|grabfood|sales_less_svc"] 
      = dailyRow["delivery|grabfood|sales"] / (1 + gst); 
  }
  if (dailyRow["delivery|oddle|sales"] !== undefined) {
    dailyRow["delivery|oddle|sales_less_svc"] 
      = dailyRow["delivery|oddle|sales"] / (1 + gst); 
  }
  
  if (dailyRow["delivery|deliveroo|sales"] !== undefined && 
    dailyRow["delivery|deliveroo|order_count"] !== undefined) {
    dailyRow["delivery|deliveroo|aov"] = 
      dailyRow["delivery|deliveroo|sales"] / 
      dailyRow["delivery|deliveroo|order_count"]; 
  }
  if (dailyRow["delivery|foodpanda|sales"] !== undefined && 
    dailyRow["delivery|foodpanda|order_count"] !== undefined) {
    dailyRow["delivery|foodpanda|aov"] = 
      dailyRow["delivery|foodpanda|sales"] / 
      dailyRow["delivery|foodpanda|order_count"]; 
  }
  if (dailyRow["delivery|grabfood|sales"] !== undefined && 
    dailyRow["delivery|grabfood|order_count"] !== undefined) {
    dailyRow["delivery|grabfood|aov"] = 
      dailyRow["delivery|grabfood|sales"] / 
      dailyRow["delivery|grabfood|order_count"]; 
  }
  if (dailyRow["delivery|oddle|sales"] !== undefined && 
    dailyRow["delivery|oddle|order_count"] !== undefined) {
    dailyRow["delivery|oddle|aov"] = 
      dailyRow["delivery|oddle|sales"] / 
      dailyRow["delivery|oddle|order_count"]; 
  }
  if (dailyRow.last_modified) {
    dailyRow.last_updated = fromStandardDateTimeFormat(dailyRow.last_modified); 
  }

  return dailyRow; 
}

export const getSalesByProduct = (dailyRow: DailyRow | undefined) => {
  const salesByProduct = [
    {
      name: "Food", 
      value: dailyRow?.["product|food|sales"] ? dailyRow?.["product|food|sales"] : 0 
    }, 
    {
      name: "Beverage", 
      value: dailyRow?.["product|beverage|sales"] ? dailyRow?.["product|beverage|sales"] : 0 
    }, 
    {
      name: "Condiment", 
      value: dailyRow?.["product|condiment|sales"] ? dailyRow?.["product|condiment|sales"] : 0 
    }, 
    {
      name: "Miscellaneous", 
      value: dailyRow?.["product|miscellaneous|sales"] ? dailyRow?.["product|miscellaneous|sales"] : 0 
    }
  ];

  const total = salesByProduct
    .map((product) => product.value)
    .reduce((sum, element) => sum + element); 
  return salesByProduct
    // .filter((channel) => channel.value > significantProportionThreshold*total)
    .map((product) => (
      {
        ...product, 
        percentageOfTotalSales: product.value / total
      }
    ))
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getSalesByChannel = (dailyRow: DailyRow | undefined): ChannelSales[] => {
  const salesByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["dine_in_w_svc|sales"] ? dailyRow?.["dine_in_w_svc|sales"] : 0 
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|sales"] ? dailyRow?.["channel|take_out|sales"] : 0
    }, 
    {
      name: "Delivery", 
      value: dailyRow?.["channel|delivery|sales"] ? dailyRow?.["channel|delivery|sales"] : 0
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|sales"] ? dailyRow?.["channel|events|sales"] : 0
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|sales"] ? dailyRow?.["channel|others|sales"] : 0
    }
  ]; 
  const total = salesByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return salesByChannel
    // .filter((channel) => channel.value > significantProportionThreshold*total)
    .map((channel) => (
      {
        ...channel, 
        percentageOfTotalSales: channel.value / total
      }
    ))
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getSalesByChannelMarketing = (dailyRow: DailyRow | undefined): ChannelSales[] => {
  const salesByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["channel|dine_in|sales"] as number
    }, 
    {
      name: "Delivery (Oddle)", 
      value: dailyRow?.["delivery|oddle|sales_less_svc"] as number
    }, 
    {
      name: "Delivery (GrabFood)", 
      value: dailyRow?.["delivery|grabfood|sales_less_svc"] as number
    }, 
    {
      name: "Delivery (Deliveroo)", 
      value: dailyRow?.["delivery|deliveroo|sales_less_svc"] as number
    }, 
    {
      name: "Delivery (FoodPanda)", 
      value: dailyRow?.["delivery|foodpanda|sales_less_svc"] as number
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|sales"] as number
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|sales"] as number
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|sales"] as number
    }
  ]; 
  const total = salesByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return salesByChannel
    .filter((channel) => channel.value > significantProportionThreshold*total) 
    // Remove zero entries
    .map((channel) => (
      {
        ...channel, 
        percentageOfTotalSales: channel.value / total
      }
    ))
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getOrderByChannel = (dailyRow: DailyRow | undefined) => {
  const orderByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["channel|dine_in|check_count"] ? dailyRow?.["channel|dine_in|check_count"] : 0 
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|check_count"] ? dailyRow?.["channel|take_out|check_count"] : 0
    }, 
    {
      name: "Delivery", 
      value: dailyRow?.["channel|delivery|check_count"] ? dailyRow?.["channel|delivery|check_count"] : 0
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|check_count"] ? dailyRow?.["channel|events|check_count"] : 0
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|check_count"] ? dailyRow?.["channel|others|check_count"] : 0
    }
  ]; 
  const total = orderByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return orderByChannel
    .filter((channel) => channel.value > significantProportionThreshold*total)
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getOrderByChannelMarketing = (dailyRow: DailyRow | undefined) => {
  const orderByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["channel|dine_in|check_count"] as number
    }, 
    {
      name: "Delivery (Oddle)", 
      value: dailyRow?.["delivery|oddle|order_count"] as number
    }, 
    {
      name: "Delivery (GrabFood)", 
      value: dailyRow?.["delivery|grabfood|order_count"] as number
    }, 
    {
      name: "Delivery (Deliveroo)", 
      value: dailyRow?.["delivery|deliveroo|order_count"] as number
    }, 
    {
      name: "Delivery (FoodPanda)", 
      value: dailyRow?.["delivery|foodpanda|order_count"] as number
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|check_count"] as number
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|check_count"] as number
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|check_count"] as number
    }
  ]; 
  const total = orderByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return orderByChannel
    .filter((channel) => channel.value > significantProportionThreshold*total)
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getAovByChannel = (dailyRow: DailyRow | undefined) => {
  const aovByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["channel|dine_in|aov"] ? dailyRow?.["channel|dine_in|aov"] : 0 
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|aov"] ? dailyRow?.["channel|take_out|aov"] : 0
    }, 
    {
      name: "Delivery", 
      value: dailyRow?.["channel|delivery|aov"] ? dailyRow?.["channel|delivery|aov"] : 0
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|aov"] ? dailyRow?.["channel|events|aov"] : 0
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|aov"] ? dailyRow?.["channel|others|aov"] : 0
    }
  ]; 
  const total = aovByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return aovByChannel
    .filter((channel) => channel.value > significantProportionThreshold*total)
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getAovByChannelMarketing = (dailyRow: DailyRow | undefined) => {
  const aovByChannel = [
    {
      name: "Dine-in", 
      value: dailyRow?.["channel|dine_in|aov"] as number
    }, 
    {
      name: "Delivery (Oddle)", 
      value: dailyRow?.["delivery|oddle|aov"] as number
    }, 
    {
      name: "Delivery (GrabFood)", 
      value: dailyRow?.["delivery|grabfood|aov"] as number
    }, 
    {
      name: "Delivery (Deliveroo)", 
      value: dailyRow?.["delivery|deliveroo|aov"] as number
    }, 
    {
      name: "Delivery (FoodPanda)", 
      value: dailyRow?.["delivery|foodpanda|aov"] as number
    }, 
    {
      name: "Take-out", 
      value: dailyRow?.["channel|take_out|aov"] as number
    }, 
    {
      name: "Events", 
      value: dailyRow?.["channel|events|aov"] as number
    }, 
    {
      name: "Others", 
      value: dailyRow?.["channel|others|aov"] as number
    }
  ]; 
  const total = aovByChannel
    .map((channel) => ! Number.isNaN(channel.value) ? channel.value : 0)
    .reduce((sum, element) => sum + element); 
  return aovByChannel
    .filter((channel) => channel.value > significantProportionThreshold*total); 
    // .sort((a, b) => b.value - a.value); // Sort by descending
}

export const getSumChannelSales = (dailyRow: DailyRow, channelSelection: (Channel | ChannelIncDel)[]): number => {
  const activeChannels = channelSelection
    .filter((channel) => channel.active); 
  if (activeChannels.length === 0) {
    return 0; 
  }
  const sumChannelSalesArray = (activeChannels.map((activeChannel) => dailyRow[activeChannel.codeSales]) as number [])
  return safeSum(sumChannelSalesArray); 
}

export const updateDtaRows = (dailyRow: DailyRow, channelSelection: Channel[]): DailyRow => {
  const activeChannels = channelSelection
    .filter((channel) => channel.active); 
  if (activeChannels.length === 0) {
    return {
      ...dailyRow, 
      dtaSales: 0, 
      dtaOrderCount: 0, 
      dtaAov: 0
    }; 
  }
  const rawDtaRows = activeChannels
    .map((activeChannel) => (
      {
        totalSales: (dailyRow[activeChannel.codeSales] !== undefined ? 
          dailyRow[activeChannel.codeSales] : 
          0) as number, 
        orderCount: (dailyRow[activeChannel.codeOrderCount] !== undefined ? 
          dailyRow[activeChannel.codeOrderCount] : 
          0) as number
      }
    ))
    .reduce((prev, curr) => (
      {
        totalSales: prev.totalSales + curr.totalSales, 
        orderCount: prev.orderCount + curr.orderCount
      }
    )); 
  return {
    ...dailyRow, 
    dtaSales: rawDtaRows.totalSales, 
    dtaOrderCount: rawDtaRows.orderCount,
    dtaAov: rawDtaRows.totalSales !== 0 && rawDtaRows.orderCount !== 0 ? 
      rawDtaRows.totalSales / rawDtaRows.orderCount : 
      0
  }; 
}


export const updateDeaRows = (dailyRow: DailyRow, channelSelection: Channel[]): DailyRow => {
  const activeChannels = channelSelection
    .filter((channel) => channel.active); 
  if (activeChannels.length === 0) {
    return {
      ...dailyRow, 
      deaSales: 0, 
      deaGuestCount: 0, 
      deaSpendPerGuest: 0
    }; 
  }
  const rawDeaRows = activeChannels
    .map((activeChannel) => (
      {
        totalSales: (dailyRow[activeChannel.codeSales] !== undefined ? 
          dailyRow[activeChannel.codeSales] : 
          0) as number, 
        guestCount: (dailyRow[activeChannel.codeGuestCount] !== undefined ? 
          dailyRow[activeChannel.codeGuestCount] : 
          0) as number
      }
    ))
    .reduce((prev, curr) => (
      {
        totalSales: prev.totalSales + curr.totalSales, 
        guestCount: prev.guestCount + curr.guestCount
      }
    )); 
  return {
    ...dailyRow, 
    deaSales: rawDeaRows.totalSales, 
    deaGuestCount: rawDeaRows.guestCount,
    deaSpendPerGuest: rawDeaRows.totalSales !== 0 && rawDeaRows.guestCount !== 0 ? 
      rawDeaRows.totalSales / rawDeaRows.guestCount : 
      0
  }; 
}

export const getMomComparison = (
  dailyRows: DailyRow[], 
  metric: keyof DailyRow
) => {
  
  let dailyRowsByMonth: {
    [key: string]: DailyRow[], 
  } = {}; 

  dailyRows.forEach((dailyRow) => {
    if (dailyRow.business_date !== undefined) {
      const date = fromStandardDateFormat(dailyRow.business_date)
      const monthIndex = date.format("YYYY-MM"); 

      if (! (monthIndex in dailyRowsByMonth)) {
        dailyRowsByMonth[monthIndex as string] = []; 
      }
      dailyRowsByMonth[monthIndex].push(dailyRow);
    } 
  }); 

  let output: {
    monthIndex: string, 
    monthName?: string, 
    metricFigure?: number, 
    momDelta?: DeltaProps
  }[] = []; 

  Object.entries(dailyRowsByMonth).forEach(([monthIndex, monthDailyRows]) => {
    const arrayMetric: number[] = monthDailyRows.map((dailyRow) => dailyRow[metric] as number); 
    output.push({
      monthIndex: monthIndex, 
      monthName: toHumanMonthYearString(fromStandardYearMonth(monthIndex)), 
      metricFigure: safeSum(arrayMetric)
    }); 
  }); 

  output.sort((a, b) => (a.monthIndex)?.localeCompare(b.monthIndex))

  const outputLength = output.length; 
  output.forEach((monthItem, index, arr) => {
    if (index !== 0) {
      arr[index].momDelta = getDelta(
        arr[index-1].metricFigure, 
        arr[index].metricFigure
      )
    }
  }); 

  return output; 
}

export const getYoyComparison = (
  dailyRows: DailyRow[], 
  metric: keyof DailyRow
) => {
  
  let dailyRowsByMonth: {
    [key: string]: DailyRow[], 
  } = {}; 

  dailyRows.forEach((dailyRow) => {
    if (dailyRow.business_date !== undefined) {
      const date = fromStandardDateFormat(dailyRow.business_date)
      const yearIndex = date.format("YYYY"); 

      if (! (yearIndex in dailyRowsByMonth)) {
        dailyRowsByMonth[yearIndex as string] = []; 
      }
      dailyRowsByMonth[yearIndex].push(dailyRow);
    } 
  }); 

  let output: {
    yearName: string, 
    metricFigure?: number, 
    yoyDelta?: DeltaProps
  }[] = []; 

  Object.entries(dailyRowsByMonth).forEach(([yearIndex, yearDailyRows]) => {
    const arrayMetric: number[] = yearDailyRows.map((dailyRow) => dailyRow[metric] as number); 
    output.push({
      yearName: yearIndex as string, 
      metricFigure: safeSum(arrayMetric)
    })
  }); 

  output.sort((a, b) => (a.yearName).localeCompare(b.yearName))

  const outputLength = output.length; 
  output.forEach((monthItem, index, arr) => {
    if (index !== 0) {
      arr[index].yoyDelta = getDelta(
        arr[index-1].metricFigure, 
        arr[index].metricFigure
      )
    }
  }); 

  return output; 
}

export const aggregateDailyRows = (
  dailyRows: DailyRow[], 
  metricsToAggregate: (keyof DailyRow)[], 
  aggregationFunctionMap: {
    [key: string]: AggregationFunction
  }
) => {

  let output: {
    [key: string]: any
  } = {}; 
  metricsToAggregate.forEach((metric: keyof DailyRow) => {
    const metricArray = dailyRows
      .map((dailyRow) => dailyRow[metric] as number); 
    
    const aggregatedMetric = aggregationFunctions[aggregationFunctionMap[metric]](metricArray); 
    output[metric] = aggregatedMetric; 
  })

  return output; 
}

export const netSalesWithServiceChargeFromRecord = (
  record: Record<DailyRow>
) => (record.fields.total_collection ?? 0) - 
  (record.fields.gst ?? 0) - 
  (record.fields["tips|amount"] ?? 0); 

export const netSalesWithServiceChargeFromDailyRow = (dailyRow: DailyRow) =>
  dailyRow.net_sales_with_service_charge
  ? dailyRow.net_sales_with_service_charge 
  : (dailyRow.total_collection ?? 0) -
    (dailyRow.gst ?? 0) -
    (dailyRow["tips|amount"] ?? 0); 