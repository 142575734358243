import React, { FormEvent } from 'react'; 
import LoadingOverlay from 'react-loading-overlay'; 
import { useHistory } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { loggedInUser } from '../utils/globals';
import TextField from './InputFields/TextField';
import MainHeader from './MainHeader';
import SideBar from './SideBarNew';
import { 
  FailedAlert, 
  MobileLayout, 
  SuccessAlert, 
  Text, 
  Button,
  Loading
} from './StandardComponents';

const SetPasswordPage: React.FC = (props) => {
  const [ loading, setLoading ] = React.useState<boolean>(false); 
  const [ inputPassword1, setInputPassword1 ] = React.useState<string>(""); 
  const [ inputPassword2, setInputPassword2 ] = React.useState<string>(""); 
  const [ showPasswordsMismatchAlert, setShowPasswordsMismatchAlert ] = 
    React.useState<boolean>(false); 
  const [ 
    passwordSetResult, 
    setPasswordSetResult
  ] = React.useState<{
    show: boolean, 
    result?: boolean
  }>({
    show: false
  }); 
  const [ isVirginLogin, setIsVirginLogin ] = React.useState<boolean>(); 

  const history = useHistory(); 
  
  React.useEffect(() => {
    
    const isVirginLoginTemp = window.localStorage.getItem("isVirginLogin") !== null; 
    setIsVirginLogin(isVirginLoginTemp); 

  }, [])

  const handleSetPassword = (event: FormEvent<HTMLFormElement>) => {
    setLoading(true); 
    event.preventDefault(); 
    
    if (inputPassword1 === inputPassword2) {
      setShowPasswordsMismatchAlert(false)

      AuthService.generateHashAndStore(
        loggedInUser(), inputPassword1, true
      )
      .then((isGenHashAndStoreSuccessful) => {
        if (isGenHashAndStoreSuccessful) {
          setPasswordSetResult({
            show: true, 
            result: true
          }); 
          setLoading(false); 
          setTimeout(() => {
            history.push(`/`); 
          }, 1000); 
        }
        else {
          setPasswordSetResult({
            show: true, 
            result: false
          }); 
          setLoading(false); 
        }
      }); 
    }
    else {
      setLoading(false); 
      setShowPasswordsMismatchAlert(true); 
    }
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(100, 108, 119, 0.2)', 
          zIndex: 2000, 
          height: '100vh', 
          position: 'fixed'
        })
      }}>

      <MobileLayout>

        <MainHeader />

        <SideBar 
          pageWrapId="outer-container" 
          outerContainerId="outer-container"
          selectedItem="SetPassword" />
          
        {
          isVirginLogin && (
            <Text>
              Set a secure password. The temporary password provided will no longer be in use after this. 
            </Text>
          )
        }        

        { 
          showPasswordsMismatchAlert && 
            <FailedAlert>
              Passwords do not match
            </FailedAlert>
        }
        { 
          passwordSetResult.show && 
          passwordSetResult.result &&
            <SuccessAlert>
              Password successfully reset. Redirecting...
            </SuccessAlert>
        }
        { 
          passwordSetResult.show && 
          ! passwordSetResult.result &&
            <FailedAlert>
              Unable to save password. Please contact administrator.
            </FailedAlert>
        }

        <form onSubmit={handleSetPassword} >
          <TextField 
            label={ isVirginLogin ? "Set password" : "New password" } 
            inputType="password" 
            changeHandler={(input) => setInputPassword1(input)} 
            />

          <TextField 
            label="Confirm password" 
            inputType="password" 
            changeHandler={(input) => setInputPassword2(input)}
            />

          <Button type="submit">Set password</Button>
        </form>
        
      </MobileLayout>
      
    </LoadingOverlay>
  )
}

export default SetPasswordPage; 