import { Moment } from "moment";
import { DailyRow } from "../../typings/common";
import {
  DataPoint,
  generateTimeSeriesSalesDataPointLabel,
} from "../HighCharts/HcLineChartTimeSeries";
import {
  dailyRowDateSortFunc,
  fromStandardDateFormat,
  fromToMoment,
  toHumanDateFormatWithDow,
  toHumanMonthString,
  toStandardDateFormat,
} from "../../utils/date";

export const salesHistoryToSeriesData = (
  currentYearData: DailyRow[],
  currentYearLastDate: Moment,
  lastYearData: DailyRow[],
  lastYearLastDate: Moment,
  metricToPlot: keyof DailyRow,
  daysToTrack: number,
  valueIsCurrency: boolean = true
) => {
  const currentYearFirstDate = currentYearLastDate.clone().subtract(daysToTrack - 1, "days");
  const lastYearFirstDate = lastYearLastDate.clone().subtract(daysToTrack - 1, "days");

  console.log({
    currentYearData,
  });

  const thisYearDataPoints = salesHistoryToDataPoints(
    currentYearData,
    metricToPlot,
    currentYearFirstDate,
    currentYearLastDate
  );

  const lastYearDataPoints = salesHistoryToDataPoints(
    lastYearData,
    metricToPlot,
    lastYearFirstDate,
    lastYearLastDate
  ).map((dataPoint) => ({
    ...dataPoint,
    pointLabel: generateTimeSeriesSalesDataPointLabel(
      dataPoint.pointLabel,
      dataPoint.value,
      valueIsCurrency
    ),
  }));
  console.log(lastYearDataPoints);

  const augmentedthisYearDataPoints = thisYearDataPoints.map((dataPoint, index) => ({
    ...dataPoint,
    pointLabel: generateTimeSeriesSalesDataPointLabel(
      dataPoint.pointLabel,
      dataPoint.value,
      valueIsCurrency,
      lastYearDataPoints[index]?.value ?? 0,
      ""
    ),
  }));

  return {
    [`Sales (${currentYearLastDate.year()})`]: augmentedthisYearDataPoints,
    [`Sales (${lastYearLastDate.year()})`]: lastYearDataPoints,
  };
};

const salesHistoryToDataPoints = (
  dailyRows: DailyRow[],
  metricToPlot: keyof DailyRow,
  startDate: Moment,
  endDate: Moment
): DataPoint[] => {
  const dailyRowsMap = dailyRows.reduce<{ [businessDate: string]: DataPoint }>((prev, curr) => {
    const businessDate = fromStandardDateFormat(curr.business_date ?? "2000-01-01");
    prev[toStandardDateFormat(businessDate)] = {
      pointLabel: toHumanDateFormatWithDow(businessDate),
      xAxisLabel: monthlyXAxisLabel(businessDate),
      value: curr[metricToPlot] as number,
    };
    return prev;
  }, {});
  console.log("dailyRowsMap", dailyRowsMap);

  const output = fromToMoment(startDate, endDate).map((iterDate) => {
    const businessDate = toStandardDateFormat(iterDate);
    const iterDataPoint = dailyRowsMap[businessDate];
    if (!!!iterDataPoint) {
      return {
        pointLabel: toHumanDateFormatWithDow(businessDate),
        xAxisLabel: monthlyXAxisLabel(iterDate),
        value: 0,
      };
    }
    return iterDataPoint;
  });
  console.log("output", output);

  return output;
};

const monthlyXAxisLabel = (date: Moment) => (date.date() === 1 ? toHumanMonthString(date) : "");
