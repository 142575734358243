import React from 'react'; 
import styled from 'styled-components'; 
import { Option } from '../typings/common'

const Wrapper = styled.div`
  border-radius: 4px; 
  background-color: #F6F7FB; 
  flex: 1; 
  display: flex; 
  flex-direction: row; 
  height: 40px; 
  margin: 12px 0px; 
  align-item: center;
`

const OptionItem = styled.a<{ selected?: boolean }>`
  display: inline; 
  color: ${({ selected }) => selected ? "#273561" : "#646C77"}; 
  font-weight: ${({ selected }) => selected ? "600" : "400"}; 
  font-size: 14px;
  text-align: center; 
  align-self: center; 
  flex: 1; 
  :not(:last-child) {
    border-right: 0.5px solid #646C7780; 
  }
  :hover {
    cursor: pointer; 
  }
`

type HorizontalToggleProps = {
  id: string, 
  options: Option[], 
  selected: string, 
  changeHandler: (id: string, value: string) => void
}

const HorizontalToggle: React.FC<HorizontalToggleProps> = ({
  id, 
  options, 
  selected, 
  changeHandler
}) => {

  return (
    <Wrapper>
      {
        options.map((option) => (
          <OptionItem 
            onClick={() => changeHandler(id, option.value ?? "")}
            selected={selected === option.value}
            key={option.value}>
            {option.label}
          </OptionItem>
        ))
      }
    </Wrapper>
  )
}

export default HorizontalToggle; 